import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  Grid,
  Typography,
  Box,
  IconButton,
  ListItemIcon,
  MenuList,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import InsertEmoticonIcon from "@mui/icons-material/InsertEmoticon";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import EmojiPicker from "emoji-picker-react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";

import Api from "../../helpers/Api";
import ProfileAppbar from "../profile/profile.appbar";
import FilesViewer from "../file/Viewer/FilesViewer";
import StatusPicker from "./status.picker";
import IssueDialog from "./issue.table.dialog";
import FormView from "./FormView";
import Response from "../responses/response";
import SmartCommentList from "../comment/smart.comment.list";
import { Apartment, BusinessCenter } from "@material-ui/icons";
import { useLocalStorage } from "react-use";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { deleteIssueById } from "./api.call";
import { useDispatch } from "react-redux";
import MyPopOver from "../styled/CommonComponents/MyPopOver";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginTop: "10vh",
  backgroundColor: theme.palette.background.default,
  minHeight: "100vh",
}));

const Content = styled("div")(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(2),
  maxWidth: 1200,
  margin: "0 auto",
  width: "100%",
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  padding: theme.spacing(4),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  transition: "all 0.3s",
  "&:hover": {
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px",
  },
}));

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "flex-start",
    gap: theme.spacing(1),
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  marginRight: theme.spacing(2),
  width: theme.spacing(6),
  height: theme.spacing(6),
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "1.75rem",
  fontWeight: 600,
  marginBottom: theme.spacing(1),
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "1.35rem",
  fontWeight: 600,
  color: theme.palette.text.primary,
  marginBottom: theme.spacing(3),
  paddingBottom: theme.spacing(1.5),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const NoDataBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(6),
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  border: `1px dashed ${theme.palette.divider}`,
  "& svg": {
    fontSize: 48,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
  },
  "& .MuiTypography-root": {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
  },
}));

const EmojiPickerContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  zIndex: 1000,
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
}));

const ActionButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: "8px 24px",
  borderRadius: theme.shape.borderRadius,
  textTransform: "none",
  fontWeight: 500,
  boxShadow: "none",
  "&:hover": {
    boxShadow: theme.shadows[2],
  },
}));

const StatusContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    alignItems: "stretch",
  },
}));

function ProfileIssueView({ issue, setIssue, isFromBooking = false }) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [status, setStatus] = useState("");
  const [template, setTemplate] = useState("");
  const [updateTemplateStatusLoading, setUpdateTemplateStatusLoading] =
    useState(false);
  const [updated, setUpdated] = useState(false);
  const [open, setOpen] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState(null);

  useEffect(() => {
    const statusObj = issue?.template?.pipeline.find(
      (obj) => obj?._id === issue?.status
    );
    setTemplate(issue?.template);
    setStatus(statusObj);
  }, [issue]);

  const updateTemplateStatus = async () => {
    setUpdateTemplateStatusLoading(true);
    const issueObject = {
      _id: issue?._id,
      template: template?._id,
      status: status?._id,
      closed: template?.finalStates.includes(status?._id),
    };
    const res = await Api.post("issue/updateTemplateStatus", issueObject);
    if (res?.issue) {
      setIssue(res?.issue);
      setUpdated(true);
    } else {
      console.error(res?.error);
    }
    setUpdateTemplateStatusLoading(false);
  };

  const createNotification = async () => {
    const data = await Api.post();
  };

  useEffect(() => {
    if (issue && template && status && status?._id !== issue?.status) {
      const timer = setTimeout(() => {
        updateTemplateStatus();
      }, 1700);
      return () => clearTimeout(timer);
    }
  }, [template, status]);

  const deleteIssue = async () => {
    try {
      await deleteIssueById({
        issueId: issue?._id,
      });
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Issue deleted successfully",
        },
      });
      history.push(
        `/issue/template/${issue?.template?._id}/view/${issue?.profile?._id}`
      );
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong",
        },
      });
    }
  };

  return (
    <Root>
      <IssueDialog
        open={open}
        IdArr={[issue?._id]}
        issueDictionary={{ [issue?._id]: issue }}
        setOpen={setOpen}
        status={Boolean(issue?.closed)}
      />
      <Content>
        <Section>
          <Header sx={{ mb: 2 }}>
            <StyledAvatar src={issue?.user?.displayPicture?.url}>
              {issue?.user?.displayName?.charAt(0)}
            </StyledAvatar>
            <Box>
              <Typography variant="h6">{issue?.user?.displayName}</Typography>
              <Typography variant="caption" color="text.secondary">
                Created on {moment(issue?.createdAt).format("MMM D, YYYY")}
              </Typography>
            </Box>
          </Header>

          <ProfileAppbar
            name={issue?.title}
            btns={
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                {template?.pipeline?.length > 0 && (
                  <StatusPicker
                    viewMode
                    pipeline={template?.pipeline}
                    startState={template?.startState}
                    status={status}
                    setStatus={setStatus}
                  />
                )}
                <MyPopOver
                  closeOnClick={true}
                  appearContent={
                    <IconButton>
                      <MoreVertIcon />
                    </IconButton>
                  }
                  showContent={
                    <div>
                      <MenuList>
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            if (isFromBooking) {
                              history.push(`/booking/issue/edit/${issue?._id}`);
                            } else {
                              history.push(`/issue/edit/${issue?._id}`);
                            }
                          }}
                        >
                          <ListItemIcon>
                            <EditIcon fontSize="small" />
                          </ListItemIcon>
                          Edit Issue
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteIssue();
                          }}
                          sx={{ color: "error.main" }}
                        >
                          <ListItemIcon>
                            <DeleteIcon fontSize="small" color="error" />
                          </ListItemIcon>
                          Delete Issue
                        </MenuItem>
                      </MenuList>
                    </div>
                  }
                />
              </Box>
            }
          />

          <Box sx={{ mt: 2, mb: 2 }}>
            <Title variant="h6">{issue?.title || "Untitled Ticket"}</Title>
          </Box>

          <Typography variant="body1" sx={{ mb: 2 }}>
            <Typography component="span" fontWeight={500}>
              Description
            </Typography>{" "}
            {issue?.description
              ? ReactHtmlParser(issue?.description)
              : "No description"}
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography
                  variant="subtitle1"
                  fontWeight={500}
                  sx={{ minWidth: "80px" }}
                >
                  Project
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    backgroundColor: "background.default",
                    padding: 1.5,
                    borderRadius: 1,
                    flex: 1,
                  }}
                >
                  <Apartment sx={{ color: "text.secondary" }} />
                  <Typography>
                    {issue?.project?.displayName || "No project associated"}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography
                  variant="subtitle1"
                  fontWeight={500}
                  sx={{ minWidth: "80px" }}
                >
                  Organization
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    backgroundColor: "background.default",
                    padding: 1.5,
                    borderRadius: 1,
                    flex: 1,
                  }}
                >
                  <BusinessCenter sx={{ color: "text.secondary" }} />
                  <Typography>
                    {issue?.organization?.displayName ||
                      "No organization associated"}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Section>

        <Divider />

        <Section>
          <SectionTitle variant="h6">Files</SectionTitle>
          {issue?.files?.length > 0 ? (
            <FilesViewer fileIds={issue.files.map((file) => file?._id)} />
          ) : (
            <NoDataBox>
              <InsertDriveFileOutlinedIcon
                sx={{ fontSize: 48, color: "text.secondary", mb: 2 }}
              />
              <Typography variant="body1" color="text.secondary">
                No files attached
              </Typography>
            </NoDataBox>
          )}
        </Section>
        <Divider />

        <Section>
          <SectionTitle variant="h6">Comments</SectionTitle>
          {issue?._id ? (
            <SmartCommentList parent={issue._id} parentModelName="Issue" />
          ) : (
            <NoDataBox>
              <CommentOutlinedIcon
                sx={{ fontSize: 48, color: "text.secondary", mb: 2 }}
              />
              <Typography variant="body1" color="text.secondary">
                No comments yet
              </Typography>
            </NoDataBox>
          )}
        </Section>
      </Content>
    </Root>
  );
}

export default ProfileIssueView;
