import socketIOClient from 'socket.io-client';
import config from '../../config/index';

// For debugging
console.log('Connecting to socket server at:', config.serverUrl);

const socket = socketIOClient(config.serverUrl, {
    transports: ['websocket', 'polling'],  // Added polling as fallback
    reconnection: true,
    reconnectionAttempts: 5,
    reconnectionDelay: 1000,
    cors: {
        origin: "*",
        methods: ["GET", "POST"]
    }
});

// Enhanced error handling
socket.on('connect_error', (error) => {
    console.error('Socket connection error:', error);
    console.log('Failed to connect to:', config.serverUrl);
});

socket.on('connect', () => {
    console.log('Socket connected successfully to:', config.serverUrl);
});

socket.on('disconnect', (reason) => {
    console.log('Socket disconnected:', reason);
});

export default socket;
