import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Typography,
} from "@mui/material";
import VoiceChatIcon from "@mui/icons-material/VoiceChat";
import MicIcon from "@mui/icons-material/Mic";
import { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import OpenAI from "openai";
import Api from "../../../helpers/Api";
import configObject from "../../../config";

// export default function ContactAI({ bookingId }) {
export default function ContactAI({ bookingData }) {
  const [open, setOpen] = useState(false);
  const [connected, setConnected] = useState(false);
  const [isAISpeaking, setIsAISpeaking] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const socketRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const openai = useRef(null);
  const threadRef = useRef(null);
  const assistantRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const initOpenAI = async () => {
    if (!configObject.OPENAI_API_KEY || configObject.OPENAI_API_KEY === "") {
      throw new Error("OPENAI_API_KEY is not set");
    }
    openai.current = new OpenAI({
      apiKey: configObject.OPENAI_API_KEY,
      dangerouslyAllowBrowser: true,
    });

    // Create assistant with comprehensive booking context
    assistantRef.current = await openai.current.beta.assistants.create({
      name: "RealList AI Assistant",
      model: "gpt-4-turbo-preview",
      instructions: `You are a helpful real estate assistant specializing in booking information and property details. 
      You have access to the following booking information:
      
      Basic Information:
      - Property: ${bookingData?.unit?.name || "N/A"}
      - Project: ${bookingData?.project?.name || "N/A"}
      - Customer: ${bookingData?.customerProfile?.name || "N/A"}
      - Agreement Value: ${bookingData?.agreementValue || "N/A"}
      - Status: ${bookingData?.status || "N/A"}
      - Created Date: ${
        new Date(bookingData?.createdAt).toLocaleDateString() || "N/A"
      }
      
      Financial Details:
      - Payment Status: ${bookingData?.paymentStatus || "N/A"}
      - Total Amount Paid: ${bookingData?.totalAmountPaid || "N/A"}
      - Remaining Balance: ${bookingData?.remainingBalance || "N/A"}
      
      Property Details:
      - Unit Type: ${bookingData?.unit?.type || "N/A"}
      - Floor Plan: ${bookingData?.unit?.floorPlan || "N/A"}
      - Area: ${bookingData?.unit?.area || "N/A"} sq ft
      - Location: ${bookingData?.project?.location || "N/A"}
      
      Contact Information:
      - Customer Email: ${bookingData?.customerProfile?.email || "N/A"}
      - Customer Phone: ${bookingData?.customerProfile?.phone || "N/A"}
      
      Please use this information to provide detailed and contextual responses about the booking.
      Don't speak irrelevant information such as booking id or id, etc.
      `,
    });

    // Create thread with initial context
    threadRef.current = await openai.current.beta.threads.create();

    // Add initial context message to the thread
    await openai.current.beta.threads.messages.create(threadRef.current.id, {
      role: "user",
      content: `Initializing conversation about booking ${bookingData?._id}. 
      This booking is for ${bookingData?.unit?.name} in ${bookingData?.project?.name}.
      The customer is ${bookingData?.customerProfile?.name} and the current status is ${bookingData?.status}.
      The agreement value is ${bookingData?.agreementValue} with ${bookingData?.paymentStatus} payment status.
      Please acknowledge receipt of this context.`,
    });
  };

  const handleConnect = async () => {
    try {
      await initOpenAI();

      // const response = await Api.post("/bookings/connect-ai/create-room", {
      //   bookingId: bookingId,
      // });

      // if (!response?.data?.token) {
      //   throw new Error("Failed to get room token");
      // }

      // socketRef.current = io(configObj.serverUrl, {
      //   path: "/bookings/voice-ai",
      //   auth: { token: response.data.token },
      //   transports: ["websocket"],
      // }).connect();

      // socketRef.current.on("connect", () => {
      setConnected(true);
      //   console.log("Connected to AI namespace");
      // });

      // socketRef.current.on("connect_error", (error) => {
      //   console.error("Connection Error:", error);
      // });

      // socketRef.current.on("disconnect", () => {
      //   setConnected(false);
      //   console.log("Disconnected from AI");
      //   stopRecording();
      // });

      // socketRef.current.on("voice-transcribed", (data) => {
      //   console.log("Transcribed text:", data.text);
      // });

      // socketRef.current.on("ai-response", (data) => {
      //   if (data.audio) {
      //     console.log("AI Response:", data.text);
      //     playAudioResponse(data.audio);
      //   }
      // });
    } catch (error) {
      console.error("Error connecting to AI:", error);
    }
  };

  const handleDisconnect = () => {
    if (socketRef.current) {
      // socketRef.current.close();
      setConnected(false);
      console.log("Disconnecting from AI...");
    }
    stopRecording();
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream, {
        mimeType: "audio/webm;codecs=opus",
      });
      const chunks = [];

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) chunks.push(event.data);
      };

      mediaRecorderRef.current.onstop = async () => {
        const startTime = Date.now();
        try {
          const blob = new Blob(chunks, { type: "audio/webm" });
          const audioFile = new File([blob], "audio.webm", {
            type: "audio/webm",
            lastModified: new Date().getTime(),
          });

          const transcription =
            await openai.current.audio.transcriptions.create({
              file: audioFile,
              model: "whisper-1",
            });

          // Process with AI assistant
          await openai.current.beta.threads.messages.create(
            threadRef.current.id,
            {
              role: "user",
              content: transcription.text,
            }
          );

          const run = await openai.current.beta.threads.runs.create(
            threadRef.current.id,
            {
              assistant_id: assistantRef.current.id,
            }
          );

          // Poll for completion
          let runStatus;
          do {
            await new Promise((resolve) => setTimeout(resolve, 100));
            runStatus = await openai.current.beta.threads.runs.retrieve(
              threadRef.current.id,
              run.id
            );
          } while (
            runStatus.status === "in_progress" ||
            runStatus.status === "queued"
          );

          if (runStatus.status === "completed") {
            const messages = await openai.current.beta.threads.messages.list(
              threadRef.current.id
            );
            const assistantMessage = messages.data[0].content[0].text.value;

            // Convert response to speech
            const speechResponse = await openai.current.audio.speech.create({
              model: "tts-1",
              voice: "alloy",
              input: assistantMessage,
            });

            const audioBlob = new Blob([await speechResponse.arrayBuffer()], {
              type: "audio/mpeg",
            });

            // Calculate total time taken
            const endTime = Date.now();
            const totalTimeSeconds = ((endTime - startTime) / 1000).toFixed(2);
            console.log(`AI Response Time: ${totalTimeSeconds} seconds`);

            playAudioResponse(audioBlob);
          }
        } catch (error) {
          console.error("Transcription error:", error);
        }
      };

      mediaRecorderRef.current.start();
      setIsListening(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsListening(false);
      console.log("Recording stopped.");
    }
  };

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      stopRecording();
    };
  }, []);

  const playAudioResponse = async (audioBuffer) => {
    try {
      setIsAISpeaking(true);
      const blob = new Blob([audioBuffer], { type: "audio/mp3" });
      const audioUrl = URL.createObjectURL(blob);
      const audio = new Audio(audioUrl);

      audio.onended = () => {
        URL.revokeObjectURL(audioUrl);
      };

      await audio.play();
    } catch (error) {
      console.error("Error playing audio response:", error);
    } finally {
      setIsAISpeaking(false);
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        startIcon={<VoiceChatIcon />}
        onClick={handleOpen}
      >
        Contact AI
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="ai-dialog-title"
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="ai-dialog-title">AI Voice Assistant</DialogTitle>
        <DialogContent>
          {!connected ? (
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "20px 0" }}
              onClick={handleConnect}
            >
              Connect
            </Button>
          ) : (
            <div style={{ textAlign: "center", padding: "20px" }}>
              <div style={{ marginBottom: "20px" }}>
                {isListening && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <MicIcon color="error" />
                    <Typography>You are speaking...</Typography>
                  </div>
                )}
                {isAISpeaking && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "10px",
                    }}
                  >
                    <CircularProgress size={20} />
                    <Typography>AI is speaking...</Typography>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (!isListening) {
                      startRecording();
                      setIsListening(true);
                    } else {
                      stopRecording();
                      setIsListening(false);
                    }
                  }}
                >
                  {isListening ? "Stop Speaking" : "Start Speaking"}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDisconnect}
                >
                  Disconnect
                </Button>
              </div>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
