import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Api from "../../helpers/Api";
import {
  BrowserRouter as Router,
  useParams,
  useHistory,
} from "react-router-dom";
import ProfileIssueEdit from "./profile.issue.edit";
import ProfileIssueView from "./profile.issue.view";
import { Loadinglogo } from "../../helpers/loadinglogo";
import ProfileIssueCRM from "./profile.issue.crm.view";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
}));

function ProfileIssueViewManager({ editModeValue }) {
  const history = useHistory();
  const classes = useStyles();
  const { issueId } = useParams();
  const {} = classes;

  const [issue, setIssue] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(editModeValue);
  useEffect(() => {
    setEditMode(editModeValue);
  }, [editModeValue]);

  const getData = async () => {
    try {
      const res = await Api.post("issue/getIssueDetail", {
        issue: issueId,
      });
      const data = res?.data;
      if (data?._id) {
        setIssue(data);
      }
      setLoading(false);
    } catch (error) {
      console.log("error in profile.issue.view.manager : ", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      {loading ? (
        <Loadinglogo />
      ) : (
        <>
          {issue?.template?.isCRM ? (
            <ProfileIssueCRM getIssueCall={getData} issue={issue} setIssue={setIssue} />
          ) : editMode ? (
            <ProfileIssueEdit issue={issue} setIssue={setIssue} />
          ) : (
            <ProfileIssueView issue={issue} setIssue={setIssue} />
          )}
        </>
      )}
    </div>
  );
}

export default ProfileIssueViewManager;
