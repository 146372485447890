import React, { useState, useEffect } from "react";
import {
  TextField,
  Box,
  Button,
  Divider,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  IconButton,
  Avatar,
  InputAdornment,
} from "@mui/material";
import {
  Edit,
  Delete,
  Add,
  Percent,
  CurrencyRupee
} from "@mui/icons-material";
import CreatePalDrawer from "../../../AddMember/CreatePalDrawer";
import SearchEntityDialog from "../../../styled/CommonComponents/SearchEntityDrawer";
import { useSelector } from "react-redux";
import { getProfileForEntitySearch } from "../../../styled/CommonComponents/api.call";

export const BrokerProfile = ({ formData, handleChange, handleOpenDialog, curEntityId, entity }) => {
  const { user } = useSelector((state) => state.auth);
  const [openBrokerPal, setOpenBrokerPal] = useState(false);
  const [showLeadsDrawer, setShowLeadsDrawer] = useState(false);
  const [mainProfileWitParent, setMainProfileWitParent] = useState({});
  const [palData, setPalData] = useState({});
  const [brokers, setBrokers] = useState(formData.brokerProfile || []);
  const [profileIdArr, setProfileIdArr] = useState([]);

  useEffect(() => {
    if (curEntityId && entity) {
      getProfileForEntitySearch({
        entityId: curEntityId,
        type: entity,
      })
        .then((data) => {
          const profiles = data?.profiles || [];
          let locProfileIdArr = new Set([...profiles, user?.profile]);
          setProfileIdArr([...locProfileIdArr]);
          const currentProfileWithParent = data?.currentProfileWithParent;
          setMainProfileWitParent(currentProfileWithParent);
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }, [curEntityId, entity]);

  useEffect(() => {
    setBrokers(formData.brokerProfile);
  }, [formData.brokerProfile]);

  const handleAddBroker = (entity) => {
    const newBroker = {
      profile: entity,
      chargeAmount: 0,
      chargePercentage: 0,
      isUserInput: false,
      isTransaction: false,
    };
    setBrokers((prev) => [...prev, newBroker]);
    setShowLeadsDrawer(false);
  };

  useEffect(() => {
    handleChange("brokerProfile", brokers);
  }, [brokers]);

  const handleEditBroker = (newOne) => {
    const updatedBroker = {
      profile: newOne,
      chargeAmount: 0,
      chargePercentage: 0,
      isUserInput: false,
      isTransaction: false,
    };
    setBrokers((prev) =>
      prev.map((item) =>
        item.profile._id === updatedBroker.profile._id ? updatedBroker : item
      )
    );
    setOpenBrokerPal(false);
  };

  return (
    <Box>
      {brokers.map((broker, index) => (
        <Box key={index} sx={{ mb: 1 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Avatar
                sx={{ width: 40, height: 40 }}
                src={broker?.profile?.parent?.displayPicture?.url}
                alt={broker?.profile?.parent?.displayName}
              />
              <Box>
                <Typography variant="subtitle1" sx={{ fontWeight: "medium" }}>
                  {broker?.profile?.parent?.displayName}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {broker?.profile?.parent?.phone}
                </Typography>
              </Box>
            </Box>
            <Box>
              <IconButton
                size="small"
                onClick={() => {
                  setOpenBrokerPal(true);
                  setPalData(broker?.profile?.parent)
                }}
              >
                <Edit fontSize="small" />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => {
                  setBrokers((prev) =>
                    prev.filter(
                      (item) => item.profile._id !== broker.profile._id
                    )
                  );
                }}
              >
                <Delete fontSize="small" />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1, mt: 1 }}>
            <ToggleButtonGroup
              size="small"
              value={broker.isPercentage ? "percentage" : "value"}
              exclusive
              onChange={(e, newValue) => {
                if (newValue !== null) {
                  setBrokers(
                    brokers.map((b, i) =>
                      i === index
                        ? {
                            ...b,
                            isPercentage: newValue === "percentage",
                            chargeAmount: 0,
                            chargePercentage: 0,
                          }
                        : b
                    )
                  );
                }
              }}
            >
              <ToggleButton value="percentage" aria-label="percentage">
                <Percent fontSize="small" />
              </ToggleButton>
              <ToggleButton value="value" aria-label="value">
                <CurrencyRupee fontSize="small" />
              </ToggleButton>
            </ToggleButtonGroup>
            <TextField
              type="number"
              size="small"
              value={
                broker.isPercentage
                  ? broker.chargePercentage
                  : broker.chargeAmount
              }
              onChange={(e) => {
                const value = Number(e.target.value);
                setBrokers(
                  brokers.map((b, i) =>
                    i === index
                      ? {
                          ...b,
                          [broker.isPercentage
                            ? "chargePercentage"
                            : "chargeAmount"]: value,
                        }
                      : b
                  )
                );
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {broker.isPercentage ? "%" : "₹"}
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
          </Box>
        </Box>
      ))}
      <Divider sx={{ my: 1.5 }} />
      <Button
        variant="outlined"
        startIcon={<Add />}
        fullWidth
        onClick={() => setShowLeadsDrawer(true)}
        sx={{ mt: 0 }}
      >
        Add Broker
      </Button>

      <SearchEntityDialog
        orgtype="User"
        givenFilterOption={[{ option: "Contact", types: ["Broker"] }]}
        entity={entity || "User"}
        multiple={false}
        curEntityId={curEntityId || user?._id}
        givenProfileIdArr={[]}
        onSave={handleAddBroker}
        open={showLeadsDrawer}
        setOpen={setShowLeadsDrawer}
        title="Select Broker"
        financialRelationType="Broker"
      />

      <CreatePalDrawer
        openPal={openBrokerPal}
        setOpenPal={setOpenBrokerPal}
        fRelationId={mainProfileWitParent?.profile?.finrel}
        selectEntityFunction={handleEditBroker}
        pal={palData}
        title={"Edit Broker"}
        orgtype={"User"}
        contactType={"Broker"}
        financialRelationType={"Broker"}
        mainProfileWitParent={mainProfileWitParent}
        profileIdArr={profileIdArr}
        notAllowedRoles={[]}
        actionType={"Update"}
      />
    </Box>
  );
};
