import { Box, Grid, MenuItem, Select, Input } from "@mui/material";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";

let keys = [
  "1",
  "2",
  "3",
  "+",
  ">",
  "CE",
  "4",
  "5",
  "6",
  "-",
  "<",
  "MAX",
  "7",
  "8",
  "9",
  "*",
  ">=",
  "ROUND",
  ".",
  "0",
  ",",
  "/",
  "<=",
  "FLOOR",
  "(",
  ")",
  "IF",
  "^",
  "!=",
  "CEIL",
];


const CalculatorBox = ({ 
  expression = "", 
  setExpression, 
  components = [],
  componentDisplayField = "name" 
}) => {
  const { organizationId } = useParams();
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  const [isValid, setIsValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedComponent, setSelectedComponent] = useState("");
  const [displayExpression, setDisplayExpression] = useState("");

  // Improved mapping functions with error handling
  const mapDisplayExpressionToExpression = (displayExpr) => {
    if (!displayExpr) return "";
    let expr = ` ${displayExpr} `; // Add spaces to ensure proper replacement
    components.forEach((component) => {
      const componentName = component[componentDisplayField];
      const componentId = component._id;
      if (componentName && componentId) {
        expr = expr.replace(new RegExp(` ${componentName} `, "g"), componentId);
      }
    });
    return expr.trim();
  };

  const mapExpressionToDisplayExpression = (expr) => {
    if (!expr) return "";
    let displayExpr = ` ${expr} `; // Add spaces to ensure proper replacement
    components.forEach((component) => {
      const componentName = component[componentDisplayField];
      const componentId = component._id;
      if (componentName && componentId) {
        displayExpr = displayExpr.replace(
          new RegExp(componentId, "g"),
          ` ${componentName} `
        );
      }
    });
    return displayExpr.trim();
  };

  // Update display expression when components or expression changes
  useEffect(() => {
    if (expression) {
      const tempDisplayExp = mapExpressionToDisplayExpression(expression);
      setDisplayExpression(tempDisplayExp);
    }
  }, [components, expression]);

  const validateExpression = (expr) => {
    if (!expr) {
      setIsValid(true);
      setErrorMessage("");
      return true;
    }
  
    const allowedFunctions = ["IF", "ROUND", "CEIL", "MAX", "FLOOR"];
    const comparisonOperators = [">", "<", ">=", "<=", "!="];
    const operators = ["+", "-", "*", "/", "^"];
    
    // Replace MongoDB IDs with a placeholder number
    let modifiedExpr = expr.replace(/[a-f\d]{24}/gi, "1");
  
    // Check parentheses balance
    const stack = [];
    for (let char of modifiedExpr) {
      if (char === "(") {
        stack.push("(");
      } else if (char === ")") {
        if (stack.length === 0) {
          setErrorMessage("Unbalanced parentheses");
          setIsValid(false);
          return false;
        }
        stack.pop();
      }
    }
    if (stack.length !== 0) {
      setErrorMessage("Unbalanced parentheses");
      setIsValid(false);
      return false;
    }
  
    // Ensure expression doesn't start or end with an operator (except '-' for negative numbers)
    if (
      operators.includes(modifiedExpr[0]) && modifiedExpr[0] !== "-" || 
      operators.includes(modifiedExpr[modifiedExpr.length - 1])
    ) {
      setErrorMessage("Expression cannot start or end with an operator");
      setIsValid(false);
      return false;
    }
  
    // Detect consecutive operators (excluding '-' for negative numbers)
    if (/[*+/^]{2,}/.test(modifiedExpr) || /[-]{3,}/.test(modifiedExpr)) {
      setErrorMessage("Invalid consecutive operators");
      setIsValid(false);
      return false;
    }
  
    // Check for invalid characters
    const validPattern = /^[\s\w+\-*/,^=!<>().\d]+$/;
    if (!validPattern.test(modifiedExpr)) {
      setErrorMessage("Invalid characters in expression");
      setIsValid(false);
      return false;
    }
  
    // Check for operators preceding closing parenthesis
    if (/[+\-*/^][\s]*\)/.test(modifiedExpr)) {
      setErrorMessage("Invalid operator preceding closing parenthesis");
      setIsValid(false);
      return false;
    }
  
    setErrorMessage("");
    setIsValid(true);
    return true;
  };
  

  const handleCalculatorButtonClick = (key) => {
    const cursorPosition = inputRef.current?.selectionStart || 0;

    if (key === "CE") {
      setDisplayExpression("");
      setExpression("");
      return;
    }

    let newDisplayExpression = displayExpression;
    let cursorOffset = 1;

    switch (key) {
      case "(":
        newDisplayExpression = 
          displayExpression.slice(0, cursorPosition) +
          " () " +
          displayExpression.slice(cursorPosition);
        cursorOffset = 2;
        break;
      case "CEIL":
        newDisplayExpression =
          displayExpression.slice(0, cursorPosition) +
          " CEIL() " +
          displayExpression.slice(cursorPosition);
        cursorOffset = 6;
        break;
      case "IF":
        newDisplayExpression =
          displayExpression.slice(0, cursorPosition) +
          " IF(,,) " +
          displayExpression.slice(cursorPosition);
        cursorOffset = 5;
        break;
      case "MAX":
        newDisplayExpression =
          displayExpression.slice(0, cursorPosition) +
          " MAX() " +
          displayExpression.slice(cursorPosition);
        cursorOffset = 5;
        break;
      case "ROUND":
        newDisplayExpression =
          displayExpression.slice(0, cursorPosition) +
          " ROUND(,) " +
          displayExpression.slice(cursorPosition);
        cursorOffset = 7;
        break;
      case "FLOOR":
        newDisplayExpression =
          displayExpression.slice(0, cursorPosition) +
          " FLOOR() " +
          displayExpression.slice(cursorPosition);
        cursorOffset = 7;
        break;
      default:
        newDisplayExpression =
          displayExpression.slice(0, cursorPosition) +
          ` ${key} ` +
          displayExpression.slice(cursorPosition);
        cursorOffset = key.length + 2;
    }

    setDisplayExpression(newDisplayExpression);
    const newExpression = mapDisplayExpressionToExpression(newDisplayExpression);
    setExpression(newExpression);
    validateExpression(newExpression);

    // Set cursor position after state update
    setTimeout(() => {
      if (inputRef.current) {
        const newPosition = cursorPosition + cursorOffset;
        inputRef.current.setSelectionRange(newPosition, newPosition);
        inputRef.current.focus();
      }
    }, 0);
  };

  const handleComponentSelection = (componentId) => {
    if (!componentId) return;

    const component = components.find((comp) => comp._id === componentId);
    if (!component || !component[componentDisplayField]) {
      console.error(`Selected component or its ${componentDisplayField} is undefined`);
      return;
    }

    const cursorPosition = inputRef.current?.selectionStart || 0;
    const componentName = ` ${component[componentDisplayField]} `;
    
    setSelectedComponent(componentId);
    const newDisplayExpression =
      displayExpression.slice(0, cursorPosition) +
      componentName +
      displayExpression.slice(cursorPosition);

    setDisplayExpression(newDisplayExpression);
    const newExpression = mapDisplayExpressionToExpression(newDisplayExpression);
    setExpression(newExpression);
    validateExpression(newExpression);

    // Set cursor position after state update
    setTimeout(() => {
      if (inputRef.current) {
        const newPosition = cursorPosition + componentName.length;
        inputRef.current.setSelectionRange(newPosition, newPosition);
        inputRef.current.focus();
      }
    }, 0);
  };

  const handleExpressionChange = (e) => {
    const newDisplayExpression = e.target.value;
    setDisplayExpression(newDisplayExpression);
    const newExpression = mapDisplayExpressionToExpression(newDisplayExpression);
    setExpression(newExpression);
    validateExpression(newExpression);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      e.preventDefault();
      const cursorPosition = inputRef.current?.selectionStart || 0;
      if (cursorPosition === 0) return;

      // Find the previous word boundary
      const textBeforeCursor = displayExpression.slice(0, cursorPosition);
      const lastSpaceIndex = textBeforeCursor.lastIndexOf(" ");
      const deleteFrom = lastSpaceIndex === -1 ? cursorPosition - 1 : lastSpaceIndex;

      const newDisplayExpression =
        displayExpression.slice(0, deleteFrom) +
        displayExpression.slice(cursorPosition);

      setDisplayExpression(newDisplayExpression);
      const newExpression = mapDisplayExpressionToExpression(newDisplayExpression);
      setExpression(newExpression);
      validateExpression(newExpression);

      // Set cursor position after state update
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.setSelectionRange(deleteFrom, deleteFrom);
        }
      }, 0);
    }
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box>
        <input
          ref={inputRef}
          value={displayExpression}
          onChange={handleExpressionChange}
          onKeyDown={handleKeyDown}
          style={{
            width: "100%",
            border: `5px inset ${isValid ? "rgba(0, 0, 0, 0.1)" : "red"}`,
            padding: "10px 15px",
            fontSize: "1.3rem",
            fontWeight: "bold",
            borderRadius: "8px",
            boxSizing: "border-box",
            outline: "none",
            whiteSpace: "pre-wrap",
            overflowWrap: "break-word",
          }}
        />
        {!isValid && <div style={{ color: "red" }}>{errorMessage}</div>}
      </Box>
      <Box mt={1}>
        <Select
          value={selectedComponent}
          onChange={(e) => handleComponentSelection(e.target.value)}
          displayEmpty
          fullWidth={true}
        >
          <MenuItem value="">Select Component</MenuItem>
          {components?.map((comp) => (
            <MenuItem key={comp._id} value={comp._id}>
              {`${comp[componentDisplayField]} ${comp.hideInTable ? '(User Input)' : ''} ${
                comp.calculationType === 'Fixed' && comp.value ? `(Value: ${comp.value})` : ''
              }`}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box mt={1}>
        <Grid container spacing={0}>
          {keys?.map((item, index) => (
            <Grid item xs={2} key={index}>
              <Box
                onClick={() => handleCalculatorButtonClick(item)}
                sx={{
                  width: "100%",
                  height: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid rgba(0,0,0,0.2)",
                  cursor: "pointer",
                  fontSize: "1.1rem",
                  fontWeight: "500",
                  "&:hover": {
                    backgroundColor: "#FAFAFA",
                  },
                  borderRadius:
                    index === 0
                      ? "5px 0px 0px 0px"
                      : index === 4
                      ? "0px 5px 0px 0px"
                      : index === keys.length - 5
                      ? "0px 0px 0px 5px"
                      : index === keys.length
                      ? "0px 0px 5px 0px"
                      : "0px 0px 0px 0px",
                }}
              >
                {item}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default CalculatorBox;
