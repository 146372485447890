import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  IconButton,
  Avatar,
} from "@mui/material";
import {
  Edit,
  Delete,
  Add
} from "@mui/icons-material";
import CreatePalDrawer from "../../../AddMember/CreatePalDrawer";
import SearchEntityDialog from "../../../styled/CommonComponents/SearchEntityDrawer";
import { useSelector } from "react-redux";
import { getProfileForEntitySearch } from "../../../styled/CommonComponents/api.call";

export const CustomerProfile = ({
  formData,
  handleChange,
  handleRemoveEntity,
  givenProfileIdArr,
  curEntityId,
  entity
}) => {
  const { user } = useSelector((state) => state.auth);
  const [openCustomerPal, setOpenCustomerPal] = useState(false);
  const [showLeadsDrawer, setShowLeadsDrawer] = useState(false);
  const [mainProfileWitParent, setMainProfileWitParent] = useState({});
  const [palData, setPalData] = useState({});
  const [profileIdArr, setProfileIdArr] = useState([]);

  useEffect(() => {
    if (curEntityId && entity) {
      getProfileForEntitySearch({
        entityId: curEntityId,
        type: entity,
      })
        .then((data) => {
          const profiles = data?.profiles || [];
          let locProfileIdArr = new Set([...profiles, user?.profile]);
          setProfileIdArr([...locProfileIdArr]);
          const currentProfileWithParent = data?.currentProfileWithParent;
          setMainProfileWitParent(currentProfileWithParent);
        })
        .catch((err) => {
          console.log(err);
        })
    }
  }, [curEntityId, entity]);

  const handleAddCustomer = (entity) => {
    handleChange("customerProfile", entity);
    setShowLeadsDrawer(false);
  };

  const handleEditCustomer = (newOne) => {
    handleChange("customerProfile", newOne);
    setOpenCustomerPal(false);
  };

  return (
    <Box>
      {formData.customerProfile ? (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" gap={2}>
            <Avatar
              src={formData.customerProfile.parent?.displayPicture?.url}
              sx={{ width: 56, height: 56 }}
            />
            <Box>
              <Typography variant="subtitle1">
                {formData.customerProfile.parent?.displayName}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {formData.customerProfile.parent?.phone}
              </Typography>
            </Box>
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                setPalData(formData.customerProfile?.parent);
                setOpenCustomerPal(true);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() =>
                handleRemoveEntity(
                  "customerProfile",
                  formData.customerProfile._id
                )
              }
            >
              <Delete />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Button
          variant="outlined"
          onClick={() => setShowLeadsDrawer(true)}
          startIcon={<Add />}
          fullWidth
        >
          Add Customer
        </Button>
      )}
      <SearchEntityDialog
        orgtype="User"
        givenFilterOption={[
          {
            option: "Contact",
            types: ["Customer"],
          },
        ]}
        entity={entity || "User"}
        multiple={false}
        curEntityId={curEntityId || user?._id}
        givenProfileIdArr={givenProfileIdArr}
        onSave={handleAddCustomer}
        open={showLeadsDrawer}
        setOpen={setShowLeadsDrawer}
        title="Select Customer"
        financialRelationType="Customer"
      />
      <CreatePalDrawer
        openPal={openCustomerPal}
        setOpenPal={setOpenCustomerPal}
        fRelationId={palData?.profile?.finrel}
        selectEntityFunction={handleEditCustomer}
        pal={palData}
        title="Edit Customer"
        orgtype="User"
        contactType="Customer"
        financialRelationType="Customer"
        mainProfileWitParent={mainProfileWitParent}
        profileIdArr={profileIdArr}
        notAllowedRoles={[]}
        actionType="Update"
      />
    </Box>
  );
};
