import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import NotifyAlert from '../styled/CommonComponents/NotifyAlert';
import {
    Autocomplete, Button, Grid, TextField,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import OrgPicker from '../styled/generic/OrgPicker';
import ProjectPicker from '../styled/generic/ProjectPicker';
import PaginatedEntityDropdown from '../styled/CommonComponents/PaginatedEntityDropdown';
import GooglePlaceAutocomplete from '../styled/CommonComponents/Google.Place.Auto';
import * as yup from "yup";
import "../../App.css";
import { getProjectByProfileOrId } from './api.call';

const assetValidationSchema = yup.object().shape({
    nameId: yup.string().min(2).max(250).required("Name is required"),
    category: yup.object().required("Category is required"),
    purchseCost: yup.number().min(1).required("Purchse cost is required"),
    fullLife: yup.string().required("Full life is required"),
    salvageValue: yup.number().min(0).required("Purchasing price is required")
});


const useStyles = makeStyles((theme) => ({
    mainDialogCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontSize: "14px"
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "15px",
        paddingBottom: "30px"
    },
    bottomAct: {
        width: "100%",
        height: "50px",
        padding: "0px 7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #ececec"
    },
    label: {
        fontSize: "15px",
        fontWeight: "500",
        marginBottom: "3px",
        marginTop: "2px"
    },
    addressCont: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
}));

export default function EventAddEdit({
    defaultType = null, defaultProjectId = null,
    editEventData = null, profileId, saveAction,
    defaultTitle, palUserName, defaultRequiredAttendees
}) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const [loader, setLoader] = useState(false)
    const [alertObj, setAlertObj] = useState({})
    const [eventType, setEventType] = useState(defaultType || "Meeting")
    const [selectedOrg, setSelectedOrg] = useState(null)
    const [selectedProject, setSelectedProject] = useState(null)
    const [eventName, setEventName] = useState(defaultTitle || "");
    const [eventDescription, setEventDescription] = useState("");
    const [startDate, setStartDate] = useState(dayjs());
    const [endDate, setEndDate] = useState(dayjs());
    const [selectedRequiredAttendees, setSelectedRequiredAttendees] = useState([]);
    const [selectedOptionalAttendees, setSelectedOptionalAttendees] = useState([]);
    const [fullAddressLine, setFullAddressLine] = useState("");
    const [streetAddress, setStreetAddress] = useState("");
    const [zip, setZip] = useState("");
    const [city, setCity] = useState("");
    const [region, setRegion] = useState("");
    const [regionCode, setRegionCode] = useState("");
    const [country, setCountry] = useState(null);
    const [countryCode, setCountryCode] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [managers, setManagers] = useState([])

    useEffect(() => {
        if (defaultRequiredAttendees && defaultRequiredAttendees.length > 0) {
            setSelectedRequiredAttendees(defaultRequiredAttendees)
        }
    }, [defaultRequiredAttendees])

    useEffect(() => {
        if (defaultProjectId || profileId) {
            getProjectByProfileOrId({
                projectIds: [defaultProjectId],
                profileIds: [profileId]
            })
                .then((data) => {
                    let selectedProject = null;
                    data.map((s) => {
                        if (s?._id == defaultProjectId) {
                            selectedProject = s;
                        }
                    })
                    if (selectedProject && selectedProject?._id) {
                        setSelectedProject(selectedProject);
                        setFullAddressLine(selectedProject?.address_line1)
                        setStreetAddress(selectedProject?.address?.address_line2)
                        setZip(selectedProject?.address?.postcode)
                        setCity(selectedProject?.address?.city)
                        setRegion(selectedProject?.address?.state)
                        setRegionCode(selectedProject?.address?.state_code)
                        setCountry(selectedProject?.address?.country)
                        setCountryCode(selectedProject?.address?.country_code)
                        setLatitude(selectedProject?.address?.lat || selectedProject?.latitude)
                        setLongitude(selectedProject?.address?.lon || selectedProject?.longitude)
                    } else if (data && data.length > 0 && data[0]?.profile == profileId) {
                        setSelectedProject(data[0]);
                        setFullAddressLine(data[0]?.address_line1)
                        setStreetAddress(data[0]?.address?.address_line2)
                        setZip(data[0]?.address?.postcode)
                        setCity(data[0]?.address?.city)
                        setRegion(data[0]?.address?.state)
                        setRegionCode(data[0]?.address?.state_code)
                        setCountry(data[0]?.address?.country)
                        setCountryCode(data[0]?.address?.country_code)
                        setLatitude(data[0]?.address?.lat || data[0]?.latitude)
                        setLongitude(data[0]?.address?.lon || data[0]?.longitude)
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }, [defaultProjectId, profileId])

    const updateProjectAddress = async (projectId) => {
        if (projectId) {
            await getProjectByProfileOrId({
                projectIds: [projectId]
            })
                .then((data) => {
                    console.log(data)
                    if (data && data.length > 0) {
                        setSelectedProject(data[0]);
                        setFullAddressLine(data[0]?.address_line1)
                        setStreetAddress(data[0]?.address?.address_line2)
                        setZip(data[0]?.address?.postcode)
                        setCity(data[0]?.address?.city)
                        setRegion(data[0]?.address?.state)
                        setRegionCode(data[0]?.address?.state_code)
                        setCountry(data[0]?.address?.country)
                        setCountryCode(data[0]?.address?.country_code)
                        setLatitude(data[0]?.address?.lat || data[0]?.latitude)
                        setLongitude(data[0]?.address?.lon || data[0]?.longitude)
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (editEventData && editEventData?._id) {
            setAlertObj({
                show: true,
                message: "Asset successfully updated!",
                status: "success"
            })
        } else {
            let profileArr = new Set([]);
            if (selectedOrg?.profile?._id) {
                profileArr.add(selectedOrg?.profile?._id)
            }
            if (selectedProject?.profile?._id) {
                profileArr.add(selectedProject?.profile?._id)
            }
            let profileRoleArr = []
            selectedRequiredAttendees.map((s) => {
                profileArr.add(s?.value)
                profileRoleArr.push({
                    user: s?.value,
                    role: "Required",
                    parent: null,
                    parentModelName: "CalendarEvent",
                    creator: user?.profile,
                    createdAt: new Date()
                })
            })
            selectedOptionalAttendees.map((s) => {
                profileArr.add(s?.value);
                profileRoleArr.push({
                    user: s?.value,
                    role: "Optional",
                    parent: null,
                    parentModelName: "CalendarEvent",
                    creator: user?.profile,
                    createdAt: new Date()
                })
            })
            const newEventData = {
                title: eventName,
                description: eventDescription,
                type: eventType,
                location: {
                    address_line1: fullAddressLine,
                    address_line2: streetAddress,
                    city: city,
                    country: country,
                    country_code: countryCode,
                    latitude: latitude,
                    longitude: longitude,
                    state: region,
                    state_code: regionCode,
                    postcode: zip
                },
                managers: managers.map((s) => s?.data?.parent?._id),
                startDate: startDate.toDate(),
                endDate: endDate.toDate(),
                durationMins: endDate.diff(startDate, "minute"),
                participants: [...profileArr],
                roles: profileRoleArr,
                addedBy: user?.profile,
                user: user?._id
            }
            if (selectedOrg?._id) {
                newEventData.organization = selectedOrg?._id
            }
            if (selectedProject?._id) {
                newEventData.project = selectedProject?._id
            }
            setLoader(true)
            await saveAction(newEventData)
            setEventType(defaultType || "Meeting")
            setSelectedOrg(null)
            setSelectedProject(null)
            setEventName("");
            setEventDescription("");
            setStartDate(dayjs());
            setEndDate(dayjs());
            setSelectedRequiredAttendees([]);
            setSelectedOptionalAttendees([]);
            setFullAddressLine("");
            setStreetAddress("");
            setZip("");
            setCity("");
            setRegion("");
            setRegionCode("");
            setCountry(null);
            setCountryCode(null);
            setLatitude(null);
            setLongitude(null);
            setLoader(false)
        }
    }

    return (
        <form className={classes.mainDialogCont} onSubmit={onSubmit} >
            <div className={classes.bodyCont} >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <p className={classes.label} >Type</p>
                            <Autocomplete
                                options={["Meeting", "Phone Call", "Site Visit", "Follow-up", "Other"]}
                                value={eventType}
                                onChange={(evt, eventTypeT) => {
                                    setEventType(eventTypeT)
                                    setEventName(palUserName ? `${palUserName.trim().replace(" ", "_")}-${eventTypeT.replace(" ", "_")}-` : `${eventTypeT.replace(" ", "_")}-`)
                                }}
                                getOptionLabel={(option) => option || ""}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        size="small"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <p className={classes.label} >Title</p>
                            <TextField
                                autoFocus
                                fullWidth
                                placeholder="Enter title"
                                value={eventName}
                                onChange={(e) => setEventName(e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <p className={classes.label} >Description</p>
                            <TextField
                                multiline
                                fullWidth
                                value={eventDescription}
                                onChange={(e) => setEventDescription(e.target.value)}
                                variant="outlined"
                                size="small"
                                rows={4}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <p className={classes.label} >Start Date</p>
                            <DateTimePicker
                                value={startDate}
                                onChange={(newValue) => setStartDate(newValue)}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        fullWidth: true,
                                        InputProps: {
                                            value: dayjs(startDate).format("D MMM YYYY, h:mm A"),
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <p className={classes.label} >End Date</p>
                            <DateTimePicker
                                value={endDate}
                                onChange={(newValue) => setEndDate(newValue)}
                                slotProps={{
                                    textField: {
                                        size: "small",
                                        fullWidth: true,
                                        InputProps: {
                                            value: dayjs(endDate).format("D MMM YYYY, h:mm A"),
                                        },
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <p className={classes.label} >Required Attendees</p>
                            <PaginatedEntityDropdown
                                value={selectedRequiredAttendees}
                                onChange={(value) => {
                                    if (value.length > 0 && value.length > selectedRequiredAttendees.length) {
                                        let optionalIds = selectedOptionalAttendees.map((s) => s?.value)
                                        if (!optionalIds.includes(value[value.length - 1]?.value)) {
                                            setSelectedRequiredAttendees(value)
                                        }
                                    } else {
                                        setSelectedRequiredAttendees(value)
                                    }
                                }}
                                isMulti={true}
                                palCreate={false}
                                noFilter={true}
                                financialRelationType={"Admin"}
                                givenFilterOption={[
                                    {
                                        option: "Network",
                                        types: ["User"]
                                    }
                                ]}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <p className={classes.label} >Optional Attendees</p>
                            <PaginatedEntityDropdown
                                value={selectedOptionalAttendees}
                                onChange={(value) => {
                                    if (value.length > 0 && value.length > selectedOptionalAttendees.length) {
                                        let optionalIds = selectedRequiredAttendees.map((s) => s?.value)
                                        if (!optionalIds.includes(value[value.length - 1]?.value)) {
                                            setSelectedOptionalAttendees(value)
                                        }
                                    } else {
                                        setSelectedOptionalAttendees(value)
                                    }
                                }}
                                isMulti={true}
                                palCreate={false}
                                noFilter={true}
                                financialRelationType={"Admin"}
                                givenFilterOption={[
                                    {
                                        option: "Network",
                                        types: ["User"]
                                    }
                                ]}
                            />
                        </Grid>
                        {(eventType === "Site Visit" || eventType === "Meeting") && (
                            <Grid item xs={6}>
                                <p className={classes.label} >Account Manager(s)</p>
                                <PaginatedEntityDropdown
                                    value={managers}
                                    onChange={(value) => {
                                        setManagers(value)
                                    }}
                                    isMulti={true}
                                    palCreate={false}
                                    noFilter={true}
                                    financialRelationType={"Admin"}
                                    givenFilterOption={[
                                        {
                                            option: "Network",
                                            types: ["User"]
                                        }
                                    ]}
                                />
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <p className={classes.label} >Project(s)</p>
                            <ProjectPicker
                                selectedProject={selectedProject}
                                setSelectedProject={(value) => {
                                    setSelectedProject(value)
                                    updateProjectAddress(value?._id)
                                }}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <p className={classes.label} >Organization(s)</p>
                            <OrgPicker
                                selectedOrg={selectedOrg}
                                setSelectedOrg={setSelectedOrg}
                                defaultOrganizationProfileId={profileId}
                                fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <p className={classes.label} >Location</p>
                            <div style={{ border: "1px solid gray", borderRadius: "5px", padding: "10px" }} >
                                <GooglePlaceAutocomplete
                                    inputContStyle={classes.addressCont}
                                    autoWidth={"100%"}
                                    textWidth={"100%"}
                                    isGetLogLat={true}
                                    fullAddressLine={fullAddressLine}
                                    setFullAddressLine={setFullAddressLine}
                                    streetAddress={streetAddress}
                                    setStreetAddress={setStreetAddress}
                                    zip={zip}
                                    setZip={setZip}
                                    city={city}
                                    setCity={setCity}
                                    region={region}
                                    setRegion={setRegion}
                                    regionCode={regionCode}
                                    setRegionCode={setRegionCode}
                                    country={country}
                                    setCountry={setCountry}
                                    countryCode={countryCode}
                                    setCountryCode={setCountryCode}
                                    latitude={latitude}
                                    setLatitude={setLatitude}
                                    longitude={longitude}
                                    setLongitude={setLongitude}
                                    isShowCountry={true}
                                    isShowCityStreet={true}
                                    isStateZip={true}
                                    noAddressShow={false}
                                    noMap={false}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </div>
            <div style={{ width: "100%", height: "5px" }} >
                {loader && (<LinearProgress />)}
            </div>
            <div className={classes.bottomAct} >
                <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    type="submit"
                >
                    Save & Close
                </Button>
            </div>
            <NotifyAlert
                alertObj={alertObj}
                setAlertObj={setAlertObj}
            />
        </form>
    );
}