import React, { useEffect, useState } from "react";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch } from "react-redux";
import { searchUsers } from "../../contacts/api.call";
import {
  Avatar,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  InputAdornment
} from "@mui/material";
import TextField from "./TextField";
import { useDebounce } from "react-use";
import SearchIcon from '@material-ui/icons/Search';

const UserSearcher = ({ defaultValue, onSelect }) => {
  const dispatch = useDispatch();

  const [text, setText] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const searchUser = async () => {
    if (!text || text?.length < 3) return;
    try {
      const { data } = await searchUsers({ name: text });

      if (data) {
        setSearchResults(data);
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useDebounce(
    () => {
      searchUser();
    },
    500,
    [text]
  );

  useEffect(() => {
    if (defaultValue) {
      setSearchResults([defaultValue]);
    }
  }, [defaultValue]);

  return (
    <Autocomplete
      freeSolo={true}
      options={searchResults}
      defaultValue={defaultValue}
      onChange={(evt, val) => onSelect(val)}
      getOptionLabel={(option) => option?.displayName}
      renderOption={(props, option, { selected }) => {
        return (
          <ListItemButton {...props} selected={selected}>
            <ListItemAvatar>
              <Avatar src={option?.displayPicture?.thumbUrl} />
            </ListItemAvatar>
            <ListItemText
              primary={option?.displayName}
              secondary={option?.email}
            />
          </ListItemButton>
        );
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            defaultValue ? defaultValue?.displayName : "Search Users"
          }
          value={text}
          onChange={(e) => setText(e.target.value)}
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export default UserSearcher;
