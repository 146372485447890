import React, { useEffect, useState } from "react";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import SearchField from "../../styled/generic/SearchField";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  PaginationItem,
  IconButton,
  Pagination,
  Typography,
  Paper,
  Avatar,
  Divider,
  Chip,
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  Close as CloseIcon,
  Refresh as RefreshIcon,
  Check as CheckIcon,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";
import NumberFormat from "react-number-format";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import Api from "../../../helpers/Api";
import { useDispatch, useSelector } from "react-redux";
import { Drawer, List, ListItem, ListItemText, Checkbox } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import BookingCard from "./BookingCard";
import { getBookingPaymentAmountAnalysis } from "./api";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export const calculateBookingFinancials = (booking) => {
  const agreementValue = booking?.paymentDetails?.reduce((total, component) => {
    if (component?.componentRef?.aggrementBased) {
      return total + component?.amountReceived
        ? parseInt(component?.amountReceived)
        : 0;
    }
    return total;
  }, 0);

  const totalReceivable = booking?.paymentDetails?.reduce((acc, detail) => {
    return acc + (detail.amountReceivable || 0);
  }, 0);
  const totalPaid = booking?.paymentDetails?.reduce((acc, detail) => {
    return acc + (detail?.amountReceived || 0);
  }, 0);
  const totalDue = totalReceivable - totalPaid;
  const paymentProgress = booking?.aggrement?.paymentProgress || 0;

  return {
    agreementValue,
    totalReceivable,
    totalPaid,
    totalDue,
    paymentProgress,
  };
};

export const BrokerAmountCard = ({ brokerData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Paper
      elevation={2}
      sx={{
        p: 2,
        border: '1px solid',
        borderColor: 'divider',
        borderRadius: 2,
        position: 'relative',
        '&:before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: 4,
          bgcolor: 'primary.main',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
        },
      }}
    >
      <Box display="flex" alignItems="center" gap={1.5} mb={2}>
        <Avatar
          src={brokerData.broker?.displayPicture?.url}
          sx={{ width: 40, height: 40 }}
        />
        <Box flex={1} minWidth={0}>
          <Typography
            variant="subtitle1"
            fontWeight={600}
            noWrap
            sx={{ maxWidth: 'calc(100% - 50px)' }}
          >
            {brokerData.broker?.displayName}
          </Typography>
          <Typography variant="caption" color="text.secondary">
            {brokerData.statusBreakdown?.length} statuses
          </Typography>
        </Box>
      </Box>

      <Grid container spacing={1} sx={{ mb: 2 }}>
        {[
          { label: 'Total', value: (parseFloat(brokerData?.totalAmountPayable || 0)).toFixed(2) },
          { label: 'Due', value: (parseFloat(brokerData?.totalDueAmount || 0)).toFixed(2) },
          { label: 'Paid', value: (parseFloat(brokerData?.totalAmountPaid || 0)).toFixed(2) },
        ].map((metric, idx) => (
          <Grid item xs={4} key={idx}>
            <Box textAlign="left">
              <Typography variant="caption" color="text.secondary" display="block">
                {metric.label}
              </Typography>
              <NumberFormat
                value={metric.value}
                displayType="text"
                thousandSeparator={true}
                prefix="₹"
                renderText={(value) => (
                  <Typography
                    variant="body2"
                    fontWeight={500}
                    sx={{
                      wordBreak: 'break-word',
                      minWidth: 60,
                      display: 'inline-block',
                    }}
                  >
                    {value}
                  </Typography>
                )}
              />
            </Box>
          </Grid>
        ))}
      </Grid>

      <Divider sx={{ mb: 2 }} />

      <Box sx={{ display: 'flex', overflowX: 'auto', gap: 1, pb: 1 }}>
        {brokerData.statusBreakdown?.map((status, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 0.5,
              p: 0.5,
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
            }}
          >
            <Typography variant="caption" color="text.primary" noWrap>
              {status.status}
            </Typography>
            <NumberFormat
              value={(parseFloat(status?.totalAmountPayable || 0)).toFixed(2)}
              displayType="text"
              thousandSeparator={true}
              prefix="₹"
              renderText={(value) => (
                <Typography variant="caption" fontWeight={500} noWrap>
                  {value}
                </Typography>
              )}
            />
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

const Bookings = ({ libraryId, projectIds }) => {
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const [bookings, setBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [gridLoading, setGridLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterScreen, setFilterScreen] = useState("Projects");
  const [projects, setProjects] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const { teamDictionary, teamIds } = useSelector((state) => state.team);
  const [selectedProjects, setSelectedProjects] = useState(projectIds || []);
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [search, setSearch] = useState("");
  const [profile, setProfile] = useState(null);
  const [cancelledBookings, setCancelledBookings] = useState("all");
  const [analysisData, setAnalysisData] = useState([])

  const getAmountAnalysisData = async () => {
    await getBookingPaymentAmountAnalysis({ profileId })
      .then((data) => {
        console.log(data)
        setAnalysisData(data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getAmountAnalysisData()
  }, [])


  useEffect(() => {
    if (profile?.parentModelName === "Organization") {
      setFilterScreen("Projects");
    } else {
      setFilterScreen("Status");
    }
  }, [profile]);

  const setUpProjects = () => {
    for (let i = 0; i < teamIds.length; i++) {
      let id = teamIds[i];
      let team = teamDictionary[id];
      if (team?.parent?.model === "Project") {
        if (projects.includes(team?.parent)) {
          continue;
        } else {
          setProjects((prev) => [...prev, team?.parent]);
        }
      }
    }
  };

  const getBlocks = async () => {
    const data = await Api.post(
      "public/project/block/get/against/multiple-projects",
      { projectIds: projects.map((project) => project?._id) }
    );

    if (data && data?.data) {
      let restructuredBlocks = data?.data.map((block) => ({
        name: block.name,
        _id: block?._id,
        isRental: block.isRental,
        totalFloors: block.totalFloors,
        //  rentalUnits: item.rentalUnits || [],
      }));
      setBlocks(restructuredBlocks);
    }
  };

  useEffect(() => {
    if (projects.length > 0) {
      getBlocks();
    }
  }, [projects]);

  useEffect(() => {
    if (!projects.length > 0) {
      setUpProjects();
    }
  }, []);

  const BOOKING_STATUS = ["DRAFT", "SENT", "CHECKING", "PAID"];
  const FILTER_SCREENS = [
    ...(profile?.parentModelName === "Organization"
      ? [{ label: "Projects", size: projects.length }]
      : []),
    { label: "Status", size: BOOKING_STATUS.length },
  ];

  const handleClearAll = () => {
    setSelectedBlocks([]);
    setSelectedProjects([]);
    setSelectedStatus([]);
  };

  const handlePageChange = (newPage) => {
    window.scrollTo(0, 0);
    setCurrentPage(newPage);
  };

  const getProfile = async () => {
    const data = await Api.post(`/profile/get/`, {
      profileIds: [profileId],
    });
    if (data) {
      setProfile(data[0]);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to fetch profile",
        },
      });
    }
  };

  useEffect(() => {
    if (profileId) {
      getProfile();
    }
  }, [profileId]);

  const payload = {
    libraryId: libraryId,
    projectIds: [...selectedProjects],
    blockIds: selectedBlocks,
    customerName: search,
    statuses: selectedStatus,
  };

  if (cancelledBookings !== "all") {
    payload.cancelled = cancelledBookings;
  }

  const getBookings = async (page = 1, limit = 12) => {
    const data = await Api.post(
      `/listing/booking/get?page=${page}&limit=${limit}`,
      payload
    );

    if (data) {
      setTotalPages(data.totalPages);
      setBookings(data?.bookings);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
    }
  };

  useEffect(() => {
    if (libraryId && profile && projectIds) {
      getBookings().finally(() => {
        setPageLoading(false);
      });
    }
  }, [libraryId, profile, projectIds]);

  useEffect(() => {
    setGridLoading(true);
    getBookings(currentPage).finally(() => {
      setGridLoading(false);
    });
  }, [currentPage, search, cancelledBookings]);

  if (pageLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="85vh"
        width="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>

      <StandardAppContainerRounded>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <SearchField
            fullWidth={true}
            placeholder="Search customer name"
            size="small"
            width="50%"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <FormControl
            sx={{
              width: "200px",
            }}
          >
            <InputLabel id="select-label">Status</InputLabel>
            <Select
              onChange={(e) => setCancelledBookings(e.target.value)}
              value={cancelledBookings}
              defaultValue={"all"}
              labelId="select-label"
              label="Age"
              variant="outlined"
              size="small"
            >
              <MenuItem value={"all"}>All</MenuItem>
              <MenuItem value={true}>Cancelled</MenuItem>
              <MenuItem value={false}>Non-Cancelled</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            onClick={() => setShowFilter(true)}
            startIcon={<FilterAltIcon />}
          >
            Filter
          </Button>
        </Box>
        {gridLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="70vh"
            width="100%"
            flexDirection={"column"}
            gap={"10px"}
          >
            <CircularProgress />
            <Typography color={"primary"}>Loading...</Typography>
          </Box>
        ) : (
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {bookings?.map((booking) => {
              return (
                <Grid item lg={3} md={6} xs={12}>
                  <BookingCard booking={booking} />
                </Grid>
              );
            })}
          </Grid>
        )}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mt: 2,
          }}
        >
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(event, page) => handlePageChange(page)}
            variant="outlined"
            shape="circular"
            renderItem={(item) => (
              <PaginationItem
                slots={{ previous: ArrowBack, next: ArrowForward }}
                {...item}
              />
            )}
          />
        </Box>

        {/* Filter drawer */}
        <FilterDrawer
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          gridLoading={gridLoading}
          setGridLoading={setGridLoading}
          filterScreen={filterScreen}
          setFilterScreen={setFilterScreen}
          selectedProjects={selectedProjects}
          setSelectedProjects={setSelectedProjects}
          selectedStatus={selectedStatus}
          setSelectedStatus={setSelectedStatus}
          projects={projects}
          BOOKING_STATUS={BOOKING_STATUS}
          getBookings={getBookings}
          handleClearAll={handleClearAll}
          FILTER_SCREENS={FILTER_SCREENS}
        />
      </StandardAppContainerRounded>
    </>
  );
};

export default Bookings;

const FilterDrawer = ({
  showFilter,
  setShowFilter,
  gridLoading,
  setGridLoading,
  filterScreen,
  setFilterScreen,
  selectedProjects,
  setSelectedProjects,
  selectedStatus,
  setSelectedStatus,
  projects,
  BOOKING_STATUS,
  getBookings,
  handleClearAll,
  FILTER_SCREENS,
}) => {
  return (
    <Drawer
      anchor="right"
      open={showFilter}
      onClose={() => {
        if (!gridLoading) {
          setShowFilter(false);
        }
      }}
      variant="temporary"
      sx={{
        "& .MuiDrawer-paper": {
          width: 500,
          maxWidth: "100%",
          borderTopLeftRadius: 16,
          borderBottomLeftRadius: 16,
        },
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: 1,
            borderColor: "divider",
          }}
        >
          <Typography
            variant="h6"
            color="primary"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            <FilterListIcon /> Filters
          </Typography>
          <Box>
            <Button
              disabled={gridLoading}
              variant="outlined"
              size="small"
              startIcon={<RefreshIcon />}
              onClick={() => {
                setGridLoading(true);
                getBookings().finally(() => {
                  handleClearAll();
                  setShowFilter(false);
                  setGridLoading(false);
                });
              }}
              sx={{ mr: 1 }}
            >
              Reset all
            </Button>
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => setShowFilter(false)}
              disabled={gridLoading}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>

        <Box display="flex" flexGrow={1} sx={{ overflow: "hidden" }}>
          <List
            sx={{
              width: 200,
              borderRight: 1,
              borderColor: "divider",
              overflowY: "auto",
            }}
          >
            {FILTER_SCREENS.map((filter, index) => (
              <ListItem
                key={index}
                button
                selected={filterScreen === filter.label}
                onClick={() => setFilterScreen(filter.label)}
              >
                <ListItemText primary={filter.label} />
                <Typography variant="caption" color="text.secondary">
                  {filter.size}
                </Typography>
              </ListItem>
            ))}
          </List>

          <Box sx={{ flexGrow: 1, overflowY: "auto" }}>
            {filterScreen === "Projects" && (
              <List>
                {projects.map((project, index) => (
                  <ListItem
                    key={index}
                    button
                    onClick={() => {
                      setSelectedProjects((prev) =>
                        prev.includes(project?._id)
                          ? prev.filter((item) => item !== project?._id)
                          : [...prev, project?._id]
                      );
                    }}
                  >
                    <Checkbox
                      checked={selectedProjects.includes(project?._id)}
                      color="primary"
                    />
                    <ListItemText primary={project.displayName} />
                  </ListItem>
                ))}
              </List>
            )}

            {filterScreen === "Status" && (
              <List>
                {BOOKING_STATUS.map((status) => (
                  <ListItem
                    key={status}
                    button
                    onClick={() => {
                      setSelectedStatus((prev) =>
                        prev.includes(status)
                          ? prev.filter((item) => item !== status)
                          : [...prev, status]
                      );
                    }}
                  >
                    <Checkbox
                      checked={selectedStatus.includes(status)}
                      color="primary"
                    />
                    <ListItemText primary={status} />
                  </ListItem>
                ))}
              </List>
            )}
          </Box>
        </Box>

        <Box
          sx={{
            p: 2,
            borderTop: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "flex-end",
            gap: 2,
          }}
        >
          <Button
            disabled={gridLoading}
            variant="outlined"
            onClick={() => setShowFilter(false)}
            startIcon={<CloseIcon />}
          >
            Close
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            loading={gridLoading}
            onClick={() => {
              setGridLoading(true);
              getBookings().finally(() => {
                setGridLoading(false);
                setShowFilter(false);
              });
            }}
            startIcon={<CheckIcon />}
          >
            Apply
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  );
};
