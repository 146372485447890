import Api from "../../../../helpers/Api";

export const getPaginatedOptimizedBlocks = async (obj) => {
    try {
        const res = await Api.post('get/optimize/block/paginated', obj);
        const result = res || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getPaginatedOptimizedUnits = async (obj) => {
    try {
        const res = await Api.post('get/optimize/unit/paginated', obj);
        const result = res || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getPaginatedOptimizedTasks = async (obj) => {
    try {
        const res = await Api.post('get/optimize/task/paginated', obj);
        const result = res || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getPaginatedOptimizedWarehouse = async (obj) => {
    try {
        const res = await Api.post('get/optimize/warehouse/paginated', obj);
        const result = res || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getBillingAccountsByFinrel = async (obj) => {
    try {
        const res = await Api.post('wallet/billing/billing-account/get/by-finrel', obj);
        const result = res || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const linkeResourcesWithResource = async (obj) => {
    try {
        const res = await Api.post('link/resources/with/resource', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getLinkedResourcesByParent = async (obj) => {
    try {
        const res = await Api.post('get/linked/resources', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};