import React, { useEffect, useState } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Paper, Checkbox, IconButton, Box, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import LessText from '../LessText';
import { FaTasks } from "react-icons/fa";
import config from '../../../../config/index'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
    paperMainCont: {
        width: "100%",
        display: "flex",
        alignContent: "center",
        justifyContent: "space-between",
        padding: "10px",
        "&:hover": {
            transform: "scale(1.02)"
        },
        "& .MuiIconButton-root":{
            padding: "8px",
        }
    },
    detailsCont: {
        width: "calc(100% - 60px)",
        "& h3": {
            fontSize: "18px",
            fontWeight: "500",
            marginBottom: "-3px"
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "500",
            color: "gray"
        },
        "& h4": {
            fontSize: "14px",
            color: "gray"
        },
        "& h5": {
            fontSize: "14px",
            color: "black",
            marginLeft: "10px"
        },
    },
    avatarNameCont: {
        width: "100%",
        display: "flex",
        alignItems: "center"
    },
    progressContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '8px',
        gap: '8px',
    },
    progressBar: {
        flexGrow: 1,
        height: 8,
        borderRadius: 4,
    },
    progressText: {
        fontSize: '12px',
        color: 'gray',
        minWidth: '45px',
    }
}));

const TaskCard = ({
    cardData, linkedTasks, setLinkedTasks,
    isForRemove = false, removeFun, loader,
    needToMakeDisable, isRedirect = false
}) => {
    const classes = useStyles();
    const history = useHistory();
    const [selectedIds, setSelectedIds] = useState([])
    useEffect(() => {
        setSelectedIds(linkedTasks.map((s) => s?._id))
    }, [linkedTasks])
    var isDev = config?.mode == 'dev'

    return (
        <Paper
            elevation={2}
            className={classes.paperMainCont}
            onClick={() => {
                if (isRedirect) {  // Only redirect if isRedirect is true
                    if (isDev) {
                        window.open(`/planning/task/${cardData?._id}`, "_blank");
                    } else if (config.mode == 'prod') {
                        let link = `https://planning.reallist.ai/planning/task/${cardData?._id}`
                        window.open(link, "_blank");
                    }
                }
              }}
              style={{ cursor: isRedirect ? "pointer" : "default" }}  
        >
            <div className={classes.detailsCont} >
                <div className={classes.avatarNameCont} >
                    <Avatar style={{ width: "38px", height: "38px" }} >
                        <FaTasks />
                    </Avatar>
                    <div style={{ marginLeft: "10px" }} >
                        <h3>
                            <LessText
                                limit={20}
                                string={`${cardData?.mspId || ""} ${cardData?.taskName}`}
                            />
                        </h3>
                        <p>{cardData?.target?.title}</p>
                        <div className={classes.progressContainer}>
                            <LinearProgress 
                                variant="determinate" 
                                value={cardData?.progress || 0}
                                className={classes.progressBar}
                            />
                            <span className={classes.progressText}>
                                {`${Math.round(cardData?.progress || 0)}%`}
                            </span>
                        </div>
                    </div>
                </div>
                {(cardData?.ancestors && cardData.ancestors.length > 0) && (
                    <Box sx={{
                        mt: 1,
                        mb: 1,
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: '4px',
                        maxHeight: '3.6em',
                        overflowY: 'auto',
                        marginTop: "10px",
                        '&::-webkit-scrollbar': { width: '4px' },
                        '&::-webkit-scrollbar-track': { background: '#f1f1f1' },
                        '&::-webkit-scrollbar-thumb': { background: '#888', borderRadius: '2px' }
                    }}>
                        {Array.isArray(cardData?.ancestors) && cardData.ancestors
                            .filter(ancestor => ancestor && typeof ancestor === 'object' && ancestor._id)
                            .map((ancestor, index, filteredAncestors) => (
                                <Box
                                    key={ancestor._id}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: '#f5f5f5',
                                        borderRadius: '4px',
                                        padding: '2px 6px',
                                    }}

                                >
                                    <Typography
                                        sx={{
                                            fontSize: '0.7rem',
                                            color: '#666',
                                            lineHeight: '1.2',
                                        }}
                                    >
                                        {ancestor.taskName?.length > 20
                                            ? `${ancestor.taskName.substring(0, 20)}...`
                                            : ancestor.taskName || 'Unnamed'}
                                    </Typography>
                                    {index < filteredAncestors.length - 1 && (
                                        <Typography
                                            sx={{
                                                fontSize: '0.7rem',
                                                color: '#666',
                                                ml: 0.5
                                            }}
                                        >
                                            {'›'}
                                        </Typography>
                                    )}
                                </Box>
                            ))}
                    </Box>
                )}
            </div>
            {!needToMakeDisable && (<>
                {isForRemove ? (
                    <IconButton
                        onClick={() => {
                            removeFun(cardData)
                        }}
                        disabled={loader}
                    >
                        <CancelIcon />
                    </IconButton>
                ) : (
                    <Checkbox
                        checked={selectedIds.includes(cardData?._id)}
                        onChange={(event) => {
                            if (event.target.checked) {
                                setLinkedTasks([...linkedTasks, cardData])
                            } else {
                                setLinkedTasks(linkedTasks.filter((s) => s?._id != cardData?._id))
                            }
                        }}
                        disabled={loader}
                        color="primary"
                    />
                )}
            </>)}
        </Paper>
    );
};

export default TaskCard;