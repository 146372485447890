import { Add } from "@material-ui/icons";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Api from "../../../helpers/Api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CRMCard from "../../issue/issue.crm.card";

const IssuesList = ({ bookingId }) => {
  const [searchStr, setSearchStr] = useState("");
  const [issues, setIssues] = useState([]);
  const [pageLoading, setPageLoading] = useState(true);

  const getIssues = async () => {
    const { data } = await Api.post(`bookings/getIssues?q=${searchStr}`, {
      bookingIds: [bookingId],
    });
    if (data) {
      setIssues(data);
    } else {
      alert("Something wrong while fetching data");
    }
  };

  useEffect(() => {
    if (bookingId) {
      setPageLoading(true);
      getIssues().finally(() => {
        setPageLoading(false);
      });
    }
  }, [bookingId]);

  useEffect(() => {
    const searchTimeout = setTimeout(() => {
      getIssues();
    }, 1000);
    return () => clearTimeout(searchTimeout);
  }, [searchStr]);

  return (
    <Box
      sx={{
        padding: "15px",
      }}
    >
      <Box>
        <TextField
          placeholder="Search Issues"
          size="small"
          fullWidth
          value={searchStr}
          onChange={(e) => setSearchStr(e.target.value)}
        />
      </Box>

      <Grid
        container
        spacing={2}
        sx={{
          paddingY: "20px",
        }}
      >
        {pageLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "40vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size={30} />
          </Box>
        ) : !issues.length > 0 ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "20vh",
              width: "100%",
            }}
          >
            <Typography variant="body1">Issues not found</Typography>
          </Box>
        ) : (
          issues.map((issue, index) => (
            <CRMCard
              hideStatus={true}
              key={index}
              issue={issue}
              xs={12}
              md={6}
              lg={3}
              onIssueUpdate={(newIssue) => {
                const newIssues = issues.map((oldIssue) => {
                  if (oldIssue._id === newIssue._id) {
                    return newIssue;
                  }
                  return oldIssue;
                });
                setIssues(newIssues);
              }}
              onIssueDelete={(deletedIssueId) => {
                const newIssues = issues.filter(
                  (oldIssue) => String(oldIssue._id) !== String(deletedIssueId)
                );
                setIssues(newIssues);
              }}
            />
          ))
        )}
        <Box></Box>
      </Grid>
    </Box>
  );
};

export default IssuesList;
