import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { format } from "timeago.js";
import ReactHtmlParser from "react-html-parser";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Avatar,
  IconButton,
  Box,
} from "@material-ui/core";
import {
  Visibility,
  Edit,
  ThumbUp,
  Comment,
  History,
} from "@material-ui/icons";
import DescriptionIcon from "@mui/icons-material/Description";

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    transition: "transform 0.3s",
    "&:hover": {
      transform: "translateY(-4px)",
      boxShadow: theme.shadows[6],
    },
    width: "55%",
    minWidth: "350px",
    height: "fit-content",
    padding: "0px 10px",
  },
  headerSection: {
    display: "flex",
    alignItems: "center",
  },
  topRightIcons: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  titleSection: {
    display: "flex",
    flexDirection: "column",
  },
  metaInfo: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
  },
  chip: {
    backgroundColor: (props) =>
      props.status === "VERIFIED" ? "#4caf50" : "#ff9800",
    color: "white",
    margin: theme.spacing(1, 0),
    fontSize: "0.75rem",
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    width: "fit-content",
    float: "right",
    height: "fit-content",
    marginRight: "10px",
  },
  description: {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
  },
  chipContainer: {
    display: "flex",
    gap: theme.spacing(1),
    flexWrap: "wrap",
    justifyContent: "flex-end",
    width: "100%",
    float: "right",
  },
  avatarGroup: {
    display: "flex",
    gap: theme.spacing(1),
  },
  actionButton: {
    color: (props) => (props.isLiked ? theme.palette.primary.main : "default"),
    padding: "0px",
    marginRight: "12px",
  },
}));

const DocCard = ({ doc, status, forCustomer }) => {
  const classes = useStyles({ status, isLiked: doc.isLiked });
  const history = useHistory();
  const dispatch = useDispatch();

  const handleView = (doc) => {
    dispatch({
      type: "AddDoc",
      payload: {
        tempDoc: doc,
      },
    });
    history.push(`/doc/view/${doc._id}`);
  };

  const handleEdit = (doc) => {
    dispatch({
      type: "AddDoc",
      payload: {
        tempDoc: doc,
      },
    });
    const path =
      doc.docType === "PUBLICDOC"
        ? `/publicdoc/edit/${doc._id}`
        : `/doc/edit${forCustomer ? "/customer" : ""}/${doc._id}`;
    history.push(path);
  };

  const truncateDescription = (description, limit) => {
    const strippedHtml = description ? description.replace(/<[^>]+>/g, "") : "";
    return strippedHtml.length > limit
      ? strippedHtml.slice(0, limit) + "..."
      : strippedHtml;
  };

  return (
    <Card className={classes.card}>
      <div className={classes.topRightIcons}>
        {status && <div className={classes.chip}>{status}</div>}
        <IconButton onClick={() => handleView(doc)}>
          <Visibility fontSize="small" color="primary" />
        </IconButton>
        {doc.docType !== "ESIGN_ENVELOPE" && (
          <IconButton onClick={() => handleEdit(doc)} variant="outlined">
            <Edit fontSize="small" color="secondary" />
          </IconButton>
        )}
      </div>
      <CardContent style={{ padding: "8px 10px" }}>
        <div className={classes.headerSection}>
          <Avatar className={classes.avatar}>
            <DescriptionIcon />
          </Avatar>
          <div className={classes.titleSection}>
            <Typography variant="h6" style={{ fontSize: "1.2rem" }}>
              {doc.title}
            </Typography>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className={classes.metaInfo}>
            <History fontSize="small" style={{ marginRight: "15px" }} />
            <span>
              {doc.allVersions?.length || 1} version
              {(doc.allVersions?.length || 1) !== 1 ? "s" : ""}
            </span>
            <span style={{ margin: "0 4px" }}>•</span>
            <span>{format(doc.updatedAt)}</span>
          </div>
          <CardActions style={{ float: "right", marginRight: "10px" }}>
            <div>
              <IconButton className={classes.actionButton}>
                <ThumbUp fontSize="small" />
              </IconButton>
              <span style={{ marginRight: "12px" }}>{doc.likeCount || 0}</span>
              <IconButton className={classes.actionButton}>
                <Comment fontSize="small" />
              </IconButton>
              <span>{doc.commentCount || 0}</span>
            </div>
          </CardActions>
        </div>
        <Typography className={classes.description}>
          {ReactHtmlParser(truncateDescription(doc.description, 150))}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DocCard;
