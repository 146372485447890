import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  TextField,
  Grid,
  CircularProgress,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Chip,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel,
  Divider,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Delete,
  Edit,
  DragIndicator,
  ExpandMore,
} from "@mui/icons-material";
import Api from "../../../helpers/Api";
import { useSelector, useDispatch } from "react-redux";
import arrayToReducer from "../../../helpers/arrayToReducer";

// Add document form should be moved outside the Accordion and only shown when editing
const DocumentForm = ({ document, onSubmit, onCancel, editingDocIndex }) => {
  const [tempDoc, setTempDoc] = useState(document);

  const handleDocUpdate = async () => {
    onSubmit(tempDoc);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Document Name"
          value={tempDoc.documentName}
          onChange={(e) =>
            setTempDoc({ ...tempDoc, documentName: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          label="Description"
          value={tempDoc.description}
          onChange={(e) =>
            setTempDoc({ ...tempDoc, description: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControlLabel
          control={
            <Switch
              checked={tempDoc.isRequired}
              onChange={(e) =>
                setTempDoc({ ...tempDoc, isRequired: e.target.checked })
              }
            />
          }
          label="Required Document"
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          fullWidth
          type="number"
          label="Max Size (MB)"
          value={tempDoc.maxFileSizeMB}
          onChange={(e) =>
            setTempDoc({ ...tempDoc, maxFileSizeMB: Number(e.target.value) })
          }
          InputProps={{ inputProps: { min: 1, max: 50 } }}
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="contained"
            onClick={handleDocUpdate}
            disabled={!tempDoc.documentName}
          >
            {editingDocIndex >= 0 ? "Update Document" : "Add Document"}
          </Button>
          <Button variant="outlined" onClick={onCancel}>
            Cancel
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

const OnboardingTemplates = ({ libraryId }) => {
  const [mode, setMode] = useState("list"); // list or create
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [editingTemplate, setEditingTemplate] = useState(null);
  const dispatch = useDispatch();
  const [currentTemplateId, setCurrentTemplateId] = useState(null);
  const { user } = useSelector((state) => state.auth);
  // Form state for create/edit mode
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    category: "FLAT_SALE",
    country: "IN",
    requiredDocuments: [],
  });

  // New document form state
  const [newDocument, setNewDocument] = useState({
    documentName: "",
    description: "",
    isRequired: true,
    maxFileSizeMB: 5,
    acceptedFileTypes: ["pdf", "jpg", "jpeg", "png"],
  });

  const [editingDocIndex, setEditingDocIndex] = useState(-1); // -1 means adding new, >= 0 means editing existing

  // Add new state for documents dictionary and order
  const [documentsDict, setDocumentsDict] = useState({});
  const [documentIds, setDocumentIds] = useState([]);

  const fetchTemplates = async (page = 1) => {
    setLoading(true);
    try {
      console.log("Search text:", libraryId);
      const response = await Api.post("doc/folder/template/list", {
        page,
        limit: 12,
        searchText,
        organizationId: libraryId,
      });

      if (response?.data) {
        setTemplates(response.data);
        setTotalPages(response.pagination.totalPages);
        setCurrentPage(response.pagination.currentPage);
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error fetching templates",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (libraryId) {
      fetchTemplates(currentPage);
    }
  }, [libraryId, currentPage, searchText]);

  const initializeTemplate = async () => {
    try {
      const response = await Api.post("doc/folder/template/create", {
        title: "Untitled Template",
        description: "",
        category: "RENTAL_ONBOARDING",
        country: "IN",
        organization: libraryId,
        requiredDocuments: [],
        createdBy: user?._id,
      });

      if (response?.data) {
        setCurrentTemplateId(response.data._id);
        setFormData({
          title: "Untitled Template",
          description: "",
          category: "FLAT_SALE",
          country: "IN",
          requiredDocuments: [],
        });
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error initializing template",
        },
      });
      setMode("list");
    }
  };

  const updateTemplateField = async (field, value) => {
    if (!currentTemplateId) return;

    try {
      await Api.post("doc/folder/template/update", {
        templateId: currentTemplateId,
        [field]: value,
      });
    } catch (error) {
      console.error("Error updating template field:", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: `Error updating ${field}`,
        },
      });
    }
  };

  const handleFormDataChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    // Debounced update to backend
    updateTemplateField(field, value);
  };

  const handleDocumentSubmit = async (updatedDoc) => {
    if (!currentTemplateId) return;

    try {
      setEditingDocIndex(-1);
      const docId =
        editingDocIndex >= 0
          ? documentIds[editingDocIndex]
          : Date.now().toString();
      const updatedDict = { ...documentsDict, [docId]: updatedDoc };
      const updatedIds =
        editingDocIndex >= 0 ? documentIds : [...documentIds, docId];

      setDocumentsDict(updatedDict);
      setDocumentIds(updatedIds);

      await Api.post("doc/folder/template/update", {
        templateId: currentTemplateId,
        requiredDocuments: updatedIds.map((id) => updatedDict[id]),
      });

      setNewDocument({
        documentName: "",
        description: "",
        isRequired: true,
        maxFileSizeMB: 5,
        acceptedFileTypes: ["pdf", "jpg", "jpeg", "png"],
      });
    } catch (error) {
      console.error("Error updating documents:", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error updating documents",
        },
      });
    }
  };

  const handleRemoveDocument = async (index) => {
    if (!currentTemplateId) return;

    try {
      const updatedDocs = formData.requiredDocuments.filter(
        (_, i) => i !== index
      );

      // Update frontend state immediately
      setFormData((prev) => ({
        ...prev,
        requiredDocuments: updatedDocs,
      }));

      // Update documentIds and Dict
      const { newDict, idArr } = arrayToReducer(updatedDocs);
      setDocumentsDict(newDict);
      setDocumentIds(idArr);

      // Update backend
      await Api.post("doc/folder/template/update", {
        templateId: currentTemplateId,
        requiredDocuments: updatedDocs,
      });
    } catch (error) {
      console.error("Error removing document:", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error removing document",
        },
      });
    }
  };

  useEffect(() => {
    if (mode === "create" && !currentTemplateId) {
      initializeTemplate();
    }
  }, [mode]);

  const handleEdit = (template) => {
    const { newDict, idArr } = arrayToReducer(template.requiredDocuments);
    setDocumentsDict(newDict);
    setDocumentIds(idArr);
    setCurrentTemplateId(template._id);
    setFormData({
      title: template.title,
      description: template.description,
      category: template.category,
      country: template.country,
      requiredDocuments: template.requiredDocuments,
    });
    setMode("create");
  };

  // Template Card Component
  const TemplateCard = ({ template }) => (
    <Card sx={{ height: "100%" }}>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography variant="h6" color="primary">
            {template.title}
          </Typography>
          <IconButton size="small" onClick={() => handleEdit(template)}>
            <Edit />
          </IconButton>
        </Box>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          {template.description}
        </Typography>
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap", mb: 2 }}>
          <Chip label={template.category} size="small" color="primary" />
          <Chip label={template.country} size="small" />
        </Box>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          Required Documents: {template.requiredDocuments.length}
        </Typography>
      </CardContent>
    </Card>
  );

  // Modified to handle final submission
  const handleCreateTemplate = async () => {
    try {
      if (!currentTemplateId) return;

      await Api.post("doc/folder/template/update", {
        templateId: currentTemplateId,
        ...formData,
        requiredDocuments: documentIds.map((id) => documentsDict[id]),
        isActive: true,
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Template created successfully",
        },
      });
      setMode("list");
      fetchTemplates(1);
    } catch (error) {
      console.error("Error in handleCreateTemplate:", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error creating template",
        },
      });
    }
  };

  // Handle cancellation
  const handleCancel = async () => {
    try {
      if (currentTemplateId) {
        // Delete the template if cancelled
        await Api.post("doc/folder/template/delete", {
          templateId: currentTemplateId,
        });
      }
    } catch (error) {
      console.error("Error deleting template:", error);
    } finally {
      setMode("list");
      setCurrentTemplateId(null);
    }
  };

  // Replace the documents section with this updated version
  const DocumentsList = () => (
    <Box sx={{ mb: 3 }}>
      {documentIds.map((docId, index) => {
        const doc = documentsDict[docId];
        return (
          <Accordion
            key={docId}
            expanded={editingDocIndex === index}
            onChange={() => {
              setEditingDocIndex(editingDocIndex === index ? -1 : index);
              setNewDocument({ ...documentsDict[docId] });
            }}
            sx={{
              mb: 1,
              "&:before": { display: "none" },
              boxShadow: "none",
              border: "1px solid",
              borderColor: "divider",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMore />}
              sx={{
                "& .MuiAccordionSummary-content": {
                  alignItems: "center",
                },
              }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <DragIndicator
                  sx={{ color: "text.secondary", mr: 1, cursor: "grab" }}
                />
                <Typography sx={{ flex: 1 }}>{doc.documentName}</Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Chip
                    label={doc.isRequired ? "Required" : "Optional"}
                    size="small"
                    color={doc.isRequired ? "primary" : "default"}
                  />
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemoveDocument(index);
                    }}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {editingDocIndex === index && (
                <DocumentForm
                  document={newDocument}
                  setDocument={setNewDocument}
                  onSubmit={handleDocumentSubmit}
                  onCancel={() => setEditingDocIndex(-1)}
                  editingDocIndex={editingDocIndex}
                />
              )}
            </AccordionDetails>
          </Accordion>
        );
      })}

      {/* Add Document Button */}
      <Button
        variant="outlined"
        startIcon={<Add />}
        onClick={() => {
          // Create new empty document
          const newEmptyDoc = {
            documentName: "",
            description: "",
            isRequired: true,
            maxFileSizeMB: 5,
            acceptedFileTypes: ["pdf", "jpg", "jpeg", "png"],
          };

          // Add to formData.requiredDocuments
          setFormData((prev) => ({
            ...prev,
            requiredDocuments: [...prev.requiredDocuments, newEmptyDoc],
          }));

          // Generate a new temporary ID
          const tempId = Date.now().toString();

          // Update documentsDict and documentIds
          setDocumentsDict((prev) => ({
            ...prev,
            [tempId]: newEmptyDoc,
          }));
          setDocumentIds((prev) => [...prev, tempId]);

          // Set this document for editing
          setNewDocument(newEmptyDoc);
          // Open the last item in edit mode
          setEditingDocIndex(formData.requiredDocuments.length);
        }}
        sx={{ mt: 2 }}
      >
        Add Document
      </Button>
    </Box>
  );

  if (mode === "list") {
    return (
      <Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Typography variant="h5">Document Templates</Typography>
          <Button
            variant="contained"
            startIcon={<Add />}
            onClick={() => {
              setEditingTemplate(null);
              setFormData({
                title: "",
                description: "",
                category: "FLAT_SALE",
                country: "IN",
                requiredDocuments: [],
              });
              setMode("create");
            }}
          >
            Save Template
          </Button>
        </Box>

        <TextField
          fullWidth
          size="small"
          placeholder="Search templates..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          sx={{ mb: 3 }}
        />

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", py: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {templates.map((template) => (
              <Grid item xs={12} sm={6} md={4} key={template._id}>
                <TemplateCard template={template} />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 3, gap: 2 }}>
          <IconButton onClick={() => setMode("list")}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h5">
            {editingTemplate ? "Edit Template" : "Create Template"}
          </Typography>
        </Box>

        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} md={8}>
            <TextField
              fullWidth
              label="Template Title"
              value={formData.title}
              onChange={(e) => handleFormDataChange("title", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                value={formData.category}
                label="Category"
                onChange={(e) =>
                  handleFormDataChange("category", e.target.value)
                }
              >
                <MenuItem value="FLAT_SALE">Flat Sale</MenuItem>
                <MenuItem value="RENTAL_ONBOARDING">Rental Onboarding</MenuItem>
                <MenuItem value="EMPLOYMENT">Employment</MenuItem>
                <MenuItem value="BANKING">Banking</MenuItem>
                <MenuItem value="OTHER">Other</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Description"
              multiline
              rows={2}
              value={formData.description}
              onChange={(e) =>
                handleFormDataChange("description", e.target.value)
              }
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper sx={{ p: 3 }}>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" sx={{ mb: 3 }}>
            Document Requirements
          </Typography>

          {/* Document List */}
          <DocumentsList />
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <Button variant="outlined" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleCreateTemplate}
            disabled={
              !formData.title || formData.requiredDocuments.length === 0
            }
          >
            Save
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default OnboardingTemplates;
