const { default: Api } = require("../../helpers/Api");

export const getInitialEntities = async (obj) => {
  try {
    const res = await Api.post("get-initial/search", obj);
    const data = res || [];
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const searchUsers = async (obj) => {
  try {
    const res = await Api.post("/searchUsers", obj);
    const data = res || [];
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const searchEntities = async (obj) => {
  try {
    const res = await Api.post("searchProfile/for-all-entities", obj);
    const data = res || [];
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const addNewFinancialRelation = async (obj) => {
  try {
    const res = await Api.post("wallet/relation/find-or-add/populated", obj);
    const result = res?.data;
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getPaginatedContact = async (obj) => {
  try {
    const res = await Api.post("wallet/relation/get/pagination", obj);
    const result = res?.data || {};
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getContactDetailsById = async (obj) => {
  try {
    const res = await Api.post("wallet/relation/get/contact/details/by-id", obj);
    const result = res?.data || {};
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getMutalOpsByType = async (obj) => {
  try {
    const res = await Api.post("procurement/ops/get/mutal-opsorder/by-type", obj);
    const result = res || {};
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getMutalTxByType = async (obj) => {
  try {
    const res = await Api.post("transaction/get/mutal-tx", obj);
    const result = res?.data || {};
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};


export const getMutalFinancialCertificate = async (obj) => {
  try {
    const res = await Api.post("transaction/get/mutal/financial-certificate", obj);
    const result = res?.data || {};
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getProcurementsAggregatedAmounts = async (obj) => {
  try {
    const res = await Api.post("procurement/ops/get/aggregated/amounts", obj);
    const result = res?.data || {};
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getProjectsByOwner = async (obj) => {
  try {
    const res = await Api.post("get/projects/by-ownerprofile", obj);
    const result = res?.data || {};
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getAggregatedAmountProjectWise = async (obj) => {
  try {
    const res = await Api.post("procurement/ops/get/aggregated/amounts/project-wise", obj);
    const result = res?.data || null;
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getFilterOptionsForIssuesByLead = async (obj) => {
  try {
    const res = await Api.post("issue/get/filter/options/for-issues/by-lead", obj);
    const result = res?.data || null;
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getPaginatedByLeadTickets = async (obj) => {
  try {
    const res = await Api.post("issue/get/with/pagination/by-lead", obj);
    const result = res?.data || null;
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getActivityNotesByLead = async (obj) => {
  try {
    const res = await Api.post("issue/activity/get/by-lead", obj);
    const result = res?.data || {};
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getIssueDocsByLead = async (obj) => {
  try {
    const res = await Api.post("issue/docs/get/by-lead", obj);
    const result = res?.data || {};
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getIssueEventsByLead = async (obj) => {
  try {
    const res = await Api.post("issue/events/get/by-lead", obj);
    const result = res?.data || {};
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getBookingsByCustomer = async (obj) => {
  try {
    const res = await Api.post("listing/booking/get/by-customer", obj);
    const result = res?.data || [];
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getIssuesByBookingLeads = async (obj) => {
  try {
    const res = await Api.post("issue/get/with/pagination/by-lead/by-booking", obj);
    const result = res?.data || [];
    return result;
  } catch (error) {
    console.log(error);
    return null;
  }
};