import axios from "axios";
import React, { useEffect, useState } from "react";
import { AsyncPaginate } from "react-select-async-paginate";
import Api from "../../../helpers/Api";

const CompleteSelect2 = ({
    selectedOption,
    onChange,
    apiUrl,
    limit = 25,
    uniqueField,
    labelField,
    randerLabal,
    isMulti = false,
    additionalFilter = {},
    disabled,
}) => {
    const [key, setKey] = useState(1);

    const loadOptions = async (searchStr, prevOptions, { page }) => {
        const res = await Api.post(`${apiUrl}`, {
            ...additionalFilter,
            searchStr,
            page,
            limit,
        });
        console.log(res);
        const result = res?.data || [];
        const options = [];

        result.map((s) => {
            if (s?.[uniqueField] && s?.[labelField]) {
                options.push({
                    value: s?.[uniqueField],
                    label: randerLabal(s),
                    data: {
                        ...s,
                    },
                });
            }
        });

        return {
            options,
            hasMore: result.length === limit,
            additional: {
                page: page + 1,
            },
        };
    };

    useEffect(() => {
        if (additionalFilter && Object.keys(additionalFilter).length > 0) {
            setKey((prevKey) => prevKey + 1);
        }
    }, [additionalFilter]);

    return (
        <AsyncPaginate
            key={key}
            value={selectedOption}
            loadOptions={loadOptions}
            isDisabled={disabled}
            isMulti={isMulti}
            closeMenuOnSelect={true}
            onChange={onChange}
            additional={{
                page: 1,
            }}
        />
    );
};

export default CompleteSelect2;