import React, { useEffect, useState } from "react";
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Divider,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import ProjectPicker from "./ProjectPicker";
import Api from "../../../helpers/Api";
import { useDispatch } from "react-redux";
import FormBox from "../../styled/generic/FormBox";
import { useHistory } from "react-router-dom";
import { getProfileDataPopulate } from "../../team/procurements/api.call";
import ProjectBlockAndUnitPickerDropdown from "../../styled/generic/ProjectBlockAndUnitPickerDropdown";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import { SALE_TYPE } from "../../../helpers/constants";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const useStyles = makeStyles((theme) => ({
  mainCreateCont: {
    width: "700px",
  },
  bodyCont: {
    width: "100%",
    height: "250px",
    overflowY: "auto",
  },
  bottomSty: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
}));

const CreateBookingModel = ({
  open,
  onClose,
  libraryId,
  profileId,
  setOpen,
  defaultBlock,
  defaultUnit,
  isNeedToRedirect = false,
  customerObj = null,
  isRentalBlocks = null,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const [profile, setProfile] = useState(false);
  const [showProjects, setShowProjects] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [paymentSchedule, setPaymentSchedule] = useState(null);
  const [paymentTables, setPaymentTables] = useState([]);
  const [bookingData, setBookingData] = useState({
    project: null,
    projectBlock: null,
    unit: null,
    area: "",
    rsf: "",
    paymentTable: "",
    paymentSchedule: "",
    project: "",
    organization: "",
    saleableArea: "",
    bookingType: SALE_TYPE.Construction_Linked_Unit_Sale.value,
    bookingDate: null,
  });
  const [createLoading, setCreateLoading] = useState(false);

  const resetForm = () => {
    setBookingData({
      project: null,
      projectBlock: null,
      unit: null,
      area: "",
      rsf: "",
      paymentTable: "",
      paymentSchedule: "",
      project: null,
      organization: null,
      projectBlock: null,
      saleableArea: "",
      bookingType: SALE_TYPE.Construction_Linked_Unit_Sale.value,
    });
    setActiveStep(0);
  };

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      let data = await getProfileDataPopulate({
        profileId: profileId,
      });
      if (data) {
        if (data?.parentModelName === "Project") {
          setShowProjects(false);
          handleUpdateBookingData({ project: data?.parent });
          if (data?.ownerProfile?.parentModelName === "Organization") {
            handleUpdateBookingData({
              organization: data?.ownerProfile?.parent,
            });
          }
        } else {
          setShowProjects(true);
          handleUpdateBookingData({ organization: data?.parent });
        }
        setProfile(data);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Failed to fetch profile",
          },
        });
      }
      setLoading(false);
    };
    if (profileId && open) {
      fetchProfile();
      setBookingData({
        ...bookingData,
        projectBlock: defaultBlock,
        unit: defaultUnit,
      });
    }
  }, [profileId, open]);

  useEffect(() => {
    if (open) resetForm();
    else setTimeout(resetForm, 500);
  }, [open]);

  const steps = [
    "Project and Units",
    "Payment Details",
    "Payment Tables",
    "Additional Details",
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleUpdateBookingData = (newData) => {
    setBookingData((prevData) => ({ ...prevData, ...newData }));
  };

  const handleSubmit = async () => {
    setCreateLoading(true);
    const payload = {
      bookingData: {
        ...bookingData,
        paymentTable: bookingData.paymentTable?._id,
        schedule: bookingData.paymentSchedule?._id,
        project: bookingData.project?._id,
        projectBlock: bookingData.projectBlock?._id,
        unit: bookingData.unit?._id,
        organization: bookingData.organization?._id,
      },
      customerObj: customerObj,
      libraryId: libraryId,
      profileId: profileId,
    };
    try {
      const data = await Api.post("/listing/booking/create", payload);
      if (data) {
        await getPaymentTableDetails(
          bookingData.paymentTable._id,
          data.data._id
        );
        await updateAreaAndRate(data.data._id);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Booking created successfully",
          },
        });
        if (isNeedToRedirect) {
          history.push("/booking/edit/" + data.data._id);
        }
        onClose(data?.data?._id);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Something went wrong",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Something went wrong while creating booking",
        },
      });
    } finally {
      setCreateLoading(false);
    }
  };

  const getPaymentTableDetails = async (paymentTableId, bookingId) => {
    const paymentDetailsPayload = {
      tableId: paymentTableId,
      bookingId: bookingId,
    };

    await Api.post(
      "listing/booking/payment/table/detail/get-or-create",
      paymentDetailsPayload
    );
  };

  const updateAreaAndRate = async (bookingId) => {
    const rsf = bookingData?.rsf;
    const area = bookingData?.area;
    const saleableArea = bookingData?.saleableArea;

    const data = await Api.post(
      "/listing/booking/payment/update-area-and-rate",
      {
        bookingId,
        rate: rsf,
        area: area,
        saleableArea,
      }
    );

    if (data.booking) {
      const updatedBooking = data.booking;
      const { paymentDetails: details } = updatedBooking;
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to update area and rate",
        },
      });
    }
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              {showProjects && (
                <FormBox label={"Project"}>
                  <ProjectPicker
                    fullWidth={true}
                    size="sm"
                    selectedOrg={
                      showProjects ? bookingData?.organization?._id : false
                    }
                    project={bookingData.project}
                    setProject={(project) =>
                      handleUpdateBookingData({ project: project })
                    }
                  />
                </FormBox>
              )}
              <div style={{ width: "100%" }}>
                <ProjectBlockAndUnitPickerDropdown
                  projectId={bookingData?.project}
                  hideLabel={true}
                  fullWidth={true}
                  selectedProjectBlock={bookingData?.projectBlock}
                  setSelectedProjectBlock={(data) => {
                    handleUpdateBookingData({ projectBlock: data });
                  }}
                  selectedProjectUnit={bookingData?.unit}
                  setSelectedProjectUnit={(data) => {
                    handleUpdateBookingData({ unit: data });
                  }}
                  isRentalBlocks={isRentalBlocks}
                />
              </div>
            </Box>
          </Box>
        );
      case 1:
        return (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                label="Total Area (sqft)"
                type="number"
                size="small"
                value={
                  bookingData?.area !== ""
                    ? bookingData.area
                    : bookingData.unit?.area
                }
                onChange={(e) =>
                  handleUpdateBookingData({ area: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Rate per Sq Ft"
                type="number"
                size="small"
                value={
                  bookingData?.rsf !== ""
                    ? bookingData.rsf
                    : bookingData.projectBlock?.baseRatePerSqft
                }
                onChange={(e) =>
                  handleUpdateBookingData({ rsf: e.target.value })
                }
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                label="Saleable Area (sqft)"
                type="number"
                size="small"
                value={
                  bookingData?.saleableArea !== ""
                    ? bookingData.saleableArea
                    : bookingData.unit?.saleableArea
                }
                onChange={(e) =>
                  handleUpdateBookingData({
                    saleableArea: e.target.value,
                  })
                }
                fullWidth
              />
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <FormControl style={{ width: "48%" }} size="small">
              <InputLabel>Payment Table</InputLabel>
              <Select
                label="Payment Table"
                value={bookingData?.paymentTable?._id}
                onChange={(e) => {
                  const paymentTable = paymentTables?.find(
                    (item) => item?._id === e.target.value
                  );
                  handleUpdateBookingData({ paymentTable: paymentTable });
                }}
              >
                {paymentTables.map((item) => {
                  return (
                    <MenuItem key={item} value={item._id}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl style={{ width: "48%" }} size="small">
              <InputLabel>Payment Schedule</InputLabel>
              <Select
                label="Payment Schedule"
                value={bookingData.paymentSchedule}
                onChange={(e) =>
                  handleUpdateBookingData({ paymentSchedule: e.target.value })
                }
              >
                {paymentSchedule?.map((item) => {
                  return (
                    <MenuItem key={item._id} value={item._id}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
        );
      case 3:
        return (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
                flexWrap: "wrap",
              }}
            >
              <FormControl>
                <DatePicker
                  label="Booking Date"
                  value={bookingData.bookingDate}
                  onChange={(date) => {
                    handleUpdateBookingData({ bookingDate: date });
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                  style={{ height: "40px" }}
                  size="small"
                />
              </FormControl>
              <FormControl style={{ width: "48%" }} size="small">
                <InputLabel>Booking Status</InputLabel>
                <Select
                  label="Booking Status"
                  value={bookingData.bookingStatus}
                  onChange={(e) =>
                    handleUpdateBookingData({ bookingStatus: e.target.value })
                  }
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value="Active">Active</MenuItem>
                  <MenuItem value="Inactive">Inactive</MenuItem>
                </Select>
              </FormControl>

              <FormControl style={{ width: "48%" }} size="small">
                <InputLabel>Loan Sanctioned/Unsanctioned</InputLabel>
                <Select
                  label="Loan Sanctioned/Unsanctioned"
                  value={bookingData.sanctioned}
                  onChange={(e) =>
                    handleUpdateBookingData({ sanctioned: e.target.value })
                  }
                  fullWidth={true}
                >
                  <MenuItem value={true}>Sanctioned</MenuItem>
                  <MenuItem value={false}>Unsanctioned</MenuItem>
                </Select>
              </FormControl>

              <FormControl style={{ width: "48%" }} size="small">
                <InputLabel>Booking Type</InputLabel>
                <Select
                  value={bookingData.bookingType || ""}
                  label="Booking Type"
                  onChange={(e) =>
                    handleUpdateBookingData({ bookingType: e.target.value })
                  }
                  fullWidth
                >
                  <MenuItem
                    value={SALE_TYPE.Construction_Linked_Unit_Sale.value}
                  >
                    {SALE_TYPE.Construction_Linked_Unit_Sale.label}
                  </MenuItem>
                  <MenuItem value={SALE_TYPE.Unit_Sale.value}>
                    {SALE_TYPE.Unit_Sale.label}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </LocalizationProvider>
        );
      default:
        return "Unknown step";
    }
  };

  const getSchedules = async () => {
    if (!bookingData?.paymentTable?._id) {
      return;
    }

    const { data } = await Api.post("/listing/booking/schedule/get", {
      libraryId: libraryId,
      tableId: bookingData?.paymentTable?._id,
    });
    if (data) {
      const filteredPaymentDetails = data.filter((item) => item?.title);
      setPaymentSchedule(filteredPaymentDetails);
    } else
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
  };

  useEffect(() => {
    if (bookingData?.paymentTable && open) {
      getSchedules();
    }
  }, [bookingData?.paymentTable]);

  const getPaymentTables = async () => {
    const data = await Api.post("/listing/booking/payment/table/get", {
      libraryId: libraryId,
    });

    if (data) {
      const filteredPaymentTables = data.data.filter((item) => item.title);
      setPaymentTables(filteredPaymentTables);
    }
  };

  useEffect(() => {
    getPaymentTables();
  }, [open, libraryId]);

  return (
    <NormalDialog
      openDialog={open}
      handleCloseShare={() => {
        setOpen(false);
      }}
      pageTitle={"Create New Booking"}
      content={
        <div className={classes.mainCreateCont}>
          <div className={classes.bodyCont}>
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 3 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Divider
              sx={{
                mb: 3,
              }}
            />
            {loading ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              renderStepContent(activeStep)
            )}
          </div>
          <div className={classes.bottomSty}>
            <Button
              disabled={createLoading ? true : activeStep === 0}
              onClick={handleBack}
              variant="outlined"
              style={{ marginRight: "15px" }}
            >
              Back
            </Button>
            {activeStep === steps.length - 1 ? (
              <Button
                onClick={handleSubmit}
                disabled={createLoading}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            ) : (
              <Button
                onClick={handleNext}
                disabled={createLoading}
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            )}
          </div>
        </div>
      }
    />
  );
};

export default CreateBookingModel;
