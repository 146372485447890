import {
  Box,
  MenuItem,
  Typography,
  Select,
  IconButton,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Radio,
  RadioGroup,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Button from "../../styled/generic/Button";
import {
  Add,
  DeleteOutline,
  EditOutlined,
  MoreVert,
} from "@mui/icons-material";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import FormBox from "../../styled/generic/FormBox";
import TextField from "../../styled/generic/TextField";
// import  from "../../styled/generic/Select";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import Api from "../../../helpers/Api";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import TableContainer from "../../styled/generic/TableContainer";
import BoxSpaceBetween from "../../styled/generic/BoxSpaceBetween";
import CalculatorBox from "../../../helpers/CalculatorBox";
import { DataGrid } from "@mui/x-data-grid";
import { changePaymentTableComponentOrder } from "./api";
import HorizBox from "../../styled/generic/HorizBox";
import PopupMenuList from "../../styled/generic/PopupMenuList";

const PaymentComponents = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { profileId } = useParams();

  const [openComponentsDrawer, setOpenComponentsDrawer] = useState(false);
  const [componentName, setComponentName] = useState("");
  const [componentType, setComponentType] = useState("Variable");
  const [componentValue, setComponentValue] = useState("");
  const [milestoneCalculation, setMilestoneCalculation] = useState(true);
  const [expression, setExpression] = useState("");
  const [tableId, setTableId] = useState("");
  const [draggedIndex, setDraggedIndex] = useState(0);

  const [components, setComponents] = useState([]);
  const [filteredComponents, setFilteredComponents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [componentId, setComponentId] = useState("");
  const [hideInTable, setHideInTable] = useState(false);
  const [loadingCalculation, setLoadingCalculation] = useState({});

  // Get query Id named tableId
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tableId = searchParams.get("tableId");
    setTableId(tableId);
  }, [location.search]);

  const saveComponent = async () => {
    console.log("save component called", edit, " edit", {
      title: componentName,
      type: componentType,
      value: componentValue ?? "",
      expression: expression ?? "",
      profile: profileId,
      table: tableId,
    });
    if (edit) {
      setLoading(true);
      const data = await Api.post(
        `/listing/booking/payment/component/${componentId}`,
        {
          title: componentName,
          type: componentType,
          value: componentValue ?? "",
          expression: expression ?? "",
          hideInTable: hideInTable,
          milestoneCalculation: milestoneCalculation,
        }
      );
      if (data) {
        setLoading(false);
        setOpenComponentsDrawer(false);
        setComponentName("");
        setComponentType("Variable");
        setComponentValue("");
        setExpression("");
        setEdit(false);
        setComponents(
          components.map((item) => (item._id === data._id ? data : item))
        );
        setFilteredComponents(
          components.map((item) => (item._id === data._id ? data : item))
        );
        setComponentId("");
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Payment component updated successfully",
          },
        });
      } else {
        setLoading(false);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred",
          },
        });
      }
    } else {
      try {
        setLoading(true);
        const { data } = await Api.post(
          "/listing/booking/payment/component/create",
          {
            title: componentName,
            type: componentType,
            value: componentValue ?? "",
            expression: expression ?? "",
            profile: profileId,
            table: tableId,
            milestoneCalculation: milestoneCalculation,
          }
        );
        if (data) {
          setOpenComponentsDrawer(false);
          setComponentName("");
          setComponentType("Variable");
          setComponentValue("");
          setFilteredComponents((prev) => [...prev, data]);
          setComponents([...components, data]);
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: true,
              message: "Payment component created successfully",
            },
          });
        }
      } catch (error) {
        console.error(error);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred",
          },
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const getComponents = async () => {
    try {
      const { data } = await Api.post(
        "/listing/booking/payment/component/get",
        {
          tableId: tableId,
        }
      );
      if (data) {
        const filteredData = data?.filter(
          (item) => item?.hideInTable === false
        );
        setFilteredComponents(filteredData);
        setComponents(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (tableId) {
      getComponents();
    }
  }, [tableId]);

  const handleDragStart = (index) => {
    setDraggedIndex(index);
  };

  const handleDragOver = (index) => {
    if (draggedIndex !== null && draggedIndex !== index) {
      const newData = [...components];
      const draggedItem = newData[draggedIndex];
      newData.splice(draggedIndex, 1);
      newData.splice(index, 0, draggedItem);
      setComponents(newData);
      const filteredData = filteredComponents.filter(
        (item) => item.hideInTable === true
      );
      setFilteredComponents([...filteredData, ...newData]);
      setDraggedIndex(index);
    }
  };

  const handleChecked = async (
    component,
    aggrementBased,
    milestoneCalculation,
    index
  ) => {
    if (!component) {
      return;
    }

    // Set loading state for this specific component
    setLoadingCalculation((prev) => ({
      ...prev,
      [component._id]: true,
    }));

    try {
      const data = await Api.post(
        `/listing/booking/payment/component/${component._id}`,
        {
          milestoneCalculation: milestoneCalculation,
          aggrementBased: aggrementBased,
        }
      );

      if (data) {
        setComponents(
          components.map((item, i) =>
            i === index
              ? {
                ...item,
                milestoneCalculation: milestoneCalculation,
                aggrementBased: aggrementBased,
              }
              : item
          )
        );
        setFilteredComponents(
          filteredComponents.map((item, i) =>
            i === index
              ? {
                ...item,
                milestoneCalculation: milestoneCalculation,
                aggrementBased: aggrementBased,
              }
              : item
          )
        );
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to update calculation type",
        },
      });
    } finally {
      // Clear loading state for this component
      setLoadingCalculation((prev) => ({
        ...prev,
        [component._id]: false,
      }));
    }
  };

  const move = async (index, tableId, componentId) => {
    let { data } = await changePaymentTableComponentOrder({
      componentId: componentId,
      newOrder: index,
      tableId: tableId,
    });
  };
  const handleDragEnd = () => {
    move(draggedIndex, components[0]?.table, components[draggedIndex]?._id);
    console.log("Moving: ", components[draggedIndex]);
    setDraggedIndex(null);
  };

  function convertExpression(expression) {
    function getTitleById(id) {
      const obj = components.find((item) => item._id === id);
      return obj ? obj.title : null;
    }
    const idRegex = /[0-9a-f]{24}/g;
    const convertedExpression = expression?.replace(idRegex, (match) => {
      const title = getTitleById(match);
      return title !== null ? title : match;
    });

    return convertedExpression;
  }

  console.log(expression)

  return (
    <Box>
      <BoxSpaceBetween sx={{ my: "1.5rem" }}>
        <Typography variant="h6">Components</Typography>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<Add />}
          onClick={() => {
            setOpenComponentsDrawer(true);
            setEdit(false);
            setComponentName("");
            setComponentType("Variable");
            setComponentValue("");
            setExpression("");
            setComponentId(null);
          }}
        >
          New Component
        </Button>
      </BoxSpaceBetween>

      <TableContainer
        columns={[
          "Component Title",
          "Component Type",
          "Component Value / Expression",
          "Milestone Calculation",
          "Aggrement based",
        ]}
        data={filteredComponents}
        loading={loading}
      >
        {filteredComponents.map((component, index) => {
          const convertedExpression = convertExpression(component?.expression);
          if (component.hideInTable) return null;
          return (
            <tr key={index}>
              <td>{component?.title || "Untitled"}</td>
              <td>{component?.type || "-"}</td>
              <td>{convertedExpression || component?.value || ""}</td>
              <td align="center">
                <HorizBox>
                  <Tooltip
                    id={`tooltip-${index * 4}`}
                    title="Milestone Calculation"
                    enterDelay={1500}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={component?.milestoneCalculation}
                          onChange={(e) => {
                            handleChecked(component, false, true, index);
                          }}
                          disabled={loadingCalculation[component._id]}
                        />
                      }
                      label=""
                    />
                  </Tooltip>
                  {loadingCalculation[component._id] && (
                    <CircularProgress size={20} sx={{ ml: 1 }} />
                  )}
                </HorizBox>
              </td>
              <td align="center">
                <HorizBox>
                  <Tooltip
                    id={`tooltip-${index}`}
                    title="Aggrement Based"
                    enterDelay={1500}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={component?.aggrementBased}
                          onChange={(e) => {
                            handleChecked(component, true, false, index);
                          }}
                          disabled={loadingCalculation[component._id]}
                        />
                      }
                      label=""
                    />
                  </Tooltip>
                </HorizBox>
              </td>
              <td
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <HorizBox>
                  <IconButton
                    onClick={() => {
                      setOpenComponentsDrawer(true);
                      setComponentName(component?.title);
                      setComponentType(component?.type);
                      setExpression(component?.expression);
                      setComponentValue(component?.value);
                      setMilestoneCalculation(
                        component?.milestoneCalculation ? true : false
                      );
                      setEdit(true);
                      setComponentId(component?._id);
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                  <IconButton onClick={() => alert("not implemented")}>
                    <DeleteOutline />
                  </IconButton>
                </HorizBox>
              </td>
            </tr>
          );
        })}
      </TableContainer>

      <DrawerContainer
        title={
          componentName ? "Create Payment Component" : "Edit Payment Component"
        }
        open={openComponentsDrawer}
        setOpen={setOpenComponentsDrawer}
        width="10%"
      >
        <FormBox label="Component Name">
          <TextField
            value={componentName}
            onChange={(e) => setComponentName(e.target.value)}
            fullWidth={true}
            placeholder="Enter"
          />
        </FormBox>
        <FormBox label="Calculation Type">
          <RadioGroup
            value={
              milestoneCalculation ? "Milestone Calculation" : "Aggrement Based"
            }
            onChange={(e) =>
              setMilestoneCalculation(
                e.target.value === "Milestone Calculation" ? true : false
              )
            }
          >
            <FormControlLabel
              control={<Radio value="Milestone Calculation" />}
              label="Milestone Calculation"
            />
            <FormControlLabel
              control={<Radio value="Aggrement Based" />}
              label="Aggrement Based"
            />
          </RadioGroup>
        </FormBox>
        <FormBox label="Component Type">
          <Select
            fullWidth={true}
            value={componentType}
            onChange={(e) => setComponentType(e.target.value)}
          >
            <MenuItem value="Variable">Variable</MenuItem>
            <MenuItem value="Fixed">Fixed</MenuItem>
            <MenuItem value="Expression">Expression</MenuItem>
          </Select>
        </FormBox>
        {componentType === "Fixed" && (
          <FormBox label="Component Value">
            <TextField
              fullWidth={true}
              value={componentValue}
              onChange={(e) => {
                setComponentValue(e.target.value);
              }}
              placeholder="Enter"
            />
          </FormBox>
        )}

        {componentType === "Expression" && (
          <FormBox label="Component Expression">
            <CalculatorBox
              expression={expression}
              setExpression={setExpression}
              components={components.filter((comp) => comp._id !== componentId)}
              componentDisplayField={"title"}
            />
          </FormBox>
        )}

        <DuoButtonGroup
          primaryButtonText="Save"
          primaryButtonListener={() => saveComponent()}
          secondaryButtonText="Cancel"
          secondaryButtonListener={() => setOpenComponentsDrawer(false)}
          loadingPrimary={loading}
        />
      </DrawerContainer>
    </Box>
  );
};

export default PaymentComponents;
