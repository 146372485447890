import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useShareAndAssign from "../ResourceAccessControl/shareAndAssign.js";
import { updateDeleteFlagForSingleFiles } from "../propertyManagement/apiCall";
import { getAllFiles } from "../styled/CommonComponents/api.call";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Radio,
  RadioGroup,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";
import AllFileViewerFeed from "../styled/CommonComponents/AllFiles.Viewer.Feed";
import CustomBtn from "../styled/CommonComponents/CustomBtn";
import IssueSvg from "../../Assets/issue.svg";
import CreateBtn from "../styled/actionBtns/create.btn";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";
import Api from "../../helpers/Api";
import Paper from "@material-ui/core/Paper";
import DescriptionInput from "../styled/description.input";
import TaskList from "../task/smart.task.list.js";
import ProfileAppbar from "../profile/profile.appbar.js";

import StatusPicker from "./status.picker.js";
import useShared from "../share/useShared.js";
import ShareIconBtn from "../share/share.icon.btn.js";
import SharedList from "../share/sharedList.js";
import useDatePickerMap from "../styled/datepicker/index.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import DateFnsUtils from "@date-io/date-fns";
import { MdDelete } from "react-icons/md";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import useGetTemplates from "./useGetTemplates";
import FileUploadButton from "../file/Uploader/FileUploadButton";
import FilesViewer from "../file/Viewer/FilesViewer";
import { Alert } from "@material-ui/lab";
import { updateDeleteFlagForManyFiles } from "../propertyManagement/apiCall";
// import Choose from "../select/choose";
// import SubjectIssue from "./subject.issue";
import docImage from "../../Assets/FileIcon/docs.png";
import PaperBtn from "../styled/actionBtns/paper.btn";
import RestoreIcon from "@material-ui/icons/Restore";
import Logs from "../logs";
import ReactHtmlParser from "react-html-parser";
import Response from "../responses/response";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import { green, grey, orange, red, yellow } from "@material-ui/core/colors";
import _ from "lodash";
import arrayToReducer from "../../helpers/arrayToReducer";
import Choose from "../select/choose";
import { Autocomplete } from "@mui/material";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import AddIcon from "@material-ui/icons/Add";
// PlaylistAddCheck as PlayListIcon,
import PlayListIcon from "@material-ui/icons/PlaylistAddCheck";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import BillListTable from "../team/procurements/BillListTable";
import MyAutocomplete from "../styled/CommonComponents/MyAutoComplete";
import StandardContainer from "../styled/generic/StandardContainer.jsx";
import StandardAppContainerRounded from "../styled/generic/StandardAppContainerRounded.jsx";
import HorizBox from "../styled/generic/HorizBox.jsx";
import FormBox from "../styled/generic/FormBox.jsx";
import ProjectPicker from "../styled/generic/ProjectPicker.jsx";
import OrgPicker from "../styled/generic/OrgPicker.jsx";
import SpaceBetween from "../styled/generic/SpaceBetween.jsx";
import CategoryAutocomplete from "../OfferingsAndProducts/CategoryAutocomplete";
import { Add } from "@material-ui/icons";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const ListDict = {
  // parentModel name to display Name
  Organization: "Organization",
  Project: "Project",
  Doc: "Documents",
  Issue: "Issues",
  RentalUnit: "Rental Units",
  FinanceRelation: "Contacts",
  Transaction: "Transactions",
};

const getSubjectText = (
  setParent,
  parentModel,
  parentObject,
  handleClickOpen
) => {
  const sendText = () => {
    switch (parentModel) {
      case "Issue":
        return (
          <ListItemText
            primary={parentObject?.title || " "}
            secondary={
              <>
                {`${parentObject?.template?.title || "No Template"} `}
                <br />
                Ticket
              </>
            }
          />
        );
      case "Doc":
        return (
          <ListItemText
            primary={parentObject?.title || " "}
            secondary={ListDict[parentModel]}
          />
        );
      case "RentalUnit":
        return (
          <ListItemText
            primary={parentObject?.name || " "}
            secondary={ListDict[parentModel]}
          />
        );
      default:
        return (
          <ListItemText
            primary={parentObject?.displayName || " "}
            secondary={ListDict[parentModel]}
          />
        );
    }
  };
  let html = (
    <ListItem
      style={{
        width: "max-content",
        alignItems: "center",
        border: "1px solid #c7c7c7",
        borderRadius: "12px",
      }}
    >
      <ListItemAvatar>
        <Avatar
          src={
            parentModel == "Issue"
              ? IssueSvg
              : parentModel == "Doc"
              ? docImage
              : parentObject?.displayPicture?.thumbUrl
          }
          style={{
            height: "1.6rem",
            width: "1.6rem",
            borderRadius: "0.8rem",
          }}
        />
      </ListItemAvatar>
      {sendText()}
      <IconButton onClick={handleClickOpen}>
        <EditIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          setParent(null);
        }}
      >
        <CloseIcon />
      </IconButton>
    </ListItem>
  );
  return html;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    width: "100%",
    height: "100vh",
    marginTop: "3rem",
    justifyContent: "center",
    alignItems: "center",
  },

  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  col: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  appbar: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-start",
    top: 0,
    position: "fixed",
    borderColor: "grey",
    backgroundColor: "white",
    height: "4.5rem",
    width: "100%",
  },

  rowDiv: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "1rem 0",
  },
  text: {
    fontSize: 20,
    fontWeight: "bold",
    margin: "0rem 1rem",
    fontColor: "#111111",
  },
  attachIconFont: {
    fontSize: "25px",
    transform: "rotate(-20deg)",
    marginRight: "5px",
  },
  iconWithTextStyle: {
    border: `2px solid ${theme.palette.primary.main}`,
    width: "150px",
  },
  categoryAndLocationTag: {
    display: "flex",
    justifyContent: "space-between",
    gap: theme.spacing(2.5),
    margin: theme.spacing(2.5, 0),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: theme.spacing(2),
    },
  },
  categoryOrLocationCont: {
    flex: 1,
    minHeight: "300px",
    padding: theme.spacing(1.5),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  optionsCont: {
    padding: "5px",
  },
  singleOption: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    cursor: "pointer",
    // "& img": {
    //   width: "20px",
    //   height: "auto",
    //   marginRight: "10px",
    // },
    // "& p": {
    //   fontSize: "15px",
    //   fontWeight: "500",
    // },
    // "&:hover": {
    //   backgroundColor: "#e2e2e2",
    // },
  },
  uploadBtn: {
    marginTop: theme.spacing(1.5),
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontSize: "2rem", // 32px
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.75rem", // 28px
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.5rem", // 24px
    },
  },
  subtitle: {
    fontSize: "1.25rem", // 20px
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.125rem", // 18px
    },
  },
  bodyText: {
    fontSize: "1rem", // 16px
    lineHeight: 1.6,
    color: theme.palette.text.primary,
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.9375rem", // 15px
    },
  },
  label: {
    fontSize: "0.75rem", // 12px
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.688rem", // 11px
    },
  },
  textField: {
    "& .MuiOutlinedInput-root": {
      borderRadius: theme.shape.borderRadius,
      transition: "all 0.2s",
      minHeight: "40px",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "&.Mui-focused": {
        boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px 14px",
      fontSize: "0.875rem",
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 12px) scale(1)",
      fontSize: "0.875rem",
    },
    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
  },
  helperText: {
    fontSize: "0.625rem", // 10px
    marginTop: theme.spacing(0.25),
    color: theme.palette.text.secondary,
  },
  formContainer: {
    maxWidth: "800px",
    width: "100%",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.5),
    },
  },

  sectionSpacing: {
    marginBottom: theme.spacing(4),
    "&:last-child": {
      marginBottom: 0,
    },
  },

  fieldSpacing: {
    marginBottom: theme.spacing(2.5),
    "&:last-child": {
      marginBottom: theme.spacing(1.5),
    },
  },

  inputGroup: {
    display: "flex",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      gap: theme.spacing(1.5),
    },
  },

  formField: {
    "& .MuiInputBase-root": {
      padding: theme.spacing(1),
    },
    "& .MuiInputLabel-root": {
      marginBottom: theme.spacing(0.5),
    },
    "& .MuiFormHelperText-root": {
      marginTop: theme.spacing(0.25),
    },
  },

  fileUploadSection: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2.5),
    borderRadius: theme.shape.borderRadius,
    border: `1px dashed ${theme.palette.divider}`,
    "& .MuiButton-contained": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },

  actionButtons: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1.5),
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },

  sectionTitle: {
    fontSize: "1.25rem",
    fontWeight: 600,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
    borderBottom: `2px solid ${theme.palette.primary.light}`,
  },
  formActions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(2),
    marginTop: theme.spacing(4),
  },
  datePickerField: {
    width: "200px",
    "& .MuiInputBase-root": {
      minHeight: "40px",
      borderRadius: theme.shape.borderRadius,
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
      "& .MuiInputBase-input": {
        padding: "8px 14px",
        fontSize: "0.875rem",
      },
    },
  },
  priorityGroup: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1.5),
    "& .MuiFormControlLabel-root": {
      margin: 0,
      minWidth: "100px",
      padding: theme.spacing(1.5),
      borderRadius: theme.shape.borderRadius,
      border: `1px solid ${theme.palette.divider}`,
      transition: "all 0.2s ease",
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
        transform: "translateY(-2px)",
      },
      "&.selected-1": {
        backgroundColor: "#f5f5f5",
        borderColor: "grey",
      },
      "&.selected-2": {
        backgroundColor: "#e8f5e9",
        borderColor: "green",
      },
      "&.selected-3": {
        backgroundColor: "#fff3e0",
        borderColor: "#f0ad4e",
      },
      "&.selected-4": {
        backgroundColor: "#ffe0b2",
        borderColor: "orange",
      },
      "&.selected-5": {
        backgroundColor: "#ffebee",
        borderColor: "red",
      },
    },
    "& .MuiFormControlLabel-label": {
      fontSize: "0.875rem",
      fontWeight: 500,
      textAlign: "center",
      width: "100%",
    },
  },
  priorityLabel: {
    fontSize: "0.75rem", // 12px
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
  },
  descriptionField: {
    // Add any specific styles for the description field if needed
  },
  filePreview: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  uploadButton: {
    marginTop: theme.spacing(2),
  },
  formGroup: {
    marginBottom: theme.spacing(4),
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(2.5),
    },
  },

  labelSpacing: {
    marginBottom: theme.spacing(1),
    "& + .MuiInputBase-root": {
      marginTop: theme.spacing(0.5),
    },
  },

  helperTextSpacing: {
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1.5),
  },

  formLabel: {
    fontSize: "0.75rem", // 12px
    fontWeight: 500,
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(0.5),
    display: "block",
  },
  formSection: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius * 2,
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  },
  uploadSection: {
    border: `2px dashed ${theme.palette.primary.light}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.action.hover,
    transition: "all 0.2s",
    "&:hover": {
      borderColor: theme.palette.primary.main,
      backgroundColor: theme.palette.action.selected,
    },
  },
  actionButton: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 3),
    textTransform: "none",
    fontWeight: 600,
  },
  selectField: {
    "& .MuiSelect-select": {
      minHeight: "40px",
      padding: "8px 14px",
      fontSize: "0.875rem",
    },
    "& .MuiSelect-icon": {
      top: "calc(50% - 12px)",
    },
  },
  datePickerGroup: {
    display: "flex",
    gap: theme.spacing(2),
    flexWrap: "wrap",
    "& > *": {
      flex: "0 0 auto",
    },
  },
  labelField: {
    width: "600px",
  },
}));

function EditProfileIssue(props) {
  const { placeholder, noFileUploadBtn, titlePlaceholder } = props;
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const { issue, setIssue } = props;
  console.log(" issueineditprofile ", issue);
  const { row, col } = classes;
  const dispatch = useDispatch();
  const [labels, setLabels] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const profileId = issue?.profile?._id;
  const { user, userProfile } = useSelector((state) => state.auth);
  const matches = useMediaQuery("(max-width:700px)");
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));
  const userId = issue?.user._id;
  const userProfileId = userId;
  const [tags, setTags] = useState([]);
  const oldShared = issue?.shared?.map((profile) => profile?._id) || [];
  const [shared, setShared] = useState(oldShared);
  const oldassigned = issue?.assigned?.map((profile) => profile?._id) || [];
  const [assigned, setAssigned] = useState(oldassigned);
  const { createdFileIds } = useSelector((state) => state.file);

  const [files, setFiles] = useState([]);
  const [isPrivate, setPrivate] = useState(issue?.isPrivate);
  const sharedProps = useShared({
    initShared: shared,
    initAssigned: assigned,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const handleClickOpen = () => {
    setOpenDialog(true);
  };
  const handleClose = () => {
    setOpenDialog(false);
  };
  const [deletedImgIds, setDeletedImgIds] = useState([]);
  const [template, setTemplate] = useState("");
  const [status, setStatus] = useState("");
  const [parent, setParent] = useState(null);
  const [parentModel, setParentModel] = useState("Organization");
  const [parentObject, setParentObject] = useState({});
  const [issueCreated, setIssueCreated] = useState(false);
  const [publish, setPublish] = useState(false);
  const [taskIds, setTaskIds] = useState([]);
  const file = useSelector((state) => state.file);
  // const { createdFileIds } = file;
  const [priority, setPriority] = useState("1");
  const [open, setOpen] = useState(false);
  const [openChooseProject, setOpenChooseProject] = useState(false);
  const [openChooseOrganization, setOpenChooseOrganization] = useState(false);
  const [chosenProject, setChosenProject] = useState(null);
  const [chosenOrganization, setChosenOrganization] = useState("");
  useEffect(() => {
    if (createdFileIds && createdFileIds.length > 0) {
      getAllFiles({ files: createdFileIds })
        .then((data) => {
          setFiles(data || []);
        })
        .catch((err) => {
          console.log(err);
          setFiles([]); // Handle error by resetting files to an empty array
        });
    }
  }, [createdFileIds]);
  const removeFun = async (id) => {
    try {
      // API call
      const response = await updateDeleteFlagForSingleFiles({ fileId: id });
      console.log("API Response:", response);

      // Update frontend state
      let allfilesId = createdFileIds.filter((_id) => _id !== id);
      let allfiles = files.filter((obj) => obj._id !== id);

      console.log("Filtered files:", allfiles);

      setFiles(allfiles);
      console.log("Updated files state:", allfiles);

      dispatch({
        type: "AddFile",
        payload: {
          createdFileIds: [...allfilesId],
        },
      });
      console.log("Updated createdFileIds:", allfilesId);
    } catch (err) {
      console.error("Error deleting file:", err);
    }
  };
  var dateNow = new Date();
  //ancestors
  let dep = [];
  if (issue?.ancestors?.length > 0) {
    // dep = issue?.ancestors.map((ancestor) => {
    //   return {
    //     issueTitle: ancestor?.title,
    //     issueId: ancestor?._id,
    //   };
    // });
    dep = issue?.ancestors.map((ancestor) => {
      return ancestor?._id;
    });
  }
  const [dependency, setDependency] = useState(dep || []);
  const [dependencyList, setDependencyList] = useState([]);
  //all issues and corresponding dictionary
  const [issueIds, setIssueIds] = useState([]);
  const [issueDict, setIssueDict] = useState({});
  const [issueTitleMap, setIssueTitleMap] = useState({});
  const shareBtn = (
    <Button variant="outlined" color="primary">
      Manage Access
    </Button>
  );

  const {
    sharedProfilesRole,
    ShareButton,
    ShareDrawer,
    SharedView,
    assignedProfiles,
    AssignButton,
    AssigneDrawer,
    AssignView,
  } = useShareAndAssign({
    initShared: null,
    initAssigned: null,
    resourceId: issue?._id,
    resourceName: "Issue",
    customShareBtn: shareBtn,
    customAssignBtn: null,
  });
  const { dateTimePickView, dateArr } = useDatePickerMap({
    isOrder: true,
    dateArr: [
      {
        label: "Start Time",
        value: issue?.startDate,
      },
      {
        label: "End Time",
        value: issue?.finishDate,
      },
    ],
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleDependencyChange = (event) => {
    setDependency(event.target.value);
  };

  function getStyles(issue, dependency, theme) {
    // let newArr = dependency.map((cur) => cur?.issueId);
    // let issueId = issue?.issueId;
    // return {
    //   fontWeight:
    //     newArr.indexOf(issueId) === -1
    //       ? theme.typography.fontWeightRegular
    //       : theme.typography.fontWeightMedium,
    // };

    return {
      fontWeight:
        dependency.indexOf(issue) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  useEffect(() => {
    setTitle(issue?.title);
    setDescription(issue?.description);
    setTags(issue?.tags);
    setChosenOrganization(issue?.organization);
    setChosenProject(issue?.project);
    setLabels(issue?.labels || []);
    setStartDate(issue?.startDate);
    setDueDate(issue?.due_date);
    let statusObj = issue?.template?.pipeline.filter(
      (obj) => obj?._id == issue?.status
    )[0];
    setTemplate(issue?.template);
    setStatus(statusObj);
    setPriority((issue?.priority || 1).toString());
    // if (issue?.parent) {
    //   if (["Organization", "Project"].includes(issue?.parentModelName)) {
    //     setParent([issue?.parent?.profile]);
    //   } else {
    //     setParent([issue?.parent?._id]);
    //   }
    //   setParentObject(issue?.parent);
    //   setParentModel(issue?.parentModelName);
    // }

    setChosenProject(issue?.project);
    setChosenOrganization(issue?.organization);

    let imgs = issue?.files;
    let imgIds = [];

    imgs?.length > 0 &&
      imgs.map((img) => {
        imgIds.push(img?._id);
      });

    dispatch({
      type: "AddFile",
      payload: {
        createdFileIds: imgIds,
      },
    });
  }, [issue]);

  // const {
  //   templateIds,
  //   setTemplateIds,
  //   templateDictionary,
  //   setTemplateDictionary,
  // } = useGetTemplates(issue?.profile?._id);

  // var templates = [];
  // templateIds.map((tempId) => {
  //   var temp = templateDictionary[tempId];
  //   if (temp?._id) {
  //     templates.push(temp);
  //     templates = templates.filter(
  //       (templates) =>
  //         templates?.type === "Issue" && templates?.title.length > 0
  //     );
  //   } else {
  //   }
  // });

  const removeSingleImgFromReducerAndDelete = async (selectedId) => {
    const filteredFileIds = createdFileIds.filter((id) => id != selectedId);
    let deletedImgs = deletedImgIds;
    deletedImgs.push(selectedId);
    setDeletedImgIds(deletedImgs);

    dispatch({
      type: "AddFile",
      payload: {
        createdFileIds: [...filteredFileIds],
      },
    });
  };
  // const getParentId = () => {
  //   if (parent) {
  //     if (["Organization", "Project"].includes(parentModel)) {
  //       return parentObject?._id;
  //     } else {
  //       return parent[0] || null;
  //     }
  //   } else {
  //     return null;
  //   }
  // };

  // useEffect(() => {
  //   const updatedTags = tags.filter(
  //     (tag) =>
  //       tag.dataModel === "Booking" || tag.dataModel === "BuildingCodeLibrary"
  //   );
  //   if (chosenProject) {
  //     const projectTag = {
  //       data: chosenProject._id,
  //       dataModel: "Project",
  //       _id: tags.find((tag) => tag.dataModel === "Project")?._id,
  //     };
  //     updatedTags.push(projectTag);
  //   }
  //   if (chosenOrganization) {
  //     const orgTag = {
  //       data: chosenOrganization._id,
  //       dataModel: "Organization",
  //       _id: tags.find((tag) => tag.dataModel === "Organization")?._id,
  //     };
  //     updatedTags.push(orgTag);
  //   }
  //   if (
  //     updatedTags.length !== tags.length ||
  //     JSON.stringify(updatedTags) !== JSON.stringify(tags)
  //   ) {
  //     setTags(updatedTags);
  //   }
  // }, [chosenProject, chosenOrganization]);

  const updateIssueApi = async () => {
    // let parentId = getParentId();
    const newStartDate = new Date(startDate).toUTCString();
    const newDueDate = new Date(dueDate).toUTCString();

    const issueObject = {
      _id: issue?._id,
      title,
      description,
      checklist: taskIds,
      template: template?._id,
      closed: template?.finalStates
        ? template.finalStates.includes(status?._id)
        : false,
      status: status?._id,
      // parentModelName: parent ? parentModel : "",
      // parent: parentId,
      project: chosenProject,
      organization: chosenOrganization,
      // shared: sharedProps?.shared,
      // assigned: sharedProps?.assigned,
      due_date: newDueDate,
      startDate: newStartDate,
      // startDate: dateArr[0],
      finishDate: dateArr[1],
      files: createdFileIds,
      tags: tags,
      priority: parseInt(priority),
      ancestors: dependency,
      isPrivate,
      labels: labels.map((l) => l?._id) || [],
      // materials: materialList.map(({ id, quantity }) => ({
      //   budgetWorkItem: id,
      //   quantity: quantity,
      //   createdBy: profileId,
      //   issue: issue?._id,
      // })),
    };

    // const requiredFields = template?.required;
    // if (requiredFields.includes("Project")) {
    //   let ip = issueObject.project;
    //   if (!ip) {
    //     dispatch({
    //       type: "AddApiAlert",
    //       payload: {
    //         success: false,
    //         message: "Please choose a project",
    //       },
    //     });
    //     return;
    //   }
    // }

    // if (requiredFields.includes("Organization")) {
    //   let io = issueObject.organization;
    //   if (!io) {
    //     dispatch({
    //       type: "AddApiAlert",
    //       payload: {
    //         success: false,
    //         message: "Please choose an organization",
    //       },
    //     });
    //     return;
    //   }
    // }

    const res = await Api.post("issue/update", issueObject);
    if (!res) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred",
        },
      });
      return;
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Ticket updated successfully",
        },
      });
    }

    if (deletedImgIds?.length > 0) {
      await updateDeleteFlagForManyFiles({ fileIds: deletedImgIds })
        .then((data) => {
          setDeletedImgIds([]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    dispatch({ type: "FileUploadReset" });
    history.goBack();
  };

  const manageFormRes = async () => {
    setLoading(false);
    // let issueId = issue?._id;
    // const res = await Api.post("form/manageRes", {
    //   issueId,
    // });
    // const issueRes = res?.data;
    // if (issueRes?._id) {
    //   setIssue(issueRes);
    //   setLoading(false);
    //}
  };

  //returns the directed graph's adjacency list in form of a dictionary
  const getAdjacencyList = (ids, newDict) => {
    let adj = {};
    if (ids?.length > 0) {
      ids.map((issueId) => {
        let currIssue = newDict[issueId];
        let ancestors = currIssue?.ancestors || [];
        if (ancestors?.length > 0) {
          ancestors.map((ancestor) => {
            //directed edge from ancestor to currentNode
            if (adj.hasOwnProperty(ancestor?._id)) {
              adj[ancestor?._id].push(issueId);
            } else {
              adj[ancestor?._id] = [issueId];
            }
          });
        }
      });
    }
    return adj;
  };

  const getChildren = (issue, ids, newDict) => {
    //find all children of this issue with a bfs

    //get the dictionary containing directed edges corresponding to every issue
    const adjList = getAdjacencyList(ids, newDict);
    let q = [];
    //visited Set
    let explored = new Set();
    //push the id in queue
    q.push(issue?._id);

    // Mark the first node as explored.
    explored.add(issue?._id);

    // continue till q is empty
    while (q.length > 0) {
      let node = q.shift();
      let children = adjList[node] || [];
      children
        .filter((n) => !explored.has(n))
        .forEach((currNode) => {
          explored.add(currNode);
          q.push(currNode);
        });
    }

    //we get the issueIds that are direct or indirect children of our current issue
    //get the issues and return
    let ChildrenIssues = [];
    explored.forEach((n) => {
      ChildrenIssues.push(newDict[n]);
    });
    return ChildrenIssues;
  };

  // const getAllDependencies = async () => {
  //   let templateId = issue?.template?._id;
  //   const res = await Api.post("issue/getIssuesByTemplate", {
  //     templateId,
  //   });
  //   if (res?.data) {
  //     let issueList = res?.data || [];
  //     // res.data.issues.map((issue) => {
  //     //   return { issueTitle: issue?.title, issueId: issue?._id };
  //     // });
  //     const { newDict, idArr } = arrayToReducer(issueList);
  //     setIssueIds(idArr);
  //     setIssueDict(newDict);
  //     let issueChildren = getChildren(issue, idArr, newDict) || [];
  //     //children cannot be ancestors, to prevent cycle
  //     let allowedAncestors = _.difference(issueList, issueChildren) || [];
  //     // allowedAncestors = allowedAncestors.map((curr) => {
  //     //   return { issueTitle: curr?.title, issueId: curr?._id };
  //     // });
  //     let tempMap = {};
  //     allowedAncestors.map((curr) => {
  //       tempMap[curr?._id] = curr?.title;
  //     });
  //     setIssueTitleMap(tempMap);
  //     setDependencyList(allowedAncestors);
  //   }
  // };

  // useEffect(() => {
  //   if (issue?._id) {
  //     setLoading(true);
  //     //get the initial dependencies
  //     // if (issue?.ancestors?.length > 0) {
  //     //   let dep = [];
  //     //   issue?.ancestors.map((ancestor) => {
  //     //     dep.push({
  //     //       issueTitle: ancestor?.title,
  //     //       issueId: ancestor?._id,
  //     //     });
  //     //   });
  //     //   setDependency(dep);
  //     // }
  //     //get the dependency list
  //     // getAllDependencies();

  //     // This will be called in create function instead
  //     // manageFormRes();
  //   }
  // }, []);

  useEffect(() => {
    if (dep?.length > 0) {
      setDependency(dep);
    }
  }, [dep?.length]);

  //bill list
  const typeArr = ["Product", "Service", "Package"];
  const subTypeArr = [
    "Material",
    "Material + Labour",
    "Labour",
    "Services",
    "Subcontract",
  ];
  const tableColumn = ["Item Type", "Taxes", "Tax incl.", "Tax excl."];
  const [type, setType] = useState(typeArr[0]);
  const [subType, setSubType] = useState(subTypeArr[0]);
  const [subTypeText, setSubTypeText] = useState(subTypeArr[0]);

  useEffect(() => {
    switch (subType) {
      case "Material":
        setType(typeArr[0]);
        break;
      case "Material + Labour":
        setType(typeArr[1]);
        break;
      case "Labour":
        setType(typeArr[2]);
        break;
      case "Services":
        setType(typeArr[2]);
        break;
      case "Subcontract":
        setType(typeArr[2]);
        break;
    }
  }, [subType]);
  const [billList, setBillList] = useState(issue?.billList);
  const [hiddenCheckeds, setHiddenCheckeds] = useState(
    issue?.template.hiddenCheckeds
  );
  const [allTotalBillDetails, setTotalBillDetails] = useState(null);
  const [billListloading, setbillListLoading] = useState(false);
  const [showTableColumn, setTableColumn] = useState(tableColumn);

  const handleChangeTableColumn = async (event, values) => {
    const isChecked = event.target.checked;

    if (isChecked) {
      setTableColumn((prevSelected) => [...prevSelected, values]);
    } else {
      setTableColumn((prevSelected) =>
        prevSelected.filter((item) => item !== values)
      );
    }
  };

  //bill list

  const [hiddenHeaders, setHiddenHeaders] = useState(
    issue?.template.hiddenHeaders
  );
  const [tempMaterialList, setTempMaterialList] = useState([]);
  const [materialList, setMaterialList] = useState([]);
  const [materialSearchQuery, setMaterialSearchQuery] = useState();
  const [materialSearchResults, setMaterialSearchResults] = useState([]);

  const handleMaterialSearch = async (e) => {
    const query = e.target.value;
    if (query.length < 3) {
      return;
    }
    try {
      const results = await Api.post("/estimate/get", {
        searchStr: query,
        profileId: profileId,
      });
      setMaterialSearchResults(results.data);
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred" + err,
        },
      });
    }
  };

  const addNewMaterialItem = () => {
    setTempMaterialList([
      ...tempMaterialList,
      {
        id: new Date().toString(),
        title: "",
        quantity: "",
      },
    ]);
    setMaterialList([
      ...materialList,
      {
        id: new Date().toString(),
        title: "",
        quantity: "",
      },
    ]);
  };

  const deleteMaterialItem = (id) => {
    const tempItems = tempMaterialList.filter((item) => item.id !== id);
    setTempMaterialList(tempItems);
    const items = materialList.filter((item) => item.id !== id);
    setMaterialList(items);
  };

  const [siteExpenseProject, setSiteExpenseProject] = useState(null);
  const [showSiteExpenseProjectChooser, setShowSiteExpenseProjectChooser] =
    useState(false);

  const [initialProfileRole, setInitialProfileRole] = useState({});
  const [creatingBug, setCreatingBug] = useState(false);

  const assignBtn = (
    <Button variant="outlined" startIcon={<Add />}>
      Click here to assign
    </Button>
  );
  const projectProfileId = issue?.project?.profile?._id;
  const orgProfileId = issue?.organization?.profile?._id;
  //Initalize
  useEffect(() => {
    let locInitialProfileRole = {};
    if (orgProfileId) {
      locInitialProfileRole[orgProfileId] = "Owner";
    }
    if (userProfileId) {
      locInitialProfileRole[userProfileId] = "Owner";
    }
    if (projectProfileId) {
      locInitialProfileRole[projectProfileId] = "Owner";
    }
    setInitialProfileRole(locInitialProfileRole);
  }, [orgProfileId, userProfileId, projectProfileId]);

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle={`Edit ${issue?.template?.title} Ticket`}
      onAppBarBackButtonClick={() => {
        history.goBack();
      }}
      appBarActions={
        <HorizBox>
          {ShareButton}
          {ShareDrawer}
          <Box sx={{ my: "7px" }}>
            {template?.pipeline?.length > 0 ? (
              <StatusPicker
                pipeline={template?.pipeline}
                startState={template?.startState}
                status={status}
                setStatus={setStatus}
              />
            ) : null}
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              updateIssueApi();
            }}
          >
            Update Ticket
          </Button>
        </HorizBox>
      }
    >
      <StandardAppContainerRounded>
        <Drawer anchor={"right"} open={open} onClose={() => setOpen(false)}>
          <Logs dataModelId={issue?._id} dataModel="Issue" setOpen={setOpen} />
          {/* <IssueLogs issueId={issue?._id} /> */}
        </Drawer>

        <Box>
          <Typography className={classes.sectionTitle}>
            Ticket Details
          </Typography>
          <Box className={classes.formField}>
            <FormBox label="Title">
              <TextField
                value={title}
                placeholder="Enter ticket title"
                onChange={(event) => setTitle(event.target.value)}
                variant="outlined"
                fullWidth
                size="small"
                className={classes.textField}
              />
            </FormBox>
          </Box>

          <Box className={classes.formField}>
            <FormBox label="Description">
              <DescriptionInput
                NoTitle
                description={description}
                placeholder="Enter ticket description"
                setDescription={setDescription}
                className={classes.descriptionField}
              />
            </FormBox>
          </Box>

          <Box>
            <Box>
              <FormBox label="Upload File">
                <Box className={classes.uploadSection}>
                  {files && files.length > 0 && (
                    <Box mt={2}>
                      <AllFileViewerFeed
                        picVideoViewerHeight={"350px"}
                        picVideoViewerHeightSmall={"250px"}
                        picVideoViewerWidth={"100%"}
                        files={files}
                        isDeletable={true}
                        removeFun={removeFun}
                      />
                    </Box>
                  )}

                  {!noFileUploadBtn && (
                    <Box display="flex" justifyContent="center" mt={2}>
                      <CustomFileUploadButton
                        showComponent={
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            className={classes.actionButton}
                          >
                            Upload Files
                          </Button>
                        }
                        parentType={"Doc"}
                        parentId={null}
                        fileNum={25}
                        givenMaxSize={26214400}
                        closeFunCall={() => {
                          console.log("close dialog");
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </FormBox>

              <Box display="flex" gap={3} flexWrap="wrap">
                <Box className={classes.datePickerGroup}>
                  <FormBox flex={1}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        margin="normal"
                        label="Start Date"
                        format="dd MMMM yyyy"
                        value={startDate ? startDate : new Date()}
                        onChange={(date) => {
                          setStartDate(date);
                        }}
                        className={classes.datePickerField}
                        autoOk
                      />
                    </MuiPickersUtilsProvider>
                  </FormBox>

                  <FormBox flex={1}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        margin="normal"
                        label="End Date"
                        format="dd MMMM yyyy"
                        value={dueDate ? dueDate : new Date()}
                        onChange={(date) => {
                          setDueDate(date);
                        }}
                        className={classes.datePickerField}
                        autoOk
                      />
                    </MuiPickersUtilsProvider>
                  </FormBox>
                </Box>
                <FormBox label="Priority">
                  <FormControl component="fieldset" fullWidth>
                    <RadioGroup
                      row
                      aria-label="priority"
                      name="priority"
                      value={priority}
                      onChange={(e) => {
                        setPriority(e.target.value);
                      }}
                      className={classes.priorityGroup}
                    >
                      <FormControlLabel
                        value={"1"}
                        control={
                          <Radio style={{ color: "grey" }} size="small" />
                        }
                        label="Very Low"
                        labelPlacement="bottom"
                      />
                      <FormControlLabel
                        value={"2"}
                        control={
                          <Radio style={{ color: "green" }} size="small" />
                        }
                        label="Low"
                        labelPlacement="bottom"
                      />
                      <FormControlLabel
                        value={"3"}
                        control={
                          <Radio style={{ color: "#f0ad4e" }} size="small" />
                        }
                        label="Medium"
                        labelPlacement="bottom"
                      />
                      <FormControlLabel
                        value={"4"}
                        control={
                          <Radio style={{ color: "orange" }} size="small" />
                        }
                        label="High"
                        labelPlacement="bottom"
                      />
                      <FormControlLabel
                        value={"5"}
                        control={
                          <Radio style={{ color: "red" }} size="small" />
                        }
                        label="Urgent"
                        labelPlacement="bottom"
                      />
                    </RadioGroup>
                  </FormControl>
                </FormBox>

                <FormBox
                  label="Labels"
                  className={classes.labelField}
                  style={{ width: "600px" }}
                >
                  <CategoryAutocomplete
                    libraryId={issue?.template?.library?._id}
                    selectedCategories={labels}
                    setSelectedCategories={setLabels}
                    isMultiple={true}
                    label={"Label"}
                    profileId={issue?.profile?._id}
                    type={"Task"}
                    isCreateNew={true}
                    placeholder="Select Label"
                    style={{ width: "600px" }}
                  />
                </FormBox>

                <SpaceBetween
                  left={
                    <FormBox label="Project">
                      <ProjectPicker
                        selectedProject={chosenProject}
                        setSelectedProject={setChosenProject}
                        fullWidth={true}
                      />
                    </FormBox>
                  }
                  right={
                    <FormBox label="Organization">
                      <OrgPicker
                        selectedOrg={chosenOrganization}
                        setSelectedOrg={setChosenOrganization}
                        fullWidth={true}
                      />
                    </FormBox>
                  }
                />
                <FormBox label="Assign">
                  <div style={{ marginBottom: "10px" }}>{AssignButton}</div>
                  <div>{AssignView}</div>
                  <div>{AssigneDrawer}</div>
                </FormBox>
              </Box>
            </Box>
          </Box>
        </Box>
      </StandardAppContainerRounded>

      <Choose
        open={openChooseProject}
        setOpen={setOpenChooseProject}
        parentModelName="Project"
        multiple={false}
        disableModelChange={true}
        placeHolder={`Find Projects`}
        onSelected={(arr) => {
          if (arr && arr.length > 0) {
            setChosenProject(arr[0]);
            let updatedIssue = issue;
            updatedIssue.project = arr[0];
            setIssue(updatedIssue);
          }
        }}
      />

      <Choose
        open={openChooseOrganization}
        setOpen={setOpenChooseOrganization}
        parentModelName="Organization"
        multiple={false}
        disableModelChange={true}
        placeHolder={`Find Organization`}
        onSelected={(arr) => {
          if (arr && arr.length > 0) {
            setChosenOrganization(arr[0]);
            let updatedIssue = issue;
            updatedIssue.organization = arr[0];
            setIssue(updatedIssue);
          }
        }}
      />
    </StandardContainer>
  );
}
export default EditProfileIssue;

{
  /* <Grid
            item
            sm={12}
            md={3}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "1rem",
            }}
          >
            
          </Grid>
          <Grid
            item
            sm={12}
            md={3}
            xs={12}
            className={matches ? col : row}
            style={{ justifyContent: "space-around", marginTop: "1rem" }}
          >
            
          </Grid> */
}

{
  /* <Grid
            item
            sm={12}
            md={3}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" className={classes.text}>
              Subject
            </Typography>
          </Grid>
          <Grid
            item
            sm={12}
            md={9}
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {parent ? (
              getSubjectText(
                setParent,
                parentModel,
                parentObject,
                handleClickOpen
              )
            ) : (
              <Button
                style={{ margin: "1rem" }}
                onClick={handleClickOpen}
                variant="contained"
                color="primary"
              >
                Choose Subject
              </Button>
            )}

            <SubjectIssue
              openDialog={openDialog}
              setOpenDialog={setOpenDialog}
              parent={parent}
              setParent={setParent}
              parentObject={parentObject}
              setParentObject={setParentObject}
              parentModel={parentModel}
              setParentModel={setParentModel}
            />
          </Grid> */
}

{
  /* <div>
              <TemplateAttach
                profileId={profileId}
                template={template}
                templates={templates}
                type={"Issue"}
                onSelect={(template) => {
                  setTemplate(template);
                  const pipeline = template?.pipeline || [];
                  if (pipeline.length > 0) {
                    let currentstatus = pipeline.filter(
                      (obj) => obj?._id == status?._id
                    );
                    if (currentstatus.length == 0) {
                      let startState = pipeline.filter(
                        (obj) =>
                          obj?._id ==
                          template?.startState
                      )[0];
                      if (
                        (startState || []).length == 0
                      ) {
                        startState = pipeline[0];
                      }
                      setStatus(startState);
                    }
                  }
                }}
              />
            </div> */
}

{
  /* <Grid
            item
            sm={12}
            md={3}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" className={classes.text}>
              Start and End Dates
            </Typography>
          </Grid>
          <Grid
            item
            sm={12}
            md={9}
            xs={12}
            className={matches ? col : row}
            style={{ justifyContent: "space-around" }}
          >
            {dateTimePickView}
          </Grid>
          <Grid
            item
            sm={12}
            md={3}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" className={classes.text}>
              Priority
            </Typography>
          </Grid>
          <Grid
            item
            sm={12}
            md={9}
            xs={12}
            className={matches ? col : row}
            style={{ justifyContent: "space-around" }}
          >
            <FormControl
              component="fieldset"
              style={{ paddingBottom: "1.2rem" }}
            >
              <RadioGroup
                row
                aria-label="position"
                name="position"
                value={priority}
                onChange={(e) => {
                  setPriortiy(e.target.value);
                }}
              >
                <FormControlLabel
                  value={"1"}
                  control={<Radio color="primary" />}
                  label="Very Low"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={"2"}
                  control={<Radio color="primary" />}
                  label="Low"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={"3"}
                  control={<Radio color="primary" />}
                  label="Medium"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={"4"}
                  control={<Radio color="primary" />}
                  label="High"
                  labelPlacement="bottom"
                />
                <FormControlLabel
                  value={"5"}
                  control={<Radio color="primary" />}
                  label="Urgent"
                  labelPlacement="bottom"
                />
              </RadioGroup>
            </FormControl>
          </Grid> */
}

{
  /* <Grid
            item
            sm={12}
            md={3}
            xs={12}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="h5" className={classes.text}>
              Checklist
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <TaskList
              issue={issue}
              taskIds={taskIds}
              setTaskIds={setTaskIds}
            />
          </Grid> */
}
