import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import DocSvg from "../../../Assets/FileIcon/docIcon.svg";
import PdfIcon from "../../../Assets/FileIcon/pdfIcon.svg";
import XlsIcon from "../../../Assets/FileIcon/excelIcon.svg";
import PptIcon from "../../../Assets/FileIcon/pptIcon.svg";
import CsvIcon from "../../../Assets/FileIcon/csvIcon.svg";
import HtmlIcon from "../../../Assets/FileIcon/htmlIcon.svg";
import RtfIcon from "../../../Assets/FileIcon/rtfIcon.svg";
import TxtIcon from "../../../Assets/FileIcon/txtIcon.svg";
import CancelIcon from "@material-ui/icons/Cancel";
import DownloadIcon from "@material-ui/icons/GetApp";
import PreviewIcon from "@material-ui/icons/Visibility";
import CircularProgress from "@material-ui/core/CircularProgress";
import Tooltip from "@material-ui/core/Tooltip";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const getFileTypeColor = (type) => {
  if (type?.includes("pdf")) return "#FF4B4B";
  if (type?.includes("xls")) return "#34A853";
  if (type?.includes("ppt")) return "#FF6D01";
  if (type?.includes("doc")) return "#4285F4";
  return "#666666";
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    margin: "0.5rem",
    width: ({ isMobile }) => (isMobile ? "100%" : "300px"),
    minHeight: "64px",
    padding: "8px 15px",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
    boxShadow: "none",
    border: "1px solid #E7E7ED",
    transition: "all 0.2s ease-in-out",
    position: "relative",
    "&:hover": {
      backgroundColor: "#f5f5f5",
      transform: "translateY(-1px)",
      boxShadow: "0 2px 5px rgba(0,0,0,0.1)",
      "& $actionButtons": {
        opacity: 1,
      },
    },
  },
  iconContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "12px",
    minWidth: "40px",
    position: "relative",
  },
  imgStyle: {
    width: "24px",
    height: "auto",
  },
  fileType: {
    fontSize: "10px",
    textTransform: "uppercase",
    color: ({ fileTypeColor }) => fileTypeColor,
    marginTop: "4px",
    fontWeight: "600",
  },
  contentContainer: {
    flex: 1,
    overflow: "hidden",
  },
  fileName: {
    fontSize: "14px",
    fontWeight: "600",
    marginBottom: "4px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  fileInfo: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
    fontSize: "12px",
    "& > span + span": {
      marginLeft: "8px",
      "&:before": {
        content: '"•"',
        marginRight: "8px",
      },
    },
  },
  actionButtons: {
    position: "absolute",
    right: "12px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    opacity: 0,
    transition: "opacity 0.2s ease-in-out",
    backgroundColor: "#f5f5f5",
    padding: "4px 8px",
    borderRadius: "4px",
  },
  iconButton: {
    padding: "8px",
    fontSize: "28px",
    color: theme.palette.text.secondary,
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      color: theme.palette.primary.main,
      transform: "scale(1.1)",
    },
  },
  deleteIcon: {
    position: "absolute",
    right: "-8px",
    top: "-8px",
    cursor: "pointer",
    color: theme.palette.error.main,
    backgroundColor: "white",
    borderRadius: "50%",
    padding: "2px",
    zIndex: 2,
    opacity: 0.9,
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      opacity: 1,
      transform: "scale(1.1)",
      backgroundColor: theme.palette.error.light,
      color: "white",
    },
  },
  progressOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    zIndex: 1,
  },
}));

const formatFileSize = (bytes) => {
  if (!bytes) return "0 B";
  const k = 1024;
  const sizes = ["B", "KB", "MB", "GB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`;
};

const getFileExtension = (type) => {
  if (type.includes("pdf")) return "PDF";
  if (type.includes("xls")) return "XLS";
  if (type.includes("ppt")) return "PPT";
  if (type.includes("plain")) return "TXT";
  if (type.includes("rtf")) return "RTF";
  if (type.includes("csv")) return "CSV";
  if (type.includes("html")) return "HTML";
  return "DOC";
};

const DownloadDocCard = ({ file, isDeletable, removeFun }) => {
  const [downloading, setDownloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const fileTypeColor = getFileTypeColor(file?.type);
  const classes = useStyles({ isMobile, fileTypeColor });
  const [fileType, setFileType] = useState(DocSvg);

  useEffect(() => {
    let fileType = file?.type?.toLowerCase() || "";
    let FileIcon = DocSvg;
    if (fileType.includes("pdf")) FileIcon = PdfIcon;
    else if (fileType.includes("xls")) FileIcon = XlsIcon;
    else if (fileType.includes("ppt")) FileIcon = PptIcon;
    else if (fileType.includes("plain")) FileIcon = TxtIcon;
    else if (fileType.includes("rtf")) FileIcon = RtfIcon;
    else if (fileType.includes("csv")) FileIcon = CsvIcon;
    else if (fileType.includes("html")) FileIcon = HtmlIcon;
    setFileType(FileIcon);
  }, [file?.type]);

  const handlePreview = (e) => {
    e.stopPropagation();
    if (file?.url) {
      window.open(file.url, "_blank", "noopener,noreferrer");
    }
  };

  return (
    <div style={{ position: "relative" }}>
      {isDeletable && (
        <Tooltip title="Delete file" placement="top">
          <CancelIcon
            className={classes.deleteIcon}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              removeFun?.(file?._id);
            }}
            aria-label="delete file"
          />
        </Tooltip>
      )}
      <Paper className={classes.root}>
        {downloading && (
          <div className={classes.progressOverlay}>
            <CircularProgress
              variant="determinate"
              value={downloadProgress}
              size={24}
            />
          </div>
        )}
        <div className={classes.iconContainer}>
          <img src={fileType} className={classes.imgStyle} alt="file-icon" />
          <span className={classes.fileType}>
            {getFileExtension(file?.type || "")}
          </span>
        </div>
        <div className={classes.contentContainer}>
          <Typography className={classes.fileName}>
            {file?.documentName || file?.name}
          </Typography>
          <div className={classes.fileInfo}>
            <span>{formatFileSize(file?.size)}</span>
            <span>{new Date(file?.createdAt).toLocaleDateString()}</span>
          </div>
        </div>
        <div className={classes.actionButtons}>
          <Tooltip title="Preview">
            <PreviewIcon
              className={classes.iconButton}
              onClick={handlePreview}
            />
          </Tooltip>
        </div>
      </Paper>
    </div>
  );
};

export default DownloadDocCard;
