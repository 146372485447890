import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Avatar, Button, Typography } from "@material-ui/core";
import ResourceShareDrawer from "./Resource.Share.Drawer";
import {
  getProfilesByIdsArr,
  getResourceAccessByResource,
  getResourceAssigneds,
} from "./Api.call";
import ResourceAssignedDrawer from "./Resource.Assigned.Drawer";
import { mainRoles } from "../../helpers/contactHelper";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  allChipsCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    padding: "10px",
    gap: "8px",
    minHeight: "50px",
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
  chipsCont: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ececec",
    borderRadius: "20px",
    marginBottom: "10spx",
    marginRight: "10px",
    padding: "3px 5px",
    "& .MuiAvatar-root": {
      height: "30px",
      width: "30px",
      margin: "0px",
    },
    "& h3": {
      fontSize: "15px",
      fontWeight: "500",
      color: "gray",
    },
    "& div": {
      margin: "0px 5px",
      fontSize: "15px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "15px",
      fontWeight: "500",
      marginLeft: "10px",
    },
    transition: "all 0.2s ease",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
      transform: "translateY(-1px)",
    },
  },
}));

const searchParameter = [
  {
    entity: "User",
    scop: [
      {
        type: "Global",
        role: [],
      },
    ],
  },
  {
    entity: "Organization",
    scop: [
      {
        type: "Global",
        role: [],
      },
    ],
  },
  {
    entity: "Project",
    scop: [
      {
        type: "Contact",
        role: [...mainRoles],
      },
    ],
  },
];

const assignedSearchParameter = [
  {
    entity: "User",
    scop: [
      {
        type: "Global",
        role: [],
      },
    ],
  },
];

function useShareAndAssign({
  initShared,
  givenShareCurEntity = null,
  givenShareCurEntityId = null,
  givenShareSearchParam = null,
  customShareBtn = null,
  initAssigned,
  givenAssignCurEntity = null,
  givenAssignCurEntityId = null,
  givenAssignSearchParam = null,
  customAssignBtn = null,
  resourceId,
  resourceName,
}) {
  const classes = useStyles();

  const [openResourceShare, setOpenResourceShare] = useState(false);
  const [sharedProfilesRole, setSharedProfilesRole] = useState([]);
  const [shareCurEntity, setShareCurEntity] = useState();
  const [shareCurEntityId, setShareCurEntityId] = useState();
  const [shareSearchParams, setShareSearchParams] = useState();
  const [openResourceAssign, setOpenResourceAssign] = useState(false);
  const [assignedProfiles, setAssignedProfiles] = useState([]);
  const [assignCurEntity, setAssignCurEntity] = useState();
  const [assignCurEntityId, setAssignCurEntityId] = useState();
  const [assignSearchParams, setAssignSearchParams] = useState();

  const { user } = useSelector((state) => state.auth);

  //share props initialize
  useEffect(() => {
    if (givenShareCurEntity != null && givenShareCurEntity != undefined) {
      setShareCurEntity(givenShareCurEntity);
    } else {
      setShareCurEntity("User");
    }
  }, [givenShareCurEntity]);

  useEffect(() => {
    if (givenShareCurEntityId != null && givenShareCurEntityId != undefined) {
      setShareCurEntityId(givenShareCurEntityId);
    } else {
      setShareCurEntityId(user?._id);
    }
  }, [givenShareCurEntityId, user?._id]);

  useEffect(() => {
    if (givenShareSearchParam != null && givenShareSearchParam != undefined) {
      setShareSearchParams(givenShareSearchParam);
    } else {
      setShareSearchParams(searchParameter);
    }
  }, [givenShareSearchParam]);

  const getExistingResourceAccesses = async (ids) => {
    await getResourceAccessByResource({ resourceId, resourceName })
      .then((data) => {
        let roleArr = [];
        data.map((s) => {
          const participantProfile = s.user;
          roleArr.push({
            ...participantProfile,
            accessId: s?._id,
            role: s?.role,
          });
        });
        setSharedProfilesRole(roleArr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (
      resourceId != null &&
      resourceId != undefined &&
      resourceName != null &&
      resourceName != undefined &&
      resourceName != ""
    ) {
      getExistingResourceAccesses();
    } else if (initShared != null && initShared !== undefined) {
      let arr = [];
      Object.keys(initShared).map((profileId) => {
        arr.push(profileId);
      });
      getProfilesByIdsArr({ profileIds: arr })
        .then((data) => {
          let roleArr = [];
          data.map((s) => {
            roleArr.push({
              ...s,
              accessId: null,
              role: initShared[s?._id],
            });
          });
          setSharedProfilesRole(roleArr);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSharedProfilesRole(true);
    }
  }, [initShared]);

  //assign props initialize
  useEffect(() => {
    if (givenAssignCurEntity != null && givenAssignCurEntity != undefined) {
      setAssignCurEntity(givenAssignCurEntity);
    } else {
      setAssignCurEntity("User");
    }
  }, [givenAssignCurEntity]);

  useEffect(() => {
    if (givenAssignCurEntityId != null && givenAssignCurEntityId != undefined) {
      setAssignCurEntityId(givenAssignCurEntityId);
    } else {
      setAssignCurEntityId(user?._id);
    }
  }, [givenAssignCurEntityId, user?._id]);

  useEffect(() => {
    if (givenAssignSearchParam != null && givenAssignSearchParam != undefined) {
      setAssignSearchParams(givenAssignSearchParam);
    } else {
      setAssignSearchParams(assignedSearchParameter);
    }
  }, [givenAssignSearchParam]);

  const getExistingResourceAssign = async () => {
    if (resourceName && resourceId) {
      await getResourceAssigneds({ resourceName, resourceId })
        .then((data) => {
          if (data && data.length > 0) {
            setAssignedProfiles(data);
          } else {
            setAssignedProfiles([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    if (
      resourceId != null &&
      resourceId != undefined &&
      resourceName != null &&
      resourceName != undefined &&
      resourceName != ""
    ) {
      getExistingResourceAssign();
    } else if (initAssigned != null && initAssigned !== undefined) {
      getProfilesByIdsArr({ profileIds: initAssigned })
        .then((data) => {
          let arr = [];
          data.map((s) => {
            arr.push(s);
          });
          setAssignedProfiles(arr);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setAssignedProfiles([]);
    }
  }, [initAssigned]);

  const afterSaveClickAssignOnNewCreate = (value) => {
    setAssignedProfiles(value);
    if (value && value.length > 0) {
      let sharedProfileIds = [];
      let profileIdRoleMap = {};
      let profileIdIndexMap = {};
      const isArray = Array.isArray(sharedProfilesRole);
      if (isArray) {
        sharedProfilesRole.map((s, i) => {
          profileIdRoleMap[s?._id] = s?.role;
          profileIdIndexMap[s?._id] = i;
          sharedProfileIds.push(s?._id);
        });
      }

      let arr = isArray ? [...sharedProfilesRole] : [];
      value.map((s) => {
        if (sharedProfileIds.includes(s?._id)) {
          if (profileIdRoleMap[s?._id] === "Viewer") {
            arr[profileIdIndexMap[s?._id]].role = "Editor";
          }
        } else {
          arr.push({
            ...s,
            role: "Editor",
          });
        }
      });
      setSharedProfilesRole(arr);
    }
  };

  const CustomtChip = ({ imageUrl, name, role = null }) => {
    return (
      <div className={classes.chipsCont}>
        <Avatar src={imageUrl} alt={name} />
        <p>{name}</p>
        {role && role.length > 0 && (
          <>
            <div>-</div>
            <h3>{role}</h3>
          </>
        )}
      </div>
    );
  };

  //share
  const ShareButton = (
    <span
      style={{ cursor: "pointe" }}
      onClick={() => {
        setOpenResourceShare(true);
      }}
    >
      {customShareBtn ? (
        customShareBtn
      ) : (
        <Button variant="contained" color="primary">
          Share
        </Button>
      )}
    </span>
  );

  const ShareDrawer = (
    <ResourceShareDrawer
      openResourceShare={openResourceShare}
      setOpenResourceShare={setOpenResourceShare}
      resourceName={resourceName}
      resourceId={resourceId}
      sharedProfilesRole={sharedProfilesRole}
      setSharedProfilesRole={setSharedProfilesRole}
      entity={shareCurEntity}
      curEntityId={shareCurEntityId}
      callbackAfterShare={(isForEdit, value) => {
        if (isForEdit) {
          getExistingResourceAccesses();
        } else {
          setSharedProfilesRole(value);
        }
      }}
      searchParameter={shareSearchParams}
    />
  );

  const SharedView = (
    <div className={classes.allChipsCont}>
      {sharedProfilesRole &&
        sharedProfilesRole.length > 0 &&
        sharedProfilesRole.map((profileRole) => (
          <CustomtChip
            imageUrl={
              profileRole?.parent?.displayPicture?.thumbUrl
                ? profileRole?.parent?.displayPicture?.thumbUrl
                : profileRole?.parent?.displayPicture?.url
            }
            name={profileRole?.parent?.displayName}
            role={profileRole?.role}
          />
        ))}
    </div>
  );

  //assign
  const AssignButton = (
    <span
      style={{ cursor: "pointe" }}
      onClick={() => {
        setOpenResourceAssign(true);
      }}
    >
      {customAssignBtn ? (
        customAssignBtn
      ) : (
        <Button variant="contained" color="primary" startIcon={<SearchIcon />}>
          Assign
        </Button>
      )}
    </span>
  );

  const AssigneDrawer = (
    <ResourceAssignedDrawer
      openResourceAssign={openResourceAssign}
      setOpenResourceAssign={setOpenResourceAssign}
      resourceName={resourceName}
      resourceId={resourceId}
      assignedProfiles={assignedProfiles}
      setAssignedProfiles={setAssignedProfiles}
      entity={assignCurEntity}
      curEntityId={assignCurEntityId}
      searchParameter={assignSearchParams}
      callbackAfterShare={(isForEdit, value) => {
        if (isForEdit) {
          getExistingResourceAssign();
          getExistingResourceAccesses();
        } else {
          afterSaveClickAssignOnNewCreate(value);
        }
      }}
    />
  );

  const AssignView = (
    <div className={classes.allChipsCont}>
      {assignedProfiles && assignedProfiles.length > 0 ? (
        assignedProfiles.map((profileRole, index) => (
          <CustomtChip
            key={index}
            imageUrl={
              profileRole?.parent?.displayPicture?.thumbUrl ||
              profileRole?.parent?.displayPicture?.url ||
              "/default-avatar.png"
            } // Fallback image
            name={profileRole?.parent?.displayName || "Unknown User"}
            role={profileRole?.role} // Show role if available
          />
        ))
      ) : (
        <Typography variant="body2" color="textSecondary">
          No users assigned yet
        </Typography>
      )}
    </div>
  );

  return {
    sharedProfilesRole,
    ShareButton,
    ShareDrawer,
    SharedView,
    assignedProfiles,
    AssignButton,
    AssigneDrawer,
    AssignView,
  };
}

export default useShareAndAssign;
