import { useState, useEffect } from "react";
import { getBookingById } from "./api";

const useBookingData = (bookingId) => {
  const [bookingData, setBookingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBookingData = async () => {
      try {
        const data = await getBookingById(bookingId);
        setBookingData(data);

      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBookingData();
  }, [bookingId]);

  return { bookingData, setBookingData, loading, error };
};

export default useBookingData;
