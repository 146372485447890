import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import config from "../../../config";
import { useState, useEffect } from "react";
import Api from "../../../helpers/Api";
import DocCard from "../../doc/Doc.Card";

export default function BookingDocumentsTab(props) {
  const { booking } = props;

  const isDev = config?.mode == "dev";

  const reqDocuments = booking?.docFolder?.documentStatus;
  const folderId = booking?.docFolder?._id;
  const template = booking?.docFolder?.template;

  const dispatch = useDispatch();
  const history = useHistory();
  const [documents, setDocuments] = useState([]);
  console.log("Documents", documents);

  const redirectToDocuments = () => {
    if (!booking?.docFolder?.profile) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "No document folder found",
        },
      });
      return;
    }
    const path = `/doc/folder/${booking?.docFolder?.profile}`;
    if (isDev) {
      history.push(path);
    } else if (config.mode == "prod") {
      let link = `https://reallist.ai/${path}`;
      window.open(link, "_self");
    }
  };

  const fetchDocumentsData = async () => {
    try {
      if (reqDocuments && reqDocuments.length > 0) {
        await Promise.all(
          reqDocuments.map(async (ds) => {
            if (!ds.document) {
              const res = await Api.post(
                "/doc/folder/template/update-document",
                {
                  folderId: folderId,
                  documentName: ds.documentName,
                  status: ds.status,
                  parentTemplate: template,
                }
              );
            }
          })
        );
        let tempDocuments = [];
        await Promise.all(
          reqDocuments.map(async (doc) => {
            const docData = await Api.post("/doc/getDocDetail", {
              docId: doc.document,
            });
            tempDocuments.push({ ...doc, document: docData.data[0] });
          })
        );
        setDocuments(tempDocuments);
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Error in fetching documents data",
        },
      });
    }
  };

  useEffect(() => {
    fetchDocumentsData();
  }, [booking]);

  return template ? (
    <div>
      {documents && documents.length > 0 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "10px",
          }}
        >
          {documents.map((doc) => (
            <DocCard
              key={doc.document._id}
              doc={doc.document}
              status={doc.status}
            />
          ))}
        </div>
      ) : (
        <div>No documents found</div>
      )}
      <Button onClick={redirectToDocuments} variant="outlined" color="primary">
        Manage Documents
      </Button>
    </div>
  ) : (
    <div
      style={{ textAlign: "center", fontWeight: "bold", fontSize: "1.2rem" }}
    >
      No template found for this booking
    </div>
  );
}
