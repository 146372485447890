import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Autocomplete,
  Avatar,
  Box,
  IconButton,
  MenuItem,
  Select,
  Divider,
  TextField,
  Typography,
  Checkbox,
  Paper,
} from "@mui/material";
import { Close } from "@material-ui/icons";
import DrawerContainer from "../../styled/generic/DrawerContainer";
import FormBox from "../../styled/generic/FormBox";
import SpaceBetween from "../../styled/generic/SpaceBetween";
import CreateTransactionTable from "../transaction/CreateTranscationTable";
import AttachmentsHandler from "../../styled/CommonComponents/Attachments.Handler";
import DuoButtonGroup from "../../styled/generic/DuoButtonGroup";
import ConfirmationDialog from "../../global/ConfirmationDialog";
import Api from "../../../helpers/Api";
import { allStatusOptions } from "../../../helpers/allStatuses";
import BillingAccountSelector from "../transaction/BillingAccountSelector";

const initialFormState = {
  subject: "",
  selectedBooking: null,
  selectedBankAccount: null,
  paymentStatus: "Draft",
  transactions: [],
  attachedFiles: [],
  selectedBillingAccount: null,
  bankAccountId: null,
  billItems: [],
};

const styles = {
  drawer: {
    "& .MuiDrawer-paper": {
      backgroundColor: "#ffffff",
    },
  },
  container: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    backgroundColor: "#f5f5f5",
    minHeight: "100%",
  },
  section: {
    marginBottom: "16px",
    padding: "16px",
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    border: "1px solid rgba(0, 0, 0, 0.12)",
  },
  sectionHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "12px",
  },
  sectionTitle: {
    fontSize: "1.125rem",
    fontWeight: "600",
    color: "primary.main",
    marginBottom: "20px",
  },
  subHeading: {
    fontSize: "1rem",
    fontWeight: "500",
    color: "text.primary",
    marginBottom: "12px",
  },
  formBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    marginBottom: "16px",
  },
  inputBase: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
      height: "44px",
      width: "100%",
    },
    "& .MuiOutlinedInput-input": {
      fontSize: "0.938rem",
      padding: "10px 14px",
      height: "24px",
    },
    "& .MuiInputLabel-root": {
      fontSize: "1rem",
    },
  },
  label: {
    fontSize: "0.938rem",
    fontWeight: "500",
    color: "text.secondary",
    marginBottom: "8px",
  },
  textField: {
    "& .MuiInputBase-input": {
      fontSize: "0.813rem",
      padding: "8px 12px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
    },
  },
  select: {
    height: "44px",
    fontSize: "0.938rem",
    minWidth: "180px",
    "& .MuiSelect-select": {
      padding: "10px 14px",
      height: "24px",
      display: "flex",
      alignItems: "center",
    },
  },
  menuItem: {
    fontSize: "0.938rem",
    padding: "10px 14px",
    height: "40px",
  },
  formLabel: {
    fontSize: "1rem",
    fontWeight: "500",
    color: "text.primary",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  avatar: {
    width: 24,
    height: 24,
  },
  checkbox: {
    "& .MuiTypography-root": {
      fontSize: "0.813rem",
    },
  },
  bookingSelect: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
      fontSize: "0.813rem",
    },
    "& .MuiAutocomplete-input": {
      padding: "8px 12px !important",
      fontSize: "0.813rem",
    },
    "& .MuiAutocomplete-endAdornment": {
      top: "calc(50% - 11px)",
    },
  },
  bookingOption: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    padding: "6px 12px",
  },
  bookingOptionText: {
    fontSize: "0.813rem",
  },
  bookingAvatar: {
    width: 28,
    height: 28,
  },
  billItemsHeader: {
    fontSize: "0.875rem",
    fontWeight: "600",
    color: "text.primary",
  },
};

function CreateTransactionModal({
  isOpen,
  onClose,
  initialData = {},
  libraryId,
  profileId,
  reload,
  setReload,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState(initialFormState);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedDialogMessage, setSelectedDialogMessage] = useState(0);
  const [billItems, setBillItems] = useState([]);
  const [useDefaultFinancialDetails, setUseDefaultFinancialDetails] =
    useState(false);
  const [paymentDetails, setPaymentDetails] = useState([]);

  useEffect(() => {
    // selectedBooking;
    if (formData.selectedBooking) {
      if (
        !formData.selectedBooking.billingAccount ||
        !formData?.selectedBooking.billingAccount?.bankAccount?._id
      ) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,

            message: "No billing account found",
          },
        });
        console.log("No billing account found");
        return;
      } else {
        setFormData({
          ...formData,
          selectedBankAccount:
            formData.selectedBooking.billingAccount.bankAccount,
          selectedBillingAccount: formData.selectedBooking.billingAccount,
        });
      }
    }
  }, [formData.selectedBooking]);

  const PAYMENT_STATUS_OPTIONS = [
    "Draft",
    "Checking",
    "Confirmed",
    "In Progress",
    "Cancelled",
    "Paid",
    "Sent",
  ];

  useEffect(() => {
    if (isOpen) {
      if (initialData?._id) {
        setIsEdit(true);
        setFormData({
          ...initialFormState,
          ...initialData,
          paymentStatus: initialData?.status ? initialData?.status : "Draft",
          selectedBooking: initialData?.booking,
          selectedBankAccount: initialData?.bankAccountId,
          selectedBillingAccount: initialData?.billingAccount,
          initialBankAccount: initialData?.bankAccountId,
          transactions: initialData?.billItems || [],
          attachedFiles: initialData?.attachedFiles || [],
          type: initialData?.type,
        });
        setBillItems(initialData.billItems || []);
      } else {
        setIsEdit(false);
        setFormData({
          ...initialFormState,
          selectedBooking: initialData?.booking ?? null,
          transactions: [],
          type: "General Transaction",
        });
      }
    }
  }, [isOpen, initialData]);

  useEffect(() => {
    fetchBookings();
  }, [libraryId]);

  useEffect(() => {
    if (formData.selectedBooking && isOpen) {
      setPaymentDetails(formData.selectedBooking.paymentDetails || []);
    }
  }, [formData.selectedBooking, isOpen]);

  const fetchBookings = async () => {
    try {
      const data = await Api.post("/listing/booking/get", { libraryId });
      if (data) {
        setBookings(data?.bookings);
      }
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const resetBillItems = () => {
    setBillItems([]);
  };

  const saveTransaction = async () => {
    setLoading(true);
    try {
      const transactionData = {
        subject: formData.subject,
        type: formData.type,
        booking: formData.selectedBooking,
        bankAccountId: useDefaultFinancialDetails ? formData?.selectedBooking?.bankAccountId?._id : formData.selectedBankAccount?._id,
        billingAccount: useDefaultFinancialDetails ? formData?.selectedBooking?.billingAccount?._id : formData?.selectedBillingAccount?._id,
        status: formData.paymentStatus,
        paymentDue: billItems.reduce((acc, t) => acc + parseInt(t.currentDue || 0), 0),
        amountPaid: billItems.reduce((acc, t) => acc + parseInt(t.receivedAgainstCurrentDue || 0), 0),
        amountPayable: billItems.reduce((acc, t) => acc + parseInt(t.totalAmountReceivable || 0), 0),
        attachedFiles: formData.attachedFiles.map((x) => x?._id || ""),
        profile: user?.profile,
        userId: user?._id,
      };

      const endpoint = isEdit ? transactionData.status === "Paid" ? `/listing/booking/transaction/paid/${initialData._id}` : "/listing/booking/transaction/update" : "/listing/booking/transaction/create";
      const payload = isEdit ?
        {
          transactionId: initialData._id,
          data: { ...initialData, ...transactionData },
          billItems: billItems,
        }
        :
        {
          transactionData,
          billItems: billItems,
          secondPartyProfile: formData?.selectedBooking?.customerProfile?._id,
          firstPartyProfile: profileId,
        };

      const response = await Api.post(endpoint, payload);

      if (response?.data) {
        setReload(!reload);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: `Transaction ${isEdit ? "updated" : "created"} successfully`,
          },
        });
        onClose();
      }
    } catch (error) {
      console.error("Error saving transaction:", error);
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: error.message || "An error occurred while saving the transaction",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSave = () => {
    if (formData.paymentStatus === "Sent") {
      setSelectedDialogMessage(0);
      setDialogOpen(true);
      return;
    }
    saveTransaction();
  };

  const confirmationDialogContent = [
    {
      title: "Confirm Status Change to Sent",
      message:
        "Are you sure you want to set the status to Sent? Once confirmed, the transactions will become uneditable, and this action cannot be undone.",
      confirmFn: saveTransaction,
      cancelFn: () => setDialogOpen(false),
    },
  ];

  return (
    <DrawerContainer
      title={isEdit ? "Edit Transaction" : "Create Transaction"}
      open={isOpen}
      setOpen={onClose}
      width="100%"
      style={styles.drawer}
      childrenButton={
        <Select
          value={formData.paymentStatus}
          defaultValue={initialData?.status}
          disabled={initialData?.status === "Paid"}
          onChange={(e) => handleInputChange("paymentStatus", e.target.value)}
          style={styles.select}
        >
          {allStatusOptions["Booking Transactions"][
            initialData?.status || "Draft"
          ].map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          ))}
          {PAYMENT_STATUS_OPTIONS.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      }
    >
      <Box style={styles.container}>
        <Paper style={styles.section}>
          <Typography
            style={{
              ...styles.sectionTitle,
              marginBottom: "16px",
            }}
          >
            Basic Information
          </Typography>
          <FormBox
            label="Subject"
            style={{
              ...styles.formBox,
            }}
          >
            <TextField
              placeholder="Enter subject"
              variant="outlined"
              value={formData.subject}
              onChange={(e) => handleInputChange("subject", e.target.value)}
              style={styles.inputBase}
              fullWidth
            />
          </FormBox>
          <FormBox label="Select Booking" sx={{ flex: "3" }}>
            {formData.selectedBooking ? (
              <MenuItem
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  border: "1px solid gray",
                  borderRadius: "10px",
                }}
              >
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Avatar
                    src={
                      formData.selectedBooking?.customerProfile?.parent
                        ?.displayPicture?.url
                    }
                  />
                  <Typography>
                    {
                      formData.selectedBooking?.customerProfile?.parent
                        ?.displayName
                    }{" "}
                    {formData.selectedBooking?.unit?.project?.displayName}
                  </Typography>
                </Box>
                <IconButton
                  onClick={() => {
                    handleInputChange("selectedBooking", null);
                    resetBillItems();
                  }}
                >
                  <Close />
                </IconButton>
              </MenuItem>
            ) : (
              <Autocomplete
                disabled={isEdit}
                options={bookings.filter((booking) => booking?.customerProfile)}
                value={formData?.selectedBooking}
                getOptionLabel={(option) =>
                  `${option?.customerProfile?.parent?.displayName} ${option?.unit?.project?.displayName}`
                }
                onChange={(_, newValue) => {
                  if (!formData?.selectedBillingAccount) {
                    handleInputChange(
                      "selectedBillingAccount",
                      newValue?.billingAccount
                    );
                    handleInputChange(
                      "selectedBankAccount",
                      newValue?.bankAccountId
                    );
                  }
                  if (newValue?.billingAccount) {
                    setUseDefaultFinancialDetails(true);
                  } else {
                    setUseDefaultFinancialDetails(false);
                  }
                  handleInputChange("selectedBooking", newValue);
                  resetBillItems();
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Booking"
                    variant="outlined"
                  />
                )}
                renderOption={(props, option) => (
                  <MenuItem {...props}>
                    <Avatar
                      src={option?.customerProfile?.parent?.displayPicture?.url}
                    />
                    <Typography>
                      {option?.customerProfile?.parent?.displayName}{" "}
                      {option?.unit?.project?.displayName}
                    </Typography>
                  </MenuItem>
                )}
              />
            )}
          </FormBox>
        </Paper>

        {/* Financial Information Section */}
        <Paper style={styles.section}>
          <Typography style={styles.sectionTitle}>
            Financial Information
          </Typography>
          {formData?.selectedBooking?.billingAccount?._id && (
            <Box sx={{ marginY: "1rem" }}>
              <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                <Checkbox
                  checked={useDefaultFinancialDetails}
                  onChange={(e) =>
                    setUseDefaultFinancialDetails(e.target.checked)
                  }
                />
                <Typography style={styles.menuItem}>
                  Use booking financial details
                </Typography>
              </Box>
            </Box>
          )}
          {!useDefaultFinancialDetails && (
            <Box sx={{ gap: 2 }}>
              <FormBox sx={{ width: "100%" }} label={"Billing account"}>
                <BillingAccountSelector
                  sourceProfile={profileId}
                  selectedBillingAccounts={formData?.selectedBillingAccount}
                  onBillingAcountChange={(value) => {
                    handleInputChange("selectedBillingAccount", value);
                    handleInputChange(
                      "selectedBankAccount",
                      value?.bankAccount
                    );
                  }}
                />
              </FormBox>
            </Box>
          )}
        </Paper>

        {/* BillItems Section */}
        <Paper style={styles.section}>
          <Box style={styles.sectionHeader}>
            <Typography style={styles.sectionTitle}>BillItems</Typography>
            <Select
              value={formData.type}
              defaultValue={initialData?.type}
              disabled={initialData?.status === "Paid"}
              onChange={(e) => {
                handleInputChange("type", e.target.value);
                resetBillItems();
              }}
              style={styles.select}
            >
              <MenuItem style={styles.menuItem} value="General Transaction">
                General Transaction
              </MenuItem>
              <MenuItem style={styles.menuItem} value="Credit Note">
                Credit Note
              </MenuItem>
              <MenuItem style={styles.menuItem} value="Debit Note">
                Debit Note
              </MenuItem>
            </Select>
          </Box>
          <Box style={{ marginTop: "16px" }}>
            <CreateTransactionTable
              paymentDetails={paymentDetails}
              status={initialData?.status || "Draft"}
              billItems={billItems}
              setBillItems={setBillItems}
              type={formData.type}
            />
          </Box>
        </Paper>

        {/* Attachments Section */}
        <Paper style={styles.section}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <AttachmentsHandler
              parentData={formData}
              setParentData={setFormData}
              parentModel={"BookingPaymentTransaction"}
              needToMakeDisable={false}
              setLoadingBool={setLoading}
            />
          </Box>
        </Paper>

        {/* Buttons */}
        <DuoButtonGroup
          disablePrimaryButton={initialData?.status === "Paid"}
          primaryButtonText="Save"
          primaryButtonListener={handleSave}
          hideSecondary={true}
          loadingPrimary={loading}
        />
      </Box>

      {/* Confirmation Dialog */}
      <ConfirmationDialog
        cancelButtonText={"Cancel"}
        cancelListener={
          confirmationDialogContent[selectedDialogMessage].cancelFn
        }
        successButtonText={"Confirm"}
        successListener={
          confirmationDialogContent[selectedDialogMessage].confirmFn
        }
        open={dialogOpen}
        setOpen={setDialogOpen}
        message={confirmationDialogContent[selectedDialogMessage].message}
        title={confirmationDialogContent[selectedDialogMessage].title}
      />
    </DrawerContainer>
  );
}

export default CreateTransactionModal;
