import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StandardContainer from "../../styled/generic/StandardContainer";
import HorizBox from "../../styled/generic/HorizBox";
import Button from "../../styled/generic/Button";
import StandardAppContainerRounded from "../../styled/generic/StandardAppContainerRounded";
import { Add, SettingsOutlined } from "@material-ui/icons";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import TabsWithActions from "../../styled/generic/TabsWithActions";
import { LuMessagesSquare } from "react-icons/lu";
import { PiHammerDuotone } from "react-icons/pi";
import Api from "../../../helpers/Api";
import { getWalletDataByProfileId } from "../../finance2o/accounts/api.call";
import Bookings from "./Bookings";
import Listings from "../Listings";
import Transaction from "../transaction/Transaction";
import DemandDeeds from "../demanddeed/DemandDeeds";
import CreateDemandDeedDrawer from "./demandDeed/CreateDemandDeedDrawer";
import CreateTransactionModal from "./CreateTransactionModal";
import BookingOffers from "../offers/BookingOffers";
import CreateOfferModel from "../offers/CreateOfferModel";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import BookingAnalytics from "../analytics/BookingAnalytics";
import CreateBookingModel from "./CreateBookingModel";
import { getProfileDataPopulate } from "../../team/procurements/api.call";
import BookingSchedule from "./BookingSchedule";
import HomeIcon from "@mui/icons-material/Home";
import DescriptionIcon from "@mui/icons-material/Description";
import HandshakeIcon from "@mui/icons-material/Handshake";
import GavelIcon from "@mui/icons-material/Gavel";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const BookingHome = () => {
  const history = useHistory();
  const location = useLocation();
  const { profileId } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [showCreateBookingModel, setShowCreateBookingModel] = useState(false);

  const [tab, setTab] = useState("Booking");
  const [showCreateDemandDeedDrawer, setShowCreateDemandDeedDrawer] =
    useState(false);
  const [showCreateTransactionModal, setShowCreateTransactionModal] =
    useState(false);
  const [walletData, setWalletData] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [showCreateOfferModel, setShowCreteOfferModel] = useState(false);
  const [currentProfileData, setCurrentProfileData] = useState({});
  const [projectIds, setProjectIds] = useState([]);
  const [projects, setProjects] = useState([]);
  const [reload, setReload] = useState(false);

  const getTabFromQuery = () => {
    const params = new URLSearchParams(location.search);
    if (params.get("tab")) {
      return params.get("tab");
    } else {
      setQuery("tab", "Booking");
      return "Booking";
    }
  };
  useEffect(() => {
    setTab(getTabFromQuery());
  }, []);

  useEffect(() => {
    if (profileId) {
      setPageLoading(true);
      getWalletDataByProfileId({ profileId }).then((data) => {
        if (data) {
          setWalletData(data);
        } else {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message: "Failed to fetch wallet data",
            },
          });
        }
        setPageLoading(false);
      });
    }
  }, [profileId, dispatch]);

  useEffect(() => {
    const getProfile = async () => {
      const response = await getProfileDataPopulate({ profileId });
      if (response) {
        const profile = response;
        setCurrentProfileData(response);
        if (profile?.parentModelName === "Organization") {
          const data = await Api.post("projects/by-org", {
            organizationId: profile?.parent?._id,
          });
          if (data) {
            setProjectIds(data?.data?.projects?.map((project) => project?._id));
            setProjects(data?.data?.projects);
          }
        } else {
          setProjectIds([profile?.parent?._id]);
          setProjects([profile]);
        }
      }
    };

    if (profileId) {
      getProfile();
    }
  }, [profileId]);

  useEffect(() => {
    if (walletData?.defaultLibrary && projectIds?.length > 0) {
      getBookings();
    }
  }, [walletData, projectIds]);

  const getBookings = async () => {
    const payload =
      projectIds?.length > 0
        ? { projectIds }
        : {
          libraryId: walletData?.defaultLibrary,
        };

    const data = await Api.post("/listing/booking/get", payload);
    if (data) {
      setBookings(data.bookings);
    } else {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "Failed to fetch bookings",
        },
      });
    }
  };
  const handleEditTransaction = (transaction) => {
    setSelectedTransaction(transaction);
    setShowCreateTransactionModal(true);
  };

  const setQuery = (key, value) => {
    const params = new URLSearchParams(location.search);
    params.set("tab", value);

    history.push({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const handleTabChange = (val) => {
    setTab(val);
    setQuery("tab", val);
  };

  return (
    <StandardContainer
      showAppBar={true}
      appBarTitle="Booking"
      loading={pageLoading}
      appBarActions={
        <HorizBox>
          <Tooltip title="Issues" enterDelay={1500}>
            <IconButton
              onClick={() => history.push(`/booking/issues/${profileId}`)}
            >
              <PiHammerDuotone style={{ fontSize: "1.3rem" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Messages" enterDelay={1500}>
            <IconButton
              onClick={() => history.push(`/booking/messages/${profileId}`)}
            >
              <LuMessagesSquare style={{ fontSize: "1.3rem" }} />
            </IconButton>
          </Tooltip>
          {tab === "Booking" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setShowCreateBookingModel(true);
              }}
              startIcon={<Add />}
            >
              New Booking
            </Button>
          )}
          {tab === "Transaction" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowCreateTransactionModal(true)}
              startIcon={<Add />}
            >
              New Transaction
            </Button>
          )}
          {tab === "Demand Deed" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowCreateDemandDeedDrawer(true)}
              startIcon={<Add />}
            >
              New Demand Deed
            </Button>
          )}
          {tab === "Offer" && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setShowCreteOfferModel(true)}
              startIcon={<Add />}
            >
              New Offer
            </Button>
          )}
          <IconButton
            variant="primary"
            onClick={() => history.push(`/booking/settings/${profileId}`)}
          >
            <SettingsOutlined />
          </IconButton>
        </HorizBox>
      }
    >
      {walletData?.defaultLibrary ? (
        <>
          <StandardAppContainerRounded>
            <TabsWithActions
              onTabChange={handleTabChange}
              currentTabVariable={tab}
              tabs={[
                { title: "Booking", icon: <HomeIcon /> },
                { title: "Demand Deed", icon: <DescriptionIcon /> },
                { title: "Transaction", icon: <HandshakeIcon /> },
                { title: "Offer", icon: <GavelIcon /> },
                {
                  title: "Analytics",
                  icon: <TrendingUpIcon />,
                },
                {
                  title: "Schedules",
                  icon: <CalendarMonthIcon />,
                },
              ]}
            />
          </StandardAppContainerRounded>
          <Box sx={{ width: "100%", height: "81vh", overflowY: "scroll" }}>
            {tab === "Booking" && (
              <Bookings
                libraryId={walletData?.defaultLibrary}
                projectIds={projectIds}
              />
            )}
            {/* {tab === "Listing" && <Listings />} */}
            {tab === "Demand Deed" && <DemandDeeds bookings={bookings} />}
            {tab === "Transaction" && (
              <Transaction
                libraryId={walletData?.defaultLibrary}
                setOldTransaction={handleEditTransaction}
                reload={reload}
              />
            )}
            {tab === "Offer" && (
              <BookingOffers libraryId={walletData?.defaultLibrary}
                projectIds={projectIds} />
            )}
            {tab === "Analytics" && (
              <BookingAnalytics libraryId={walletData?.defaultLibrary} />
            )}
            {tab === "Schedules" && (
              <BookingSchedule
                libraryId={walletData?.defaultLibrary}
                bookings={bookings}
                projectIds={projectIds}
                projects={projects}
              />
            )}
          </Box>
          <CreateOfferModel
            open={showCreateOfferModel}
            setOpen={setShowCreteOfferModel}
            libraryId={walletData?.defaultLibrary}
          />
          <CreateTransactionModal
            isOpen={showCreateTransactionModal}
            onClose={() => {
              setShowCreateTransactionModal(false);
              setSelectedTransaction(null);
            }}
            reload={reload}
            setReload={setReload}
            profileId={profileId}
            initialData={selectedTransaction}
            libraryId={walletData?.defaultLibrary}
          />
          <CreateDemandDeedDrawer
            isOpen={showCreateDemandDeedDrawer}
            setIsOpen={setShowCreateDemandDeedDrawer}
            profileId={profileId}
          />
          <CreateBookingModel
            libraryId={walletData?.defaultLibrary}
            open={showCreateBookingModel}
            setOpen={setShowCreateBookingModel}
            isNeedToRedirect={true}
            onClose={(newBookingId) => {
              setShowCreateBookingModel(false);
            }}
            profileId={profileId}
          />
        </>
      ) : (
        <Box sx={{ padding: "20px" }}>
          <Typography variant="body1">
            No Library exists in the current directory or any library still
            didn't selected as default library.
            <br />
            Click{" "}
            <Typography
              component="span"
              sx={{
                color: "primary.main",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() =>
                history.push(`/offerings-categories/management/${profileId}`)
              }
            >
              here
            </Typography>{" "}
            to create and manage your library(s), category(s), product(s) and
            service(s)
          </Typography>
        </Box>
      )}
    </StandardContainer>
  );
};

export default BookingHome;
