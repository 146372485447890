const { default: Api } = require("../../helpers/Api");

export const getAllPrefabWorkItemsApi = async (obj) => {
    try {
        const resData = await Api.post("prefab/workitems/get/all", obj);
        return resData?.data || { data: [], count: 0 };
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const getPrefabWorkItemByIdApi = async (obj) => {
    try {
        const resData = await Api.post("prefab/workitem/get", obj);
        return resData?.data || null;
    } catch (error) {
        console.log(error);
        throw error;
    }
}; 

export const getPreFabWorkItemTemplatesApi = async (obj) => {
    try {
        const resData = await Api.post("prefab/templates/get/all", obj);
        return resData?.data || { data: [], count: 0 };
    } catch (error) {
        console.log(error);
        throw error;
    }
};

export const createPrefabTicket = async (obj) => {
    try {
        const resData = await Api.post("prefab/ticket/create", obj);
        return resData?.data || null;
    } catch (error) {
        console.log(error);
        throw error;
    }
}; 

export const getPaginatedPrefabTickets = async (obj) => {
    try {
        const resData = await Api.post("get/prefab-ticket/paginated", obj);
        return resData?.data || null;
    } catch (error) {
        console.log(error);
        throw error;
    }
}; 

export const updatePrefabWorkItemApi = async (obj) => {
    try {
        const resData = await Api.post("prefab/workitem/update", obj);
        return resData?.data || null;
    } catch (error) {
        console.log(error);
        throw error;
    }
}; 

export const getDetailsPrefabWorkitemData = async (obj) => {
    try {
        const resData = await Api.post("prefab/workitem/get", obj);
        return resData?.data || null;
    } catch (error) {
        console.log(error);
        throw error;
    }
}; 

export const getPrefabItemFilterOptions = async (obj) => {
    try {
        const resData = await Api.post("prefab/workitem/filter/options", obj);
        return resData?.data || null;
    } catch (error) {
        console.log(error);
        throw error;
    }
}; 

export const getPrefabItemTemplateFilterOptions = async (obj) => {
    try {
        const resData = await Api.post("prefab/workitem/template/filter/options", obj);
        return resData?.data || null;
    } catch (error) {
        console.log(error);
        throw error;
    }
}; 

export const getPrefabTicketFilterOptions = async (obj) => {
    try {
        const resData = await Api.post("prefab/prefab/ticket/filter/options", obj);
        return resData?.data || null;
    } catch (error) {
        console.log(error);
        throw error;
    }
}; 

export const getPrefabItemsAnalysis = async (obj) => {
    try {
        const resData = await Api.post("prefab/items/projectwise/analysis", obj);
        return resData?.data || null;
    } catch (error) {
        console.log(error);
        throw error;
    }
}; 

export const createDefaultPrefabCategories = async (obj) => {
    try {
        const resData = await Api.post("wallet/cost-code-category/prefab-default/create", obj);
        return resData?.data || null;
    } catch (error) {
        console.log(error);
        throw error;
    }
}; 

export const getProjectsByOrgId = async (obj) => {
    try {
        const resData = await Api.post("/projects/by-org", obj);
        return resData?.data || null;
    } catch (error) {
        console.log(error);
        throw error;
    }
}; 

export const getProjectsStructureByOrgId = async (obj) => {
    try {
        const resData = await Api.post("/projects/structure/by-org", obj);
        return resData?.data || null;
    } catch (error) {
        console.log(error);
        throw error;
    }
}; 